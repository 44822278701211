var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('viewer-layout',{attrs:{"primary-toolbar-config":{
		Back: {
			group: 'left',
			class: 'back-btn',
			icon: 'arrow-left',
			action: function () { return this$1.$router.push('/consigner-studies'); },
		},
	},"settings-config":{
		'Intersecting Line Angles': {
			hidden: true,
		},
		'Reference Lines': {
			hidden: true,
		},
	}}})}
var staticRenderFns = []

export { render, staticRenderFns }