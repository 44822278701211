<template>
	<div class="viewer-toolbar-container" :class="({ 'show-compact': showCompactToolbar }, toolbarLocation)">
		<!-- Full / Grouped -->
		<ast-toolbar ref="fullToolbar" class="full">
			<!-- Levels -->
			<template v-if="!isGrouped.levels">
				<ast-toolbar-button
					v-for="tool in itemsInGroup('levels')"
					:key="tool.label"
					:icon="tool.iconName"
					:label="tool.label"
					:title="tool.title"
					:badge="tool.binding"
					:disabled="tool.disabled"
					@mousedown.native.prevent="tool.command ? tool.command() : activateTool({ event: $event, tool })"
					@contextmenu.native.prevent
				/>
				<span class="separator"></span>
			</template>
			<ast-toolbar-dropdown
				v-else
				:items="itemsInGroup('levels')"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>

			<!-- Zoom tools  -->
			<template v-if="!isGrouped.zoom">
				<ast-toolbar-button
					v-for="tool in itemsInGroup('zoom')"
					:key="tool.label"
					:icon="tool.iconName"
					:label="tool.label"
					:title="tool.title"
					:badge="tool.binding"
					:disabled="tool.disabled"
					@mousedown.native.prevent="tool.command ? tool.command() : activateTool({ event: $event, tool })"
					@contextmenu.native.prevent
				/>
				<!-- <span class="separator"></span> -->
			</template>
			<ast-toolbar-dropdown
				v-else
				:items="itemsInGroup('zoom')"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>

			<!-- Tools without Groups -->
			<ast-toolbar-button
				v-for="tool in ungroupedTools"
				:key="tool.label"
				:icon="tool.iconName"
				:label="tool.label"
				:title="tool.title"
				:badge="tool.binding"
				:disabled="tool.disabled"
				@mousedown.native.prevent="activateTool({ event: $event, tool })"
				@contextmenu.native.prevent
			/>
			<span class="separator"></span>

			<!-- Annotation -->
			<template v-if="!isGrouped.annotation">
				<ast-toolbar-button
					v-for="tool in itemsInGroup('annotation')"
					:key="tool.label"
					:icon="tool.iconName"
					:label="tool.label"
					:title="tool.title"
					:badge="tool.binding"
					:disabled="tool.disabled"
					@mousedown.native.prevent="activateTool({ event: $event, tool })"
					@contextmenu.native.prevent
				/>
				<span class="separator"></span>
			</template>
			<ast-toolbar-dropdown
				v-else
				:items="itemsInGroup('annotation')"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>

			<template v-if="!isRepository">
				<!-- Surgical -->
				<template v-if="!isGrouped.surgical">
					<ast-toolbar-button
						v-for="tool in itemsInGroup('surgical')"
						:key="tool.label"
						:icon="tool.iconName"
						:label="tool.label"
						:title="tool.title"
						:badge="tool.binding"
						:disabled="tool.disabled"
						@mousedown.native.prevent="activateTool({ event: $event, tool })"
						@contextmenu.native.prevent
					/>
					<span class="separator"></span>
				</template>
				<ast-toolbar-dropdown
					v-else
					:items="itemsInGroup('surgical')"
					:available-tools="availableTools"
					:dropdown-location="dropdownLocation"
					@activate-tool="activateTool"
				/>
				<!-- Scroll -->
				<template v-if="!isGrouped.scroll">
					<ast-toolbar-button
						v-for="tool in itemsInGroup('scroll')"
						:key="tool.label"
						:icon="tool.iconName"
						:label="tool.label"
						:title="tool.title"
						:badge="tool.binding"
						:disabled="tool.disabled"
						@mousedown.native.prevent="activateTool({ event: $event, tool })"
						@contextmenu.native.prevent
					/>
					<span class="separator"></span>
				</template>
				<ast-toolbar-dropdown
					v-else
					:items="itemsInGroup('scroll')"
					:available-tools="availableTools"
					:dropdown-location="dropdownLocation"
					@activate-tool="activateTool"
				/>
				<!-- Sync Method -->
				<ast-toolbar-dropdown
					:icon="currentSyncMethod.iconName"
					label="Sync"
					:title="currentSyncMethod.title"
					:items="syncItems"
					:dropdown-location="dropdownLocation"
				/>
			</template>

			<ast-toolbar-dropdown
				icon="repeat2"
				label="Rotate"
				title="Rotation Tools"
				:items="rotateItems"
				:dropdown-location="dropdownLocation"
			/>
			<ast-toolbar-button icon="backup-restore" label="Reset" title="Reset Image" @click.native="resetActiveCanvas" />
			<span class="separator"></span>
			<ast-toolbar-button icon="tag" label="DICOM" @click.native="$emit('toggle-drawer', 'dicom')" />
			<ast-toolbar-button
				icon="dots-horizontal"
				label="Actions"
				title="Export images, burn in annotations, etc."
				@click.native="$emit('toggle-drawer', 'actions')"
			/>
		</ast-toolbar>

		<!-- Compact -->
		<ast-toolbar class="compact">
			<ast-toolbar-button icon="backup-restore" label="Reset" @click.native="resetActiveCanvas" />
			<ast-toolbar-dropdown
				icon="repeat2"
				label="Rotate"
				title="Rotation Tools"
				:items="rotateItems"
				:dropdown-location="dropdownLocation"
			/>
			<ast-toolbar-dropdown
				:items="compactItems"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>
			<ast-toolbar-button icon="tag" label="DICOM" @click.native="$emit('toggle-drawer', 'dicom')" />
			<ast-toolbar-button
				icon="dots-horizontal"
				label="Actions"
				title="Export images, burn in annotations, etc."
				@click.native="$emit('toggle-drawer', 'actions')"
			/>
		</ast-toolbar>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ViewerToolsMixin from './ViewerToolsMixin'

let toolActivationDebounce

export default {
	name: 'ViewerTools',
	mixins: [ViewerToolsMixin],
	props: {
		activeSeries: {
			type: Object,
			required: false,
		},
		activeImage: {
			type: null,
			required: true,
		},
	},
	data() {
		return {
			isGrouped: {
				levels: false,
				zoom: false,
				annotation: false,
				surgical: false,
				scroll: false,
			},
			rotateItems: [
				{
					iconName: 'flip-horizontal2',
					label: 'Flip Horizontally',
					command: () => this.$store.dispatch('toggleCanvasHorizontalFlip'),
				},
				{
					iconName: 'flip-vertical',
					label: 'Flip Vertically',
					command: () => this.$store.dispatch('toggleCanvasVerticalFlip'),
				},
				{
					iconName: 'rotate-right',
					label: 'Rotate Clockwise',
					command: () => this.$store.dispatch('rotateCanvas', 90),
				},
				{
					iconName: 'rotate-left2',
					label: 'Rotate Counterclockwise',
					command: () => this.$store.dispatch('rotateCanvas', -90),
				},
			],
		}
	},
	computed: {
		...mapGetters(['allSeries', 'availableTools', 'isAuthenticated', 'activeStudy']),
		...mapState({
			isStackScrollSynchronizationEnabled: state => state.viewer.settingsPanel.isStackScrollSynchronizationEnabled,
			isManualStackScrollSynchronizationEnabled: state =>
				state.viewer.settingsPanel.isManualStackScrollSynchronizationEnabled,
		}),
		actions() {
			return [
				{
					iconName: 'invert-colors',
					label: 'Invert',
					title: 'Toggle Invert Image',
					command: () => this.$store.dispatch('toggleCanvasInvert'),
					groups: ['levels'],
				},
				{
					iconName: 'fullscreen',
					label: '1:1',
					title: 'Toggle 1:1 Scale',
					command: () => this.$store.dispatch('toggleZoom1to1'),
					groups: ['zoom'],
				},
			]
		},
		syncItems() {
			return [
				{
					iconName: 'link',
					title: 'Auto Series Synchronization',
					label: 'auto_sync',
					command: () => {
						this.setStackSynchronizationMethodAndBroadcast('auto')
					},
					active: this.isStackScrollSynchronizationEnabled,
					groups: ['sync'],
				},
				{
					iconName: 'link_manual',
					title: 'Manual Series Synchronization',
					label: 'manual_sync',
					command: () => {
						this.setStackSynchronizationMethodAndBroadcast('manual')
					},
					active: this.isManualStackScrollSynchronizationEnabled,
					groups: ['sync'],
				},
				{
					iconName: 'link_off',
					title: 'Series Synchronization Off',
					label: 'sync_off',
					command: () => {
						this.setStackSynchronizationMethodAndBroadcast('off')
					},
					active: !this.isStackScrollSynchronizationEnabled && !this.isManualStackScrollSynchronizationEnabled,
					groups: ['sync'],
				},
			]
		},
		hasMultiImageSeries() {
			return this.allSeries.some(series => series.images.length > 1)
		},
		groupOrder() {
			let groupOrder = ['levels', 'zoom', 'surgical', 'annotation', 'scroll']
			if (!this.hasMultiImageSeries) groupOrder = ['scroll', 'levels', 'zoom', 'surgical', 'annotation']
			return groupOrder
		},
		compactItems() {
			let compactItems = [].concat(
				this.itemsInGroup('levels'),
				this.itemsInGroup('zoom'),
				this.ungroupedTools,
				this.itemsInGroup('annotation')
			)
			if (!this.isRepository)
				compactItems = compactItems.concat(this.itemsInGroup('surgical'), this.itemsInGroup('scroll'), this.syncItems)
			return compactItems.filter(deduplicateItems)

			function deduplicateItems(item, i) {
				return compactItems.findIndex(each => each.label === item.label) === i
			}
		},
		currentSyncMethod() {
			return this.syncItems.find(s => s.active)
		},
	},
	methods: {
		...mapActions(['resetActiveCanvas', 'setStackSynchronizationMethodAndBroadcast']),
		activateTool({ tool, event }) {
			clearTimeout(toolActivationDebounce)
			if (typeof tool.command === 'function') {
				// don't run the command if no canvas/series is active
				if (this.activeSeries) {
					tool.command(this.activeSeries, this.activeImage, this.activeStudy)
				}
				return
			}
			toolActivationDebounce = setTimeout(() => {
				const whichToButtons = {
					// map event.which to equivalent event.buttons for Safari
					1: 1, // L
					2: 4, // M
					3: 2, // R
				}
				const mouseButtonMask = event.buttons || whichToButtons[event.which] || 1
				const validButtons = [1, 2, 3, 4, 5, 6, 7] // L, R, LR, M, LM, MR, LMR (no Back or Forward yet)
				if (!validButtons.includes(mouseButtonMask) || tool.disabled) return
				const options = { mouseButtonMask }
				this.$store.dispatch('setActiveToolAndBroadcast', {
					alias: tool.alias,
					options,
				})
				// Save and synchronize the binding with mpr viewer
				this.$store.dispatch('updateMouseBinding', {
					type: 'img',
					tool: tool.alias,
					button: mouseButtonMask,
				})
			}, 200)
		},
	},
}
</script>

<style lang="scss">
.viewer-toolbar-container {
	&.top,
	&.bottom {
		.separator {
			width: 0;
			border-right: 1px solid var(--divider);
			margin: 0 6px;
		}
	}
	&.left,
	&.right {
		.separator {
			width: 100%;
			border-bottom: 1px solid var(--divider);
			margin: 6px 0;
		}
		.button-container + .button-container {
			margin-top: 6px;
		}
		.full,
		.compact {
			flex-direction: column;
		}
	}
	&.top {
		border-bottom: 1px solid var(--viewer-menu-border);
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	&.bottom {
		border-top: 1px solid var(--viewer-menu-border);
		box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	&.left {
		z-index: 11;
		box-shadow: 1px 0 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	&.right {
		z-index: 11;
		box-shadow: -1px 0 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}
	.toolbar-button button.has-caret {
		min-width: 64px; /* prevent buttons from shifting when changing active tool */
	}
	.full {
		display: flex !important;
		justify-content: flex-start;
	}
	.compact {
		display: none !important;
		justify-content: space-around;
	}
	&.show-compact {
		.full {
			display: none !important;
		}
		.compact {
			display: flex !important;
		}
	}
}
</style>
