var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation"},[_c('div',{staticClass:"buttons"},[_c('ast-toolbar-button',{attrs:{"icon":"play-box-outline","title":"CINE","disabled":!_vm.hasMultipleImages},nativeOn:{"click":function($event){return _vm.toggleCine($event)}}}),(!_vm.hangingProtocol)?[_c('ast-toolbar-button',{attrs:{"icon":"skip-previous","title":"Previous Series","disabled":!_vm.hasPreviousSeries},nativeOn:{"click":function($event){return _vm.$store.dispatch('previousSeries')}}}),_c('ast-toolbar-button',{attrs:{"icon":"skip-next","title":"Next Series","disabled":!_vm.hasNextSeries},nativeOn:{"click":function($event){return _vm.$store.dispatch('nextSeries')}}})]:[_c('ast-toolbar-button',{attrs:{"icon":"skip-previous","title":"Previous Display Set","disabled":!_vm.hasPreviousDisplaySet},nativeOn:{"click":function($event){return _vm.setHangingProtocolDisplaySet(_vm.activeDisplaySetIndex - 1)}}}),_c('ast-toolbar-button',{attrs:{"icon":"skip-next","title":"Next Display Set","disabled":!_vm.hasNextDisplaySet},nativeOn:{"click":function($event){return _vm.setHangingProtocolDisplaySet(_vm.activeDisplaySetIndex + 1)}}})]],2),(_vm.hangingProtocols && _vm.hangingProtocols.length)?_c('div',{staticClass:"dropdowns"},[_c('ast-toolbar-button',{attrs:{"title":"Hanging Protocol","disabled":_vm.isHangingProtocolLoading,"dropdown-location":_vm.showDropdownsBelow ? 'bottom' : 'top'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"label full-label"},[_vm._v(_vm._s(_vm.hangingProtocolLabel))]),_c('p',{staticClass:"label compact-label"},[_vm._v("Hanging Protocol")])]},proxy:true},{key:"dropdown",fn:function(ref){
var hide = ref.hide;
return [_c('ast-toolbar-menu',{attrs:{"items":_vm.hangingProtocolOptions},on:{"select":function (protocol) { return _vm.setHangingProtocol(protocol.value, hide); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var protocol = ref.item;
return [_c('span',{class:{ selected: _vm.hangingProtocol && protocol.value === _vm.hangingProtocol.id }},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(protocol.label)+"\n\t\t\t\t\t\t")])]}}],null,true)})]}}],null,false,3110910219)}),(_vm.hangingProtocol)?_c('ast-toolbar-button',{attrs:{"title":"Display Sets","disabled":_vm.isHangingProtocolLoading,"dropdown-location":_vm.showDropdownsBelow ? 'bottom' : 'top'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"label full-label"},[_vm._v(" DS: "+_vm._s(_vm.hangingProtocol.displaySets[_vm.activeDisplaySetIndex].name)+" ")]),_c('p',{staticClass:"label compact-label"},[_vm._v("Display Set")])]},proxy:true},{key:"dropdown",fn:function(ref){
var hide = ref.hide;
return [_c('ast-toolbar-menu',{attrs:{"items":_vm.hangingProtocol.displaySets},on:{"select":function (e) { return _vm.switchDisplaySet(e, hide); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var displaySet = ref.item;
return [_c('span',{class:{
								selected: displaySet === _vm.hangingProtocol.displaySets[_vm.activeDisplaySetIndex],
							}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(displaySet.name)+"\n\t\t\t\t\t\t")])]}}],null,true)})]}}],null,false,1589826515)}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }