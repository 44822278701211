import store from '@store'

const playClip = function() {
  store.commit('CINE_PLAY')
}

const pauseClip = function() {
  store.commit('CINE_PAUSE')
}

const cineNextSeries = function() {
  pauseClip()
  store.dispatch('nextSeries')
}

const cinePreviousSeries = function() {
  pauseClip()
  store.dispatch('previousSeries')
}

const goToImage = function(action) {
  pauseClip()

  switch (action) {
    case 'first':
      store.dispatch('firstImage')
      break
    case 'previous':
      store.dispatch('previousImage')
      break
    case 'next':
      store.dispatch('nextImage')
      break
    case 'last':
      store.dispatch('lastImage')
      break
  }
}

const onFrameRateChange = function(event) {
  const value = parseInt(event.target.value)
  store.commit('SET_CINE_FRAMERATE', value)
}

export default {
  cineNextSeries,
  cinePreviousSeries,
  playClip,
  pauseClip,
  goToImage,
  onFrameRateChange,
}
