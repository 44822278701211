<template>
	<div
		class="viewer-toolbar-container"
		:class="({ 'is-disabled': isLoading, 'show-compact': showCompactToolbar }, toolbarLocation)"
	>
		<!-- Full / Grouped -->
		<ast-toolbar ref="fullToolbar" class="full">
			<!-- Levels -->
			<template v-if="!isGrouped.levels">
				<ast-toolbar-button
					v-for="tool in itemsInGroup('levels')"
					:key="tool.label"
					:icon="tool.iconName"
					:label="tool.label"
					:title="tool.title"
					:badge="tool.binding"
					:disabled="tool.disabled"
					@mousedown.native.prevent="tool.command ? tool.command() : activateTool({ event: $event, tool })"
					@contextmenu.native.prevent
				/>
				<span class="separator"></span>
			</template>
			<ast-toolbar-dropdown
				v-else
				:items="itemsInGroup('levels')"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>

			<!-- Tools without Groups -->
			<ast-toolbar-button
				v-for="tool in ungroupedTools"
				:key="tool.label"
				:icon="tool.iconName"
				:label="tool.label"
				:title="tool.title"
				:badge="tool.binding"
				:disabled="tool.disabled"
				@mousedown.native.prevent="activateTool({ event: $event, tool })"
				@contextmenu.native.prevent
			/>
			<span class="separator"></span>

			<!-- Annotations -->
			<template v-if="!isGrouped.annotation">
				<ast-toolbar-button
					v-for="tool in itemsInGroup('annotation')"
					:key="tool.label"
					:icon="tool.iconName"
					:label="tool.label"
					:title="tool.title"
					:badge="tool.binding"
					:disabled="tool.disabled"
					@mousedown.native.prevent="activateTool({ event: $event, tool })"
					@contextmenu.native.prevent
				/>
				<span class="separator"></span>
			</template>
			<ast-toolbar-dropdown
				v-else
				:items="itemsInGroup('annotation')"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>

			<!-- Scroll -->
			<template v-if="!isGrouped.scroll">
				<ast-toolbar-button
					v-for="tool in itemsInGroup('scroll')"
					:key="tool.label"
					:icon="tool.iconName"
					:label="tool.label"
					:title="tool.title"
					:badge="tool.binding"
					:disabled="tool.disabled"
					@mousedown.native.prevent="activateTool({ event: $event, tool })"
					@contextmenu.native.prevent
				/>
			</template>
			<ast-toolbar-dropdown
				v-else
				:items="itemsInGroup('scroll')"
				:available-tools="availableTools"
				:dropdown-location="dropdownLocation"
				@activate-tool="activateTool"
			/>

			<ast-toolbar-dropdown
				key="hack-bugfix?!"
				icon="gradient"
				:dropdown-location="dropdownLocation"
				:label="activeBlendMethod.label"
				:title="activeBlendMethod.title"
				:items="blendMethods"
			/>
			<span class="separator"></span>

			<ast-toolbar-button
				v-for="tool in itemsInGroup('switch')"
				:key="tool.label"
				:icon="tool.iconName"
				:label="tool.label"
				:title="tool.title"
				:badge="tool.binding"
				:disabled="tool.disabled"
				@mousedown.native.prevent="tool.command()"
				@contextmenu.native.prevent
			/>
			<ast-toolbar-dropdown
				icon="repeat2"
				label="Rotate"
				title="Rotation Tools"
				:dropdown-location="dropdownLocation"
				:items="rotateItems"
			/>
			<ast-toolbar-button icon="backup-restore" label="Reset" title="Reset Image" @click.native="resetViewData" />
			<span class="separator"></span>

			<!--ACTIONS-->
			<ast-toolbar-button icon="dots-horizontal" label="Actions" @click.native="$emit('toggle-drawer', 'mprActions')" />
		</ast-toolbar>

		<!-- Compact -->
		<!-- <ast-toolbar class="compact">
			<ast-toolbar-button icon="backup-restore" label="Reset" @click.native="$emit('reset')" />
			<ast-toolbar-dropdown
				icon="repeat2"
				label="Rotate"
				title="Rotation Tools"
				:items="itemsInGroup('rotate')"
			/>
			<ast-toolbar-dropdown :items="compactItems" @activate-tool="activateTool" />
			<ast-toolbar-button icon="tag" label="DICOM" @click.native="$emit('toggle-drawer', 'dicom')" />
			<ast-toolbar-button
				icon="dots-horizontal"
				label="Actions"
				@click.native="$emit('toggle-drawer', 'actions')"
			/>
		</ast-toolbar> -->
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { consts } from '@vtk'
import ViewerToolsMixin from './ViewerToolsMixin'

let toolActivationDebounce

export default {
	name: 'MprViewerTools',
	mixins: [ViewerToolsMixin],
	data() {
		return {
			groupOrder: ['scroll', 'levels', 'annotation'],
			isGrouped: {
				levels: false,
				annotation: false,
				scroll: false,
			},
		}
	},
	computed: {
		...mapState({
			availableTools: state => state.mpr.availableTools,
			activeTools: state => state.mpr.activeTools,
			switchModeActive: state => state.mpr.activeSeries.switchModeActive,
			isLoading: state => !state.mpr.activeSeries || !state.mpr.activeSeries.volumeData,
		}),
		...mapGetters(['isAuthenticated']),
		actions() {
			return [
				{
					name: 'InvertTool',
					label: 'Invert',
					title: 'Invert View',
					groups: ['levels'],
					iconName: 'invert-colors',
					command: () => {
						this.invertVolume()
					},
				},
				{
					name: 'AstSwitchViews',
					label: 'Switch',
					title: 'Switch Views',
					iconName: 'switch',
					groups: ['switch'],
					command: () => {
						this.setSwitchModeActive(!this.switchModeActive)
					},
				},
			]
		},
		rotateItems() {
			return [
				{
					iconName: 'flip-horizontal2',
					label: 'Flip Horizontally',
					command: () => this.flipHActiveView(),
				},
				{
					iconName: 'flip-vertical',
					label: 'Flip Vertically',
					command: () => this.flipVActiveView(),
				},
				// Removed due to bug with 90 degrees [ch7465]
				{
					iconName: 'rotate-right',
					label: 'Rotate Clockwise',
					command: () => this.rotateActiveViewClockwise(),
				},
				{
					iconName: 'rotate-left2',
					label: 'Rotate Counterclockwise',
					command: () => this.rotateActiveViewCounterClockwise(),
				},
			]
		},
		blendMethods() {
			return [
				{
					name: consts.BLEND_NONE,
					label: 'None',
					title: 'No Blend Mode',
					iconName: 'cancel',
					command: () => {
						this.setBlendMode(consts.BLEND_NONE)
					},
				},
				{
					name: consts.BLEND_MIP,
					label: 'MIP',
					title: 'Maximum Intensity Projection',
					iconName: 'brightness-high',
					command: () => {
						this.setBlendMode(consts.BLEND_MIP)
					},
				},
				{
					name: consts.BLEND_MINIP,
					label: 'MinIP',
					title: 'Minimum Intensity Projection',
					iconName: 'brightness-low',
					command: () => {
						this.setBlendMode(consts.BLEND_MINIP)
					},
				},
				{
					name: consts.BLEND_AVG,
					label: 'AvgIP',
					title: 'Average Intensity Projection',
					iconName: 'brightness-auto',
					command: () => {
						this.setBlendMode(consts.BLEND_AVG)
					},
				},
			]
		},
		activeBlendMethod() {
			try {
				return (
					this.blendMethods.find(m => m.name === this.$store.state.mpr.activeSeries.blendMode) || this.blendMethods[0]
				)
			} catch (err) {
				return this.blendMethods[0]
			}
		},
		compactItems() {
			let compactItems = [].concat(
				this.itemsInGroup('levels'),
				this.itemsInGroup('zoom'),
				this.ungroupedTools,
				this.itemsInGroup('annotation')
			)
			return compactItems // .filter(deduplicateItems)
		},
	},
	watch: {
		activeTools: {
			deep: true,
			handler() {
				this.updateToolBindings()
			},
		},
	},
	methods: {
		...mapActions([
			'rotateActiveViewClockwise',
			'rotateActiveViewCounterClockwise',
			'flipHActiveView',
			'flipVActiveView',
			'resetViewData',
			'invertVolume',
			'updateMouseBinding',
			'setMprActiveToolAndBroadcast',
		]),
		...mapMutations({
			updateToolBindings: 'UPDATE_TOOL_BINDINGS',
			setSwitchModeActive: 'SET_MPR_SWITCH_MODE_ACTIVE',
			setBlendMode: 'SET_MPR_BLEND_MODE_ALL',
		}),
		activateTool({ tool, event }) {
			clearTimeout(toolActivationDebounce)
			toolActivationDebounce = setTimeout(() => {
				const buttonMask = event.buttons || whichToButtons[event.which] || 1
				if (!validButtons.includes(buttonMask) || tool.disabled) return
				this.setMprActiveToolAndBroadcast({
					tool: tool.name,
					buttonMask,
				})
				// Save and synchronize the binding with image viewer
				this.updateMouseBinding({ tool: tool.name, button: buttonMask, type: 'mpr' })
			}, 200)
		},
	},
}

const whichToButtons = {
	// map event.which to equivalent event.buttons for Safari
	1: 1, // L
	2: 4, // M
	3: 2, // R
}
const validButtons = [1, 2, 4] // L, R, M,
</script>
