




































































import { openReportPdf } from '@dialogs/ReportPdf.vue'
import consultations from "@services/consultationService";

export default {
	name: 'ReportList',
	props: {
		reports: {
			type: Array,
			default: () => [],
		},
	},
	data: function() {
    return {
      IDEXXAPI : this.$IDEXXAPI,
			VetsChoice_ClinicCode : this.$VetsChoice_ClinicCode
    }
  },
	watch: {
		reports() {
			this.tryOpenFromUrlQuery()
		},
	},
	methods: {
		tryOpenFromUrlQuery() {
			if (this.reports && this.$route.query.reportId) {
				let found = this.reports.find(r => r.reportId === this.$route.query.reportId)
				if (found) {
					this.openReportPdf(found)
				}
			}
		},
		openReportPdf(report) {
			const { reportId, consultantReportId, isComplete } = report
			openReportPdf(reportId, consultantReportId, isComplete)
		},
		async downloadExternalReport(providerClinicCode: string, consultantReportId: string) {
			var clientClinicCode = this.$store.state.auth.claims.activeClinicCode
      var getReportResult = await consultations.downloadExternalReport(providerClinicCode, clientClinicCode, consultantReportId)

			if (getReportResult['Success']){
				var reportBinaryString = getReportResult['Data']
				let pdfWindow = window.open("")
				pdfWindow.document.write(
    			"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    			encodeURI(reportBinaryString) + "'></iframe>"
				)
			}
		}
	},
}
