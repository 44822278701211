import { import as csTools } from 'cornerstone-tools/dist/cornerstoneTools.js'
import { MEASUREMENT_DONE_MODIFYING } from '@/cornerstone/_shared/events'

const triggerEvent = csTools('util/triggerEvent')

export default function triggerDoneModifying(toolName, element, image) {
	triggerEvent(element, MEASUREMENT_DONE_MODIFYING, {
		toolName,
		toolType: toolName, // Deprecation notice: toolType will be replaced by toolName
		element,
		image,
	})
}
