import AstToolbar from '@components/Toolbar'
import AstToolbarButton from '@components/ToolbarButton'
import AstToolbarDropdown from '@components/ToolbarDropdown'
import { eventBus } from '@services/eventBus'

export default {
	components: {
		AstToolbar,
		AstToolbarButton,
		AstToolbarDropdown,
	},
	props: {
		isRepository: {
			type: Boolean,
			default: false,
		},
		toolbarLocation: {
			type: String,
			default: 'top',
		},
	},
	data() {
		return {
			isBurningAnnotations: false,
			showCompactToolbar: false,
		}
	},
	computed: {
		dropdownLocation() {
			return this.toolbarLocation === 'top'
				? 'bottom'
				: this.toolbarLocation === 'bottom'
				? 'top'
				: this.toolbarLocation === 'left'
				? 'right'
				: 'left'
		},
		ungroupedTools() {
			return this.availableTools.filter(tool => !tool.groups.length)
		},
	},
	watch: {
		'availableTools.length'() {
			this.groupTools()
		},
		toolbarLocation() {
			this.groupTools()
		},
	},
	mounted() {
		this.groupTools()
		eventBus.on('resize', this.groupTools)
	},
	destroyed() {
		eventBus.off('resize', this.groupTools)
	},
	methods: {
		async groupTools() {
			this.showCompactToolbar = false
			await this.$nextTick()
			if (!this.$refs.fullToolbar) return
			const toolbar = this.$refs.fullToolbar.$el
			if (!toolbar || !toolbar.clientWidth) return
			toolbar.style.opacity = 0 // makes rendering look less glitchy in IE
			// Ungroup all tools
			this.groupOrder.forEach(group => {
				this.isGrouped[group] = false
			})
			const isToolbarOverflowing = () => {
				return ['top', 'bottom'].includes(this.toolbarLocation)
					? toolbar.clientWidth < toolbar.scrollWidth
					: toolbar.clientHeight < toolbar.scrollHeight
			}
			// Group tools until toolbar is not overflowing
			for (let i = 0; i < this.groupOrder.length; i++) {
				await this.$nextTick()
				if (!isToolbarOverflowing()) break
				this.isGrouped[this.groupOrder[i]] = true
			}
			await this.$nextTick()
			this.showCompactToolbar = isToolbarOverflowing()
			toolbar.style.opacity = 1
		},
		itemsInGroup(group) {
			let tools = this.availableTools.filter(tool => tool.groups.includes(group))
			let actions = this.actions.filter(action => action.groups.includes(group))
			return [].concat(tools, actions).filter(item => !(item.isHiddenForRepository && this.isRepository))
		},
		async burnInAnnotations() {
			if (this.isBurningAnnotations) {
				return
			}
			try {
				this.isBurningAnnotations = true
				await this.$store.dispatch('createAnnotationRenderingAsync')
			} finally {
				this.isBurningAnnotations = false
			}
		},
	},
}
