import {
	external,
	import as csTools,
	getToolState,
	toolColors,
	addToolState,
	removeToolState,
	EVENTS,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import { openPromptDlg } from '@dialogs/TextPromptDlg'
import toolSelectedCallback from './_shared/toolSelectedCallback.js'
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import triggerDoneModifying from './util/triggerDoneModifying.js'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')
const triggerEvent = csTools('util/triggerEvent')
const getNewContext = csTools('drawing/getNewContext')
const draw = csTools('drawing/draw')
const setShadow = csTools('drawing/setShadow')
const drawTextBox = csTools('drawing/drawTextBox')
const pointInsideBoundingBox = csTools('util/pointInsideBoundingBox')

export default class AstText extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstText',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				async getTextCallback(cb) {
					const text = await openPromptDlg({
						title: 'New Text Annotation',
						prompt: 'Enter your annotation text:',
						requireInput: true,
					})
					cb(text)
				},
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
			},
		}
		super(configuration, defaultConfig)
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	createNewMeasurement(e) {
		return {
			visible: true,
			active: true,
			color: undefined,
			text: '...',
			handles: {
				end: {
					x: e.detail.currentPoints.image.x,
					y: e.detail.currentPoints.image.y,
					highlight: true,
					active: true,
					hasBoundingBox: true,
				},
			},
		}
	}

	addNewMeasurement(e, interactionType) {
		const element = e.detail.element
		const measurementData = this.createNewMeasurement(e)

		addToolState(element, this.name, measurementData)
		external.cornerstone.updateImage(element)

		moveNewHandle(
			e.detail,
			this.name,
			measurementData,
			measurementData.handles.end,
			this.options,
			interactionType,
			(e, success) => {
				if (!success) {
					removeToolState(element, this.name, measurementData)
					return
				}
				this.configuration.getTextCallback(text => {
					if (!text) {
						removeToolState(element, this.name, measurementData)
						external.cornerstone.updateImage(element)
						return
					}
					measurementData.text = text
					measurementData.active = false
					external.cornerstone.updateImage(element)
					triggerDoneModifying(this.name, element, e.detail.image)
				}, e.detail)
				external.cornerstone.updateImage(element)

				const modifiedEventData = {
					toolName: this.name,
					toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
					element,
					measurementData,
				}
				triggerEvent(element, EVENTS.MEASUREMENT_COMPLETED, modifiedEventData)
			}
		)
	}

	updateCachedStats() {}

	renderToolData(e) {
		const toolData = getToolState(e.detail.element, this.name)
		if (!toolData) return

		const canvas = e.detail.canvasContext.canvas
		const context = getNewContext(canvas)

		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i]
			if (data.visible === false) continue

			draw(context, context => {
				setShadow(context, this.configuration)
				const color = toolColors.getColorIfActive(data)
				const textCoords = external.cornerstone.pixelToCanvas(e.detail.element, data.handles.end)
				const options = {
					centering: {
						x: true,
						y: true,
					},
				}
				data.handles.end.boundingBox = drawTextBox(
					context,
					data.text || ' ',
					textCoords.x,
					textCoords.y - 10,
					color,
					options
				)
			})
		}
	}

	handleSelectedCallback(e, toolData, handle, interactionType = 'mouse') {
		// open annotation dialog when textbox is clicked
		this.toolSelectedCallback(e, toolData, interactionType)
	}

	pointNearTool(element, data, coords) {
		if (data.visible === false) return false
		if (!data.handles.end.boundingBox) return false

		const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(data.handles.end.boundingBox, coords)
		const insideBoundingBox = pointInsideBoundingBox(data.handles.end, coords)
		return distanceToPoint < 10 || insideBoundingBox
	}
}
