/**
 * Copied from Cornerstone Tools so we can use previously stored metadata instead of relying on dom elements from other pages.
 * One file because that's easier.
 */
import { external, import as csTools, toolColors } from 'cornerstone-tools/dist/cornerstoneTools.js'
import { waitForEnabledElementImageToLoad } from '@/utils/wait.js'

import convertToVector3 from '@/utils/convertToVector3'
import store from '@store'

const BaseTool = csTools('base/BaseTool')
const draw = csTools('drawing/draw')
const drawLine = csTools('drawing/drawLine')

const getNewContext = csTools('drawing/getNewContext')

/**
 * When enabled, this tool will display references lines for each source
 * enabledElement in the provided synchronizer. This tool can also be configured
 * to use a custom renderer for alternative reference line rendering behavior
 *
 * TODO: Need to watch for configuration changes to update ToolState
 * TODO:
 *
 * @export @public @class
 * @name ReferenceLinesTool
 * @classdesc Tool for displaying reference lines of other enabledElements
 * @extends Tools.Base.BaseTool
 */
export default class ReferenceLinesTool extends BaseTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'ReferenceLines',
			mixins: ['enabledOrDisabledBinaryTool'],
			configuration: {
				renderer: renderActiveReferenceLine,
			},
		}

		super(props, defaultProps)

		this.renderer = null
		this.synchronizationContext = null
	}

	async enabledCallback(element, { synchronizationContext } = {}) {
		const renderer = this.configuration.renderer
		const enabledElement = await waitForEnabledElementImageToLoad(element)

		if (!enabledElement || !renderer || !synchronizationContext) {
			// TODO: Unable to add tool state, image never loaded.
			// Should we `setToolDisabledForElement` here?
			// logger.warn(
			//   `Unable to enable ${this.name}. Exiting enable callback. Tool will be enabled, but will not render.`
			// );
			return
		}
		this.renderer = renderer
		this.synchronizationContext = synchronizationContext

		this.forceImageUpdate(element)
	}

	disabledCallback(element) {
		this.forceImageUpdate(element)
	}

	forceImageUpdate(element) {
		const enabledElement = external.cornerstone.getEnabledElement(element)

		if (enabledElement.image) {
			external.cornerstone.updateImage(element)
		}
	}

	renderToolData(evt) {
		const eventData = evt.detail

		// No renderer or synch context? Adios
		if (!this.renderer || !this.synchronizationContext) {
			return
		}

		// This is a onetime check to see if the metadata hasn't been set yet.
		// Not ideal, but the first pass kept failing for some reason.
		let referenceMetaData = store.state.viewer.activeImageMetadata

		// Get the enabled elements associated with this synchronization context and draw them
		const enabledElements = this.synchronizationContext.getSourceElements()
		const context = getNewContext(eventData.canvasContext.canvas)

		external.cornerstone.setToPixelCoordinateSystem(eventData.enabledElement, context)

		if (enabledElements.length) {
			enabledElements.forEach(referenceEnabledElement => {
				// Render it
				this.renderer(context, eventData, evt.currentTarget, referenceMetaData)
			})
		} else if (referenceMetaData) {
			// There is no enabled element (because it's on the other window)
			this.renderer(context, eventData, evt.currentTarget, referenceMetaData)
		}
	}
}

/**
 * Renders the active reference line.
 *
 * @export @public @method
 * @name renderActiveReferenceLine
 * @param  {Object} context        The canvas context.
 * @param  {Object} eventData      The data associated with the event.
 * @param  {HTMLElement} targetElement    The element on which to render the reference line.
 * @param  {Object} referenceImageMetadata The metadata for the element referenced by the line.
 * @returns {void}
 */
export function renderActiveReferenceLine(
	context,
	eventData,
	targetElement,
	referenceImageMetadata
) {
	const cornerstone = external.cornerstone
	const targetImage = cornerstone.getEnabledElement(targetElement).image

	// Make sure the images are actually loaded for the target and reference
	if (!targetImage || !referenceImageMetadata) {
		return
	}

	const targetImagePlane = cornerstone.metaData.get('imagePlaneModule', targetImage.imageId)
	const referenceImagePlane = referenceImageMetadata

	// Make sure the target and reference actually have image plane metadata
	if (
		!targetImagePlane ||
		!referenceImagePlane ||
		!targetImagePlane.rowCosines ||
		!targetImagePlane.columnCosines ||
		!targetImagePlane.imagePositionPatient ||
		!referenceImagePlane.rowCosines ||
		!referenceImagePlane.columnCosines ||
		!referenceImagePlane.imagePositionPatient
	) {
		return
	}

	// The image planes must be in the same frame of reference
	if (targetImagePlane.frameOfReferenceUID !== referenceImagePlane.frameOfReferenceUID) {
		return
	}

	targetImagePlane.rowCosines = convertToVector3(targetImagePlane.rowCosines)
	targetImagePlane.columnCosines = convertToVector3(targetImagePlane.columnCosines)
	targetImagePlane.imagePositionPatient = convertToVector3(targetImagePlane.imagePositionPatient)
	referenceImagePlane.rowCosines = convertToVector3(referenceImagePlane.rowCosines)
	referenceImagePlane.columnCosines = convertToVector3(referenceImagePlane.columnCosines)
	referenceImagePlane.imagePositionPatient = convertToVector3(
		referenceImagePlane.imagePositionPatient
	)

	// The image plane normals must be > 30 degrees apart
	const targetNormal = targetImagePlane.rowCosines.clone().cross(targetImagePlane.columnCosines)
	const referenceNormal = referenceImagePlane.rowCosines
		.clone()
		.cross(referenceImagePlane.columnCosines)
	let angleInRadians = targetNormal.angleTo(referenceNormal)

	angleInRadians = Math.abs(angleInRadians)
	if (angleInRadians < 0.5) {
		// 0.5 radians = ~30 degrees
		return
	}

	const referenceLine = calculateReferenceLine(targetImagePlane, referenceImagePlane)

	if (!referenceLine) {
		return
	}

	const color = toolColors.getActiveColor()

	// Draw the referenceLines
	context.setTransform(1, 0, 0, 1, 0, 0)

	draw(context, context => {
		drawLine(context, eventData.element, referenceLine.start, referenceLine.end, { color })
	})
}

const planePlaneIntersection = csTools('util/planePlaneIntersection')
const projectPatientPointToImagePlane = csTools('util/projectPatientPointToImagePlane')

/**
 * Calculates a reference line between two planes by projecting the top left hand corner and bottom right hand corner
 * Of the reference image onto the target image.  Ideally we would calculate the intersection between the planes but
 * That requires a bit more math and this works fine for most cases.
 *
 * @export
 * @public
 * @method
 * @name calculateReferenceLine
 * @param  {Object} targetImagePlane    The imagePlane on which the reference line will be drawn.
 * @param  {Object} referenceImagePlane The imagePlane being referenced.
 * @returns {Object}  The start and end points of the line to be drawn.
 */
export function calculateReferenceLine(targetImagePlane, referenceImagePlane) {
	const points = planePlaneIntersection(targetImagePlane, referenceImagePlane)

	if (!points) {
		return
	}

	return {
		start: projectPatientPointToImagePlane(points.start, targetImagePlane),
		end: projectPatientPointToImagePlane(points.end, targetImagePlane),
	}
}
