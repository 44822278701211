import introJs from 'intro.js'
import { getIcon } from './ViewerTour'
import storage from '@services/storage'

const flagKey = 'mpr-intro-new'
let initialBodyStyle

const tryStartTour = function() {
	// Don't auto-start if flag is set
	if (!storage.getItem(flagKey)) {
		startTour()
	}
}

const startTour = function() {
	const intro = introJs()
	intro
		.setOptions({
			exitOnEsc: true,
			exitOnOverlayClick: false,
			showStepNumbers: false,
			showBullets: false,
			showProgress: true,
			scrollToElement: false,
			overlayOpacity: 0.75,
			disableInteraction: false,
			steps: [
				// Welcome
				{
					element: null,
					intro: `
						<h4>Welcome to the Multi-Planar Reconstruction viewer!</h4>
						<p>Select <b>Next</b> for an overview of the viewer's features.</p>
					`,
				},
				// Viewer
				{
					element: document.querySelector('.viewer2d.active'),
					intro: `
						<h4>Image Views</h4>
						<p>MPR constructs an image volume with 3 orthogonal views.</p>
						<p>This view matches the DICOM series. The colored border denotes it as the active view.</p>
					`,
				},
				// Dot indicator
				{
					element: document.querySelector('.viewColor'),
					intro: `
						<h4>Dot Indicator</h4>
						<p>The colored indicator helps associate this view with the same-colored axis in the other two views.</p>
						<p><b>Click</b> it to toggle the fullscreen layout for this particular view.</p>
					`,
				},
				// Axis rotation
				{
					element: document.querySelector('.rotateCursor.y-top'),
					intro: `
						<h4>Axis Rotation</h4>
						<p><b>Drag</b> the circular handle to rotate the axis for its associated view (purple axis will rotate view with purple dot indicator).</p>
						<h4>Advanced Usage</h4>
						<p><b>DblClick</b> resets the axes to their default orientation
						<p><b>Shift</b>+<b>Drag</b> to rotate a single axis. Relative angles will be preserved when rotating normally.</p>
						<p><b>Shift</b>+<b>DblClick</b> re-orthogonalizes the axes if an axis was rotated individually.</p>
					`,
				},
				// Slice thickness
				{
					element: document.querySelector('.thicknessCursor.y-top'),
					intro: `
						<h4>Slick Thickness</h4>
						<p><b>Drag</b> the square handles to adjust the slice thickness for its associated view.</p>
						<p>That view will display the thickness amount (mm) in the bottom left corner.</p>
					`,
					position: 'right',
				},
				// Layout selector
				{
					element: document.querySelector('.btn-select-layout'),
					intro: `
						<h4>Select Layout & Settings</h4>
						<p><b>Click</b> the layout & settings button ${getIcon(
							'layout'
						)} to select a different view layout or toggle various overlay, axis and window level settings.</p>
					`,
					position: 'bottom',
				},
				// Viewer tools
				{
					element: document.querySelector('.viewer-toolbar-container'),
					intro: `
						<h4>Image Tools</h4>
						<p>The <b>MPR Viewer Toolbar</b> provides most of the same tools provided by the image viewer.</p>
						<p><b>Select</b> a tool to assign that tool to whichever mouse button you used to click.</p>
					`,
					position: 'bottom',
				},
				// Rotate slice plane tool
				{
					element: document.querySelector('.btn-rotate-slice-plane'),
					intro: `
						<h4>Rotate Slice Plane Tool</h4>
						<p><b>Click</b> and <b>Drag</b> to rotate the active view’s slice plane, horizontal rotation and vertical rotation.</p>
					`,
					position: 'bottom',
				},
				// Blend mode selection tools
				{
					element: document.querySelector('.btn-maximum-intensity-projection'),
					intro: `
						<h4>Blend Modes</h4>
						<p>Select the intensity projection / blend mode used to interpolate the slice thicknesses.</p>
						<p>Defaults to MIP (Maximum Intensity Projection)</p>
					`,
					position: 'bottom',
				},
				// Switch tool
				{
					element: document.querySelector('.btn-switch-views'),
					intro: `
						<h4>Switch Tool</h4>
						<p><b>Click</b> to switch the position of the active view with another view.</p>
						<h4>Keyboard Shorcut</h4>
						<p>Hold <b>Option (Mac)</b> or <b>Alt (Win)</b> and <b>Click</b> to perform the same action.</p>
					`,
					position: 'bottom',
				},
				// Settings / Help
				{
					element: document.querySelector('.btn-help'),
					intro: `
						<h4>Farewell Notes</h4>
						<p>Open the Help menu ${getIcon('help-circle')} if you want to see this walkthrough again.</p>
						<p><b>Most importantly...</b> Have fun! Play around, and don't be afraid to ask questions.</p>
					`,
					position: 'bottom',
				},
			],
		})
		.onbeforechange(function() {
			initialBodyStyle = initialBodyStyle || document.body.style // store initial body style
			document.body.style.overflow = 'hidden' // prevent tooltip layer from creating scrollbars
		})
		.onexit(function() {
			document.body.style.overflow = initialBodyStyle // restore initial body style
			storage.setItem(flagKey, true)
		})
	intro.start()
}

export { tryStartTour, startTour }
