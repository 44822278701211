// This cannot detect, or exit from, fullscreen triggered by F11/browser UI
// https://stackoverflow.com/a/44368592/6136739
import { omniDesktop } from '@/electron/omniDesktop'

export default {
	data() {
		return {
			isFullScreenSupported: true,
			isFullScreen: false,
		}
	},
	created() {
		// make sure browser supports a fullscreen property, as well as an enter and exit method
		const fullScreenProperties = ['fullscreen', 'mozFullScreen', 'webkitIsFullScreen']
		const fullScreenProperty = fullScreenProperties.find(p => p in document)
		if ((!fullScreenProperty || document.fullscreenEnabled === false) && !omniDesktop.isConnected)
			this.isFullScreenSupported = false
		if (this.isFullScreenSupported) {
			const onResize = async () => {
				if (omniDesktop.isConnected) {
					this.isFullScreen = await omniDesktop.request('isWindowFullscreen')
				} else this.isFullScreen = document[fullScreenProperty]
			}
			// The fullscreenchange event is not as well supported as the resize event, so we use that
			window.addEventListener('resize', onResize)
			this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', onResize))
		}
	},
	methods: {
		toggleFullScreen() {
			if (this.isFullScreen) return exitFullScreen()
			return enterFullScreen()

			function enterFullScreen() {
				if (omniDesktop.isConnected) {
					omniDesktop.request('setWindowFullscreen', true)
				} else {
					const enterMethods = [
						'requestFullscreen',
						'mozRequestFullScreen',
						'webkitRequestFullscreen',
						'msRequestFullscreen',
					]
					const el = document.documentElement
					const method = enterMethods.find(m => m in el)
					if (!method) return
					el[method]()
				}
			}
			function exitFullScreen() {
				if (omniDesktop.isConnected) {
					omniDesktop.request('setWindowFullscreen', false)
				} else {
					const exitMethods = ['exitFullscreen', 'mozCancelFullScreen', 'webkitExitFullscreen', 'msExitFullscreen']
					const method = exitMethods.find(m => m in document)
					if (!method) return
					document[method]()
				}
			}
		},
	},
}
