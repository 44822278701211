<template>
	<div class="calibration-annotation">
		<h5>Length</h5>
		<input
			ref="lengthInput"
			:value="hasChangedLength ? newLength : currentLength"
			class="input"
			@mousedown.stop
			@input="updatenewLength"
			@keydown.enter="calibrate"
		/>
		<div>
			<ast-radio v-model="unit" value="mm">mm</ast-radio>
			<ast-radio v-model="unit" value="cm">cm</ast-radio>
		</div>
		<div style="text-align: right">
			<button class="btn btn-default" @click="$emit('close')">
				Cancel
			</button>
			<button class="btn btn-primary" :disabled="!canCalibrate" @click="calibrate">
				<svg-icon icon="check" fixed />
				Calibrate
			</button>
		</div>
	</div>
</template>

<script>
import AstRadio from '@components/Radio.vue'
import { removeToolState } from 'cornerstone-tools'

export default {
	name: 'AstCalibrationAnnotation',
	components: {
		AstRadio,
	},
	props: {
		annotation: {
			type: Object,
			required: true,
		},
		event: {
			type: CustomEvent,
			required: true,
		},
		tool: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			hasChangedLength: false,
			newLength: null,
			unit: 'mm',
		}
	},
	computed: {
		canCalibrate() {
			if (!this.hasChangedLength) return false
			if (this.newLength === this.currentLength) return false
			if (!this.newLength) return false
			return true
		},
		currentLength() {
			if (this.unit === 'mm') return this.annotation.length
			return parseFloat((this.annotation.length / 10).toFixed(2))
		},
	},
	watch: {
		'annotation.length': {
			handler() {
				this.$nextTick(() => {
					if (!this.hasChangedLength && document.getSelection() !== this.currentLength) {
						this.$refs.lengthInput.select()
					}
				})
			},
			immediate: true,
		},
	},
	beforeDestroy() {
		removeToolState(this.event.detail.element, this.tool.name, this.annotation)
		this.$emit('update-image')
	},
	methods: {
		updatenewLength(e) {
			this.newLength = parseFloat(e.target.value)
			this.hasChangedLength = true
		},
		calibrate() {
			let newLength = this.newLength
			if (this.unit === 'cm') newLength *= 10
			this.tool.calibrate(this.event, this.annotation, newLength)
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss">
.calibration-annotation {
	& > * {
		margin-top: 8px;
	}
	button {
		margin-top: 16px;
	}
	button + button {
		margin-left: 4px;
	}
}
</style>
