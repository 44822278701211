<template>
	<ast-toolbar-button v-if="hasActiveSeries" title="Select Layout" icon="layout">
		<template #dropdown="{ hide }">
			<ast-toolbar-menu class="viewer-layout-menu">
				<div class="layouts">
					<ast-toolbar-button
						v-for="layout in layouts"
						:key="layout.name"
						:title="layout.title"
						:icon="layout.icon"
						@click.native="layoutSelected(layout, hide)"
					/>
				</div>
				<ul>
					<li v-for="(setting, i) in settings" :key="i" @mousedown.stop="setting.toggle">
						<checkbox :value="_self[setting.computed]" style="margin:0">
							{{ setting.label }}
						</checkbox>
					</li>
				</ul>
				<div v-if="mq.small" class="dock-toolbar">
					Dock Toolbar
					<div>
						<svg-icon
							v-for="(location, i) in ['top', 'bottom', 'left', 'right']"
							:key="i"
							:icon="i <= 1 ? 'dock-bottom' : 'dock-left'"
							:class="{ 'is-selected': toolbarLocation === location }"
							:title="$options.filters.capitalize(location)"
							:style="{ transform: i === 0 || i === 3 ? 'rotate(180deg)' : '' }"
							@click.native="toolbarLocation = location"
						/>
					</div>
				</div>
			</ast-toolbar-menu>
		</template>
	</ast-toolbar-button>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import AstToolbarMenu from '@components/ToolbarMenu.vue'
import AstToolbarButton from '@components/ToolbarButton.vue'
import layouts from '@store/modules/mpr/state/layouts.json'
import { keys as MPRKeys } from '@store/modules/mpr/mutations'
import Checkbox from '@components/Checkbox.vue'

export default {
	name: 'LayoutSelector',
	components: {
		AstToolbarButton,
		AstToolbarMenu,
		Checkbox,
	},
	data() {
		return {
			layouts,
			settings: [
				{
					computed: 'showOverlayText',
					label: 'Overlay Text',
					toggle: () => this.$store.commit('SET_MPR_SHOW_OVERLAY_TEXT', !this.showOverlayText),
				},
				{
					computed: 'showImageOrientationMarkers',
					label: 'Orientation Markers',
					toggle: () => this.$store.commit('SET_MPR_SHOW_IMAGE_ORIENTATION_MARKERS', !this.showImageOrientationMarkers),
				},
				{
					computed: 'showAxis',
					label: 'View Axis',
					toggle: () => this.$store.commit('TOGGLE_MPR_SHOW_AXIS', !this.showAxis),
				},
				{
					computed: 'isSyncWindowLevelsEnabled',
					label: 'Sync Window Levels',
					toggle: () => this.$store.commit('SET_MPR_SYNC_WINDOW_LEVELS', !this.isSyncWindowLevelsEnabled),
				},
			],
		}
	},
	computed: {
		...mapState({
			hasActiveSeries: state => !!state.mpr.activeSeries,
			showOverlayText: state => state.mpr.showOverlayText,
			showImageOrientationMarkers: state => state.mpr.showImageOrientationMarkers,
			showAxis: state => state.mpr.showAxisOverlay,
			isSyncWindowLevelsEnabled: state => state.mpr.syncWindowLevels,
		}),
		toolbarLocation: {
			get() {
				return this.$store.state.viewer.settingsPanel.toolbarLocation
			},
			set(location) {
				this.$store.dispatch('setViewerToolbarLocation', location)
			},
		},
	},
	methods: {
		...mapMutations({
			setLayout: MPRKeys.SET_MPR_LAYOUT,
		}),
		layoutSelected(e, hideDropdown) {
			hideDropdown()
			this.setLayout(e)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.layouts {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	width: 150px;
	padding: 4px 0;
}
// Update if we add more view options
.layouts :last-child {
	grid-column: 2;
}
ul {
	border-top: 1px solid var(--divider);
}
li {
	padding: 8px 8px;
	& > * {
		pointer-events: none;
	}
}
</style>
