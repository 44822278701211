/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { updateImage, metaData, pixelToCanvas } from 'cornerstone-core/dist/cornerstone.js'
import { point } from 'cornerstone-math/dist/cornerstoneMath.js'
import {
	addToolState,
	clearToolState,
	getToolState,
	import as csTools,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import moveHandle from '@/cornerstone/manipulators/moveHandle'
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'
import triggerDoneModifying from './util/triggerDoneModifying'
import getImagePixelSpacing from './util/getImagePixelSpacing'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawCircle = csTools('drawing/drawCircle')
const drawHandles = csTools('drawing/drawHandles')
const drawLine = csTools('drawing/drawLine')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const getNewContext = csTools('drawing/getNewContext')

// Util
const lineSegDistance = csTools('util/lineSegDistance')
const moveAnnotation = csTools('manipulators/moveAnnotation')

/**
 * @export @public @class
 * @name AstTta
 * @classdesc
 * @extends BaseAnnotationTool
 */
export default class AstTta extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstTta',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
				defaultAngle: 105,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
		this.isDragging = true

		this.disabledCallback = this._clearIncompleteToolData.bind(this)
		this.passiveCallback = this._clearIncompleteToolData.bind(this)
		this.enabledCallback = this._clearIncompleteToolData.bind(this)
	}

	// No stats to cache when moving, but CS complains otherwise
	updateCachedStats() {}

	/**
	 * Create the measurement data for this tool with the end handle activated
	 *
	 * @param {*} eventData
	 * @returns
	 */
	createNewMeasurement({ currentPoints, element }) {
		const { x, y } = currentPoints.image
		return {
			visible: true,
			active: true,
			invalidated: true,
			color: undefined,
			handles: [
				{
					x,
					y,
					highlight: true,
					active: false,
				},
				{
					x,
					y,
					highlight: true,
					active: true,
				},
			],
		}
	}

	addNewMeasurement(evt, interactionType = 'mouse') {
		const eventData = evt.detail
		const { element } = eventData
		const toolData = getToolState(element, this.name)

		// Determine Current Step
		const isExistingInstance = toolData && toolData.data && toolData.data.length
		const isAddingCircle = isExistingInstance && toolData.data[0].handles.length < 6
		const isAddingLigament = isExistingInstance && toolData.data[0].handles.length === 6

		//
		const startMovingHandle = () => {
			const movableHandles = data.handles.filter(x => !x.hasBoundingBox)
			moveNewHandle(
				eventData,
				this.name,
				data,
				movableHandles[movableHandles.length - 1],
				this.options,
				interactionType
			)
		}

		let data
		if (isExistingInstance) {
			data = toolData.data[0]
		} else {
			data = this.createNewMeasurement(eventData)
			addToolState(element, this.name, data)
		}
		if (!isExistingInstance) startMovingHandle()
		if (isAddingCircle || isAddingLigament) {
			data.handles = data.handles.concat(this.createNewMeasurement(eventData).handles)
			if (isAddingLigament) {
				data.handles.push({
					// textbox
					active: false,
					hasMoved: false,
					movesIndependently: true,
					drawnIndependently: true,
					allowedOutsideImage: true,
					hasBoundingBox: true,
				})
			}
			startMovingHandle()
		}
		evt.preventDefault()
		evt.stopPropagation()
	}

	/**
	 *
	 *
	 * @param {*} element
	 * @param {*} data
	 * @param {*} coords
	 * @returns
	 */
	pointNearTool(element, data, coords, isMouse = true) {
		const distance = isMouse ? 6 : 12
		if (pointOnCircle(data.handles[1], data.handles[0])) return true // First Condyle
		if (pointOnCircle(data.handles[3], data.handles[2])) return true // Second Condyle
		if (pointOnCircle(data.handles[5], data.handles[4])) return true // Femoral Head
		if (pointOnLine(data.handles[6], data.handles[7])) return true // Ligament
		return false

		function pointOnCircle(centerPoint, outerPoint, radius) {
			if (!centerPoint || (!outerPoint && !radius)) return false
			centerPoint = pixelToCanvas(element, centerPoint)
			if (outerPoint) {
				outerPoint = pixelToCanvas(element, outerPoint)
				radius = point.distance(centerPoint, outerPoint)
			}
			const minDistance = radius - distance / 2
			const maxDistance = radius + distance / 2
			const pointDistance = point.distance(centerPoint, coords)
			return pointDistance > minDistance && pointDistance < maxDistance
		}
		function pointOnLine(start, end) {
			if (!start || !end) return false
			return lineSegDistance(element, start, end, coords) < distance
		}
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	// Custom selected callback for modified notification
	toolSelectedCallback(evt, annotation, interactionType = 'mouse') {
		moveAnnotation(evt, this, annotation, interactionType)

		// wait 180ms for mouseup/touchend, indicating a click rather than a drag
		const UP_EVENT = interactionType === 'mouse' ? 'mouseup' : 'touchend'

		// Notify once we're done moving the tool, regardless of click vs drag
		const changedEvent = e => {
			const { element, image } = evt.detail
			triggerDoneModifying(this.name, element, image)
			evt.detail.element.removeEventListener(UP_EVENT, changedEvent)
		}
		evt.detail.element.addEventListener(UP_EVENT, changedEvent)
		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	/**
	 *
	 *
	 * @param {*} evt
	 * @returns
	 */
	renderToolData(evt) {
		const eventData = evt.detail
		const { image, element } = eventData
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData || !toolData.data || !toolData.data.length) return
		const context = getNewContext(eventData.canvasContext.canvas)
		const data = toolData.data[0] // only ever one instance of this tool

		draw(context, context => {
			if (this.configuration.shadow) {
				context.shadowColor = this.configuration.shadowColor
				context.shadowOffsetX = this.configuration.shadowOffsetX
				context.shadowOffsetY = this.configuration.shadowOffsetY
			}
			const color = toolColors.getColorIfActive(data)

			const haveFirstCircle = data.handles.length >= 2
			const haveSecondCircle = data.handles.length >= 4
			const haveThirdCircle = data.handles.length >= 6
			const haveLigament = data.handles.length >= 8

			// Draw handles
			if (this.configuration.drawHandlesOnHover && data.active !== true) {
				drawHandles(context, eventData, data.handles, {
					color,
					drawHandlesIfActive: true,
				})
			} else {
				drawHandles(context, eventData, data.handles, { color })
			}

			// Draw condyle circles
			if (haveFirstCircle) drawNewCircle(0, 1)
			if (haveSecondCircle) drawNewCircle(2, 3)
			// Draw condyle connector
			const lineDash = [1, 3]
			if (haveFirstCircle && haveSecondCircle)
				drawLine(context, element, data.handles[1], data.handles[3], {
					color,
					lineDash,
				})
			// Draw third circle and connector
			let rightVertical
			if (haveThirdCircle) {
				drawNewCircle(4, 5)
				const start = {
					x: data.handles[1].x + (data.handles[3].x - data.handles[1].x) / 2,
					y: data.handles[1].y + (data.handles[3].y - data.handles[1].y) / 2,
				}
				const end = data.handles[5]
				rightVertical = {
					start,
					end,
				}
				drawLine(context, element, rightVertical.start, rightVertical.end, {
					color,
					lineDash,
				})
			}
			// Draw ligament, remaining lines, and textbox
			if (haveLigament) {
				drawLine(context, element, data.handles[6], data.handles[7], { color })
				const verticalSlope =
					(rightVertical.end.y - rightVertical.start.y) / (rightVertical.end.x - rightVertical.start.x)
				const horizontalSlope =
					((rightVertical.end.x - rightVertical.start.x) / (rightVertical.end.y - rightVertical.start.y)) * -1
				const lowerHorizontal = {
					start: data.handles[7],
					end: _getIntersection(data.handles[7], horizontalSlope, rightVertical),
				}
				const leftVertical = {
					start: data.handles[6],
					end: _getIntersection(data.handles[6], verticalSlope, lowerHorizontal),
				}
				const leftMidpoint = {
					x: leftVertical.start.x + (leftVertical.end.x - leftVertical.start.x) / 2,
					y: leftVertical.start.y + (leftVertical.end.y - leftVertical.start.y) / 2,
				}
				const midHorizontal = {
					start: leftMidpoint,
					end: _getIntersection(leftMidpoint, horizontalSlope, rightVertical),
				}
				const advanceLine = {
					start: leftVertical.end,
					end: lowerHorizontal.start,
				}
				drawLine(context, element, advanceLine.start, advanceLine.end, {
					color,
					lineWidth: 2,
				})
				drawLine(context, element, midHorizontal.start, midHorizontal.end, {
					color,
					lineDash,
				})
				drawLine(context, element, leftVertical.start, leftVertical.end, {
					color,
					lineDash,
				})
				drawTextBox(data.handles[8], leftVertical.end, [`TTA: ${getActualLength(advanceLine)}`])
			}

			function drawNewCircle(outerIndex, innerIndex) {
				const outer = pixelToCanvas(element, data.handles[outerIndex])
				const inner = pixelToCanvas(element, data.handles[innerIndex])
				const radius = point.distance(outer, inner)
				drawCircle(context, element, data.handles[innerIndex], radius, {
					color,
				})
			}
			function drawTextBox(textBox, linkedLineHandle, text) {
				const color = toolColors.getColorIfActive(data)
				let textBoxOffset = 0
				if (!textBox.hasMoved) {
					textBoxOffset = 12
					textBox.x = linkedLineHandle.x
					textBox.y = linkedLineHandle.y + textBoxOffset
				}
				drawLinkedTextBox(
					context,
					element,
					textBox,
					text,
					[linkedLineHandle],
					() => [linkedLineHandle],
					color,
					toolStyle.getToolWidth(),
					textBoxOffset,
					true
				)
			}
			function getActualLength(line) {
				const { rowPixelSpacing, columnPixelSpacing, isPixelSpacingDefined } = getImagePixelSpacing(image)
				const dx = (line.end.x - line.start.x) * columnPixelSpacing
				const dy = (line.end.y - line.start.y) * rowPixelSpacing
				const length = Math.sqrt(dx * dx + dy * dy)
				const unit = isPixelSpacingDefined ? 'mm' : 'px'
				return `${formatNumber(round(length, 2))} ${unit}`
			}
		})
	}

	/**
	 * Clears the tool's state if it is not a completed annotation. Usually
	 * fired when exiting the tool's `active` mode.
	 *
	 * @memberof AstTta
	 */
	_clearIncompleteToolData(element, options) {
		const toolData = getToolState(this.element, this.name)
		const isToolInstance = toolData && toolData.data && toolData.data.length
		const isIncompleteDrawing = isToolInstance && toolData.data[0].handles.length < 9
		if (isIncompleteDrawing) {
			clearToolState(this.element, this.name)
			updateImage(this.element)
		}
	}
}

/**
 *
 *
 * @private @function
 * @param {*} lineAStart
 * @param {*} lineASlope
 * @param {*} lineB
 * @returns {Object} {x, y}
 */
const _getIntersection = function(lineAStart, lineASlope, lineB) {
	// https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection#Given_two_points_on_each_line
	const x1 = lineAStart.x
	const y1 = lineAStart.y
	const x2 = lineAStart.x + 1
	const y2 = lineASlope + lineAStart.y
	const x3 = lineB.start.x
	const y3 = lineB.start.y
	const x4 = lineB.end.x
	const y4 = lineB.end.y
	const x =
		((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) /
		((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4))
	const y =
		((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) /
		((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4))
	return {
		x,
		y,
	}
}
