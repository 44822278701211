/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { updateImage, pixelToCanvas } from 'cornerstone-core/dist/cornerstone.js'
import { point } from 'cornerstone-math/dist/cornerstoneMath.js'
import {
	addToolState,
	clearToolState,
	getToolState,
	import as csTools,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

import moveHandle from '@/cornerstone/manipulators/moveHandle'
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'
import triggerDoneModifying from './util/triggerDoneModifying'

const moveAllHandles = csTools('manipulators/moveAllHandles')

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawCircle = csTools('drawing/drawCircle')
const drawHandles = csTools('drawing/drawHandles')
const drawLine = csTools('drawing/drawLine')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const getNewContext = csTools('drawing/getNewContext')

/**
 * @export @public @class
 * @name AstPennHip
 * @classdesc
 * @extends BaseAnnotationTool
 */
export default class AstPennHip extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstPennHip',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)

		this.disabledCallback = this._clearIncompleteToolData.bind(this)
		this.passiveCallback = this._clearIncompleteToolData.bind(this)
		this.enabledCallback = this._clearIncompleteToolData.bind(this)
	}

	// No stats to cache when moving, but CS complains otherwise
	updateCachedStats() {}

	/**
	 * Create the measurement data for this tool with the end handle activated
	 *
	 * @param {*} eventData
	 * @returns
	 */
	createNewMeasurement({ currentPoints }) {
		const { x, y } = currentPoints.image
		return {
			visible: true,
			active: true,
			invalidated: true,
			color: undefined,
			handles: [
				{
					x,
					y,
					highlight: true,
					active: false,
				},
				{
					x,
					y,
					highlight: true,
					active: true,
				},
			],
		}
	}

	/**
	 *
	 *
	 * @param {HTMLElement} element
	 * @param {*} data
	 * @param {*} coords
	 * @returns {boolean}
	 */
	pointNearTool(element, data, coords, isMouse = true, returnShape = true) {
		const distance = isMouse ? 6 : 12
		if (pointOnCircle(data.handles[1], data.handles[0])) return returnShape ? 'first' : true
		if (pointOnCircle(data.handles[3], data.handles[2])) return returnShape ? 'second' : true
		return false

		function pointOnCircle(centerPoint, outerPoint) {
			if (!centerPoint || !outerPoint) return false
			centerPoint = pixelToCanvas(element, centerPoint)
			outerPoint = pixelToCanvas(element, outerPoint)
			const radius = point.distance(centerPoint, outerPoint)
			const minDistance = radius - distance / 2
			const maxDistance = radius + distance / 2
			const pointDistance = point.distance(centerPoint, coords)
			return pointDistance > minDistance && pointDistance < maxDistance
		}
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	addNewMeasurement(evt, interactionType = 'mouse') {
		const eventData = evt.detail
		const element = eventData.element
		const measurementData = getToolState(element, this.name)

		const isExistingInstance = measurementData && measurementData.data && measurementData.data.length
		const isInstanceUnfinished =
			isExistingInstance && measurementData.data[measurementData.data.length - 1].handles.length < 4

		let data
		if (isInstanceUnfinished) {
			data = measurementData.data[measurementData.data.length - 1]
			data.handles = data.handles.concat(this.createNewMeasurement(eventData).handles)
			data.handles.push({
				active: false,
				hasMoved: false,
				movesIndependently: true,
				drawnIndependently: true,
				allowedOutsideImage: true,
				hasBoundingBox: true,
			})
		} else {
			data = this.createNewMeasurement(eventData)
			addToolState(element, this.name, data)
		}

		startMovingHandles.call(this)
		evt.preventDefault()
		evt.stopPropagation()

		function startMovingHandles() {
			const movableHandles = data.handles.filter(handle => !handle.movesIndependently)

			const doneMovingCallback = () => {
				data.active = isInstanceUnfinished // show active color until both circles are done
				data.invalidated = true
			}

			moveNewHandle(
				eventData,
				this.name,
				data,
				movableHandles[movableHandles.length - 1],
				this.options,
				interactionType,
				doneMovingCallback
			)
		}
	}

	toolSelectedCallback(evt, toolData, interactionType = 'mouse') {
		const eventData = evt.detail
		const { element, image } = eventData
		const coords = eventData.startPoints.canvas

		const circle = this.pointNearTool(element, toolData, coords, true)
		if (!circle) return
		toolData.active = true

		// Move individual circle
		const circleHandles = {
			first: [0, 1],
			second: [2, 3],
		}
		makeCircleMoveIndependently(circleHandles[circle])

		const doneMovingCallback = () => {
			triggerDoneModifying(this.name, element, image)
		}
		moveAllHandles(evt.detail, this.name, toolData, null, this.options, interactionType, doneMovingCallback)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()

		function makeCircleMoveIndependently(indices) {
			toolData.handles = toolData.handles.map((handle, i) => {
				handle.movesIndependently = !indices.includes(i)
				return handle
			})
		}
	}

	/**
	 *
	 *
	 * @param {*} evt
	 * @returns
	 */
	renderToolData(evt) {
		const eventData = evt.detail
		const { element } = eventData
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData || !toolData.data || !toolData.data.length) return
		const context = getNewContext(eventData.canvasContext.canvas)

		// If we have no toolData for this element, return immediately as there is nothing to do
		if (!toolData || !toolData.data || !toolData.data.length) {
			return
		}

		toolData.data.forEach(data => {
			if (data.visible === false) return

			draw(context, context => {
				if (this.configuration.shadow) {
					context.shadowColor = this.configuration.shadowColor
					context.shadowOffsetX = this.configuration.shadowOffsetX
					context.shadowOffsetY = this.configuration.shadowOffsetY
				}
				const color = toolColors.getColorIfActive(data)
				const haveFirstCircle = data.handles.length >= 2
				const haveSecondCircle = data.handles.length >= 4
				// Draw handles
				if (this.configuration.drawHandlesOnHover && data.active !== true) {
					drawHandles(context, eventData, data.handles, {
						color,
						drawHandlesIfActive: true,
					})
				} else {
					drawHandles(context, eventData, data.handles, { color })
				}
				let circle1Outer, circle1Inner, circle2Outer, circle2Inner
				let firstRadius, secondRadius, firstRadiusPixels, secondRadiusPixels
				if (haveFirstCircle) {
					// Draw first circle
					circle1Outer = pixelToCanvas(element, data.handles[0])
					circle1Inner = pixelToCanvas(element, data.handles[1])
					firstRadius = point.distance(circle1Outer, circle1Inner)
					firstRadiusPixels = point.distance(data.handles[0], data.handles[1])
					drawCircle(context, element, data.handles[1], firstRadius, { color })
				}
				if (haveSecondCircle) {
					// Draw second circle
					circle2Outer = pixelToCanvas(element, data.handles[2])
					circle2Inner = pixelToCanvas(element, data.handles[3])
					secondRadius = point.distance(circle2Outer, circle2Inner)
					secondRadiusPixels = point.distance(data.handles[2], data.handles[3])
					drawCircle(context, element, data.handles[3], secondRadius, { color })
					const lineDash = [1, 3]
					// Draw connector
					drawLine(context, element, data.handles[1], data.handles[3], {
						color,
						lineDash,
					})
					// Draw femur radius
					const femurRadius = Math.min(firstRadius, secondRadius)
					if (femurRadius === firstRadius) {
						const rightEdgeOfFemur = {
							x: data.handles[1].x,
							y: data.handles[1].y - firstRadiusPixels,
						}
						drawLine(context, element, data.handles[1], rightEdgeOfFemur, {
							color,
							lineDash,
						})
					} else {
						const rightEdgeOfFemur = {
							x: data.handles[3].x,
							y: data.handles[3].y - secondRadiusPixels,
						}
						drawLine(context, element, data.handles[3], rightEdgeOfFemur, {
							color,
							lineDash,
						})
					}
					// Draw textbox connected to rightmost circle's edge
					const distance = point.distance(circle1Inner, circle2Inner)
					const distractionIndex = formatNumber(round(distance / femurRadius, 2))
					const rightCircle = {
						center: data.handles[3].x > data.handles[1].x ? data.handles[3] : data.handles[1],
						radius: data.handles[3].x > data.handles[1].x ? secondRadiusPixels : firstRadiusPixels,
					}
					const rightEdgeOfShape = {
						x: rightCircle.center.x + rightCircle.radius,
						y: rightCircle.center.y,
					}
					drawTextBox(data.handles[4], rightEdgeOfShape, [`DI: ${distractionIndex}`])
				}
			})
			function drawTextBox(textBox, linkedLineHandle, text) {
				const color = toolColors.getColorIfActive(data)
				let textBoxOffset = 0
				if (!textBox.hasMoved) {
					textBoxOffset = (linkedLineHandle.radius || 6) + 6
					textBox.x = linkedLineHandle.x + textBoxOffset
					textBox.y = linkedLineHandle.y
				}
				drawLinkedTextBox(
					context,
					element,
					textBox,
					text,
					[linkedLineHandle],
					() => [linkedLineHandle],
					color,
					toolStyle.getToolWidth(),
					textBoxOffset,
					true
				)
			}
		})
	}

	/**
	 * Clears the tool's state if it is not a completed annotation. Usually
	 * fired when exiting the tool's `active` mode.
	 *
	 * @memberof AstVertebralHeart
	 */
	_clearIncompleteToolData(element, options) {
		const toolData = getToolState(this.element, this.name)
		const isToolInstance = toolData && toolData.data && toolData.data.length
		const isIncompleteDrawing = isToolInstance && toolData.data[0].handles.length < 5
		if (isIncompleteDrawing) {
			clearToolState(this.element, this.name)
			updateImage(this.element)
		}
	}
}
