<template>
	<div class="thumbnail-list">
		<div
			v-for="(thumbnail, index) in thumbnails"
			:key="index"
			draggable="true"
			class="thumbnail"
			@touchstart="startLongPress"
			@selectstart.prevent
			@contextmenu.prevent
			@dragstart="onDragStart(thumbnail.seriesId, $event)"
			@dragend="onDragEnd($event)"
			@click.prevent="$emit('click', thumbnail.seriesId)"
		>
			<div class="thumbnail-image" :style="findImageBackground(thumbnail)">
				<div class="dot" :style="getCanvasColor(thumbnail.seriesId)"></div>
				<div :id="`progress-${thumbnail.seriesId}`" class="progress-bar"></div>
			</div>

			<svg-icon v-if="thumbnail.isMultiFrame" icon="play-box-outline" />

			<div class="thumbnail-text">
				<p class="name">{{ thumbnail.simpleName }}</p>
				<p>S:{{ thumbnail.seriesNumber }}</p>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { findThumbnailUrl } from '@utils/urlUtility'
// Pollyfill
import { polyfill } from 'mobile-drag-drop'
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour'
import { imageProgress} from '@/imageLoader'
import { events as csEvents } from 'cornerstone-core/dist/cornerstone'
import throttle from '@/utils/throttle'
polyfill({
	dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
})

export default {
	name: 'ThumbnailList',
	props: {
		study: {
			type: Object,
			required: true,
		},
		thumbnails: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapGetters(['canvasColors']),
	},
	mounted() {
		// @Hack
		// Fix for iOS10 touchmove bug (https://github.com/timruffles/ios-html5-drag-drop-shim/issues/77)
		window.addEventListener('touchmove', this.doNothing)
		this.refreshSeriesProgress()
		const throttledProgress = throttle(this.refreshSeriesProgress, 200)
		csEvents.addEventListener('cornerstoneimageloaded', throttledProgress)
		this.$once('hook:beforeDestroy', () => {
			csEvents.removeEventListener('cornerstoneimageloaded', throttledProgress)
		})
	},
	beforeDestroy() {
		window.removeEventListener('touchmove', this.doNothing)
	},
	methods: {
		refreshSeriesProgress() {
			this.study.imageData.series.forEach(series => {
				const loadedImages = series.images.reduce(
					(a, image) => a + (imageProgress.has(image.imageId + image.frameIndex) ? 1 : 0),
					0
				)
				if (loadedImages) {
					// not using Vue to update the progress bars because it slows down image loading
					const progressBar = document.getElementById(`progress-${series.seriesId}`)
					if (!progressBar) return
					const percent = Math.floor((loadedImages / series.images.length) * 100) + '%'
					if (progressBar.style.width !== percent) progressBar.style.width = percent
				}
			})
		},
		findImageBackground(thumbnail) {
			if (!thumbnail || !thumbnail.storageLocation) return {}
				const url = findThumbnailUrl(thumbnail)
			return url ? { backgroundImage: `url('${url}')` } : {}
		},
		getCanvasColor(seriesId) {
			let color = this.canvasColors[seriesId]
			if (!color) color = 'transparent'
			return { background: color }
		},
		startLongPress(startEvent) {
			let longPressTimer
			if (startEvent.isTrusted) {
				// true for real event, false for dispatched event
				startEvent.stopPropagation() // prevent initial, real event from dragging
				longPressTimer = setTimeout(startDragging, 500)
				document.addEventListener('touchmove', onTouchMove)
				document.addEventListener('touchend', cancelDragListeners)
			}

			function startDragging() {
				startEvent.preventDefault()
				if (window.navigator.vibrate) window.navigator.vibrate(100)
				startEvent.srcElement.dispatchEvent(startEvent) // re-fire touchstart event to start dragging
				cancelDragListeners()
			}
			function onTouchMove(moveEvent) {
				if (!moveEvent.changedTouches.length) return
				const tolerance = 10
				const isMoved = (a, b) => Math.abs(a - b) > tolerance
				const getTouch = event => event.changedTouches[0]
				if (isMoved(getTouch(moveEvent).clientX, getTouch(startEvent).clientX)) cancelDragListeners()
				if (isMoved(getTouch(moveEvent).clientY, getTouch(startEvent).clientY)) cancelDragListeners()
			}
			function cancelDragListeners() {
				document.removeEventListener('touchmove', onTouchMove)
				document.removeEventListener('touchend', cancelDragListeners)
				clearTimeout(longPressTimer)
			}
		},
		onDragStart(seriesId, event) {
			this.$emit('drag-thumbnail')
			// Attach data to dragged item
			const data = { seriesId }
			if (this.study.reportId) data.reportId = this.study.reportId
			else data.studyId = this.study.studyId
			event.dataTransfer.setData('text/plain', JSON.stringify(data))
			// Flag that drag from thumbnail list has started
			this.$store.commit('SET_DRAGGING_FROM_THUMBNAIL_LIST', true)
			// Try setting a proper drag image if triggered on a dnd-handle (won't work in IE).
			if (event._dndHandle && event.dataTransfer.setDragImage) {
				const ghostImage = event.target.closest('[draggable="true"]')
				event.dataTransfer.setDragImage(ghostImage)
			}
		},
		onDragEnd(event) {
			this.$store.commit('SET_DRAGGING_FROM_THUMBNAIL_LIST', false)
		},
		doNothing() {},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.thumbnail {
	margin: 0 16px 16px 16px;
	cursor: pointer;
	width: 180px;
	user-select: none;
	position: relative;

	.icon {
		position: absolute;
		top: 0;
		left: 0;
		color: white;
		font-size: 2em;
	}
}

.thumbnail-image {
	position: relative;
	background-color: black;
	width: 100%;
	height: 138px;
	transition: all 0.2s linear;
	background-repeat: no-repeat;
	background-position: center;
	background-size: fill;
	user-select: none;

	.dot {
		position: absolute;
		top: 0;
		right: 0;
		width: 12px;
		height: 12px;
		margin: 4px;
		border-radius: 100%;
	}

	.progress-bar {
		position: absolute;
		bottom: 4px;
		left: 4px;
		width: 0;
		max-width: 172px;
		height: 3px;
		background: var(--button-success-bg);
		transition: width 0.15s ease;
	}
}

.thumbnail-text {
	display: flex;
	padding-top: 4px;
	justify-content: space-between;
	font-size: 0.9em;
	user-select: none;

	.name {
		min-width: 50%;
		word-break: break-word;
		padding-right: 8px;
	}
}
</style>
