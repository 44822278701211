/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { updateImage, metaData, pixelToCanvas } from 'cornerstone-core/dist/cornerstone.js'
import { lineSegment, point } from 'cornerstone-math/dist/cornerstoneMath.js'
import {
	addToolState,
	clearToolState,
	getToolState,
	import as csTools,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import moveHandle from '@/cornerstone/manipulators/moveHandle'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'
import triggerDoneModifying from '@/cornerstone/tools/util/triggerDoneModifying'
import getImagePixelSpacing from './util/getImagePixelSpacing'
const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawCircle = csTools('drawing/drawCircle')
const drawHandles = csTools('drawing/drawHandles')
const drawLine = csTools('drawing/drawLine')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const getNewContext = csTools('drawing/getNewContext')

// Util
const lineSegDistance = csTools('util/lineSegDistance')
const moveAnnotation = csTools('manipulators/moveAnnotation')

/**
 * @export @public @class
 * @name AstTplo
 * @classdesc
 * @extends BaseAnnotationTool
 */
export default class AstTplo extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstTplo',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
				defaultAngle: 105,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
		this.isDragging = true

		this.disabledCallback = this._clearIncompleteToolData.bind(this)
		this.passiveCallback = this._clearIncompleteToolData.bind(this)
		this.enabledCallback = this._clearIncompleteToolData.bind(this)
	}

	// No stats to cache when moving, but CS complains otherwise
	updateCachedStats() {}

	/**
	 * Create the measurement data for this tool with the end handle activated
	 *
	 * @param {*} eventData
	 * @returns
	 */
	createNewMeasurement({ currentPoints, element }) {
		const { x, y } = currentPoints.image
		return {
			visible: true,
			active: true,
			invalidated: true,
			color: undefined,
			handles: [
				{
					x,
					y,
					highlight: true,
					active: false,
				},
				{
					x,
					y,
					highlight: true,
					active: true,
				},
			],
		}
	}

	addNewMeasurement(evt, interactionType = 'mouse') {
		const eventData = evt.detail
		const { element, image } = eventData
		const toolData = getToolState(element, this.name)

		// Determine Current Step
		const isExistingInstance = toolData && toolData.data && toolData.data.length
		const isAddingTibialAxis = isExistingInstance && toolData.data[0].handles.length === 2
		const isAddingPlateau = isExistingInstance && toolData.data[0].handles.length === 3

		//
		const startMovingHandle = () => {
			const movableHandles = data.handles.filter(x => !x.hasBoundingBox)
			moveNewHandle(
				eventData,
				this.name,
				data,
				movableHandles[movableHandles.length - 1],
				this.options,
				interactionType
			)
		}

		let data
		if (isExistingInstance) {
			data = toolData.data[0]
		} else {
			data = this.createNewMeasurement(eventData)
			addToolState(element, this.name, data)
		}
		if (!isExistingInstance) startMovingHandle()
		if (isAddingTibialAxis) addSingleHandle.bind(this)()
		if (isAddingPlateau) {
			data.handles = data.handles.concat(this.createNewMeasurement(eventData).handles)
			data.handles.push({
				// textbox
				active: false,
				hasMoved: false,
				movesIndependently: true,
				drawnIndependently: true,
				allowedOutsideImage: true,
				hasBoundingBox: true,
			})
			startMovingHandle()
		}

		evt.preventDefault()
		evt.stopPropagation()

		function addSingleHandle() {
			data.handles.push({
				x: eventData.currentPoints.image.x,
				y: eventData.currentPoints.image.y,
				highlight: true,
				active: true,
			})
			updateImage(element)
			triggerDoneModifying(this.name, element, image)
		}
	}

	/**
	 *
	 *
	 * @param {*} element
	 * @param {*} data
	 * @param {*} coords
	 * @returns
	 */
	pointNearTool(element, data, coords, isMouse = true) {
		const distance = isMouse ? 15 : 25
		if (pointOnCircle(data.handles[1], data.handles[0])) return true // Talus
		if (pointOnLine(data.handles[1], data.handles[2])) return true // Tibial Axis
		if (pointOnLine(data.handles[3], data.handles[4])) return true // Plateau
		if (data.handles.length < 5) return
		const referenceLine = _getReferenceLine(data.handles)
		if (referenceLine) {
			if (pointOnLine(referenceLine.start, referenceLine.end)) return true
			if (pointOnCircle(_getIntersection(data.handles), null, _getCutRadius(data.handles, element))) return true // Cut circle
		}
		return false

		function pointOnCircle(centerPoint, outerPoint, radius) {
			if (!centerPoint || (!outerPoint && !radius)) return false
			centerPoint = pixelToCanvas(element, centerPoint)
			if (outerPoint) {
				outerPoint = pixelToCanvas(element, outerPoint)
				radius = point.distance(centerPoint, outerPoint)
			}
			const minDistance = radius - distance / 2
			const maxDistance = radius + distance / 2
			const pointDistance = point.distance(centerPoint, coords)
			return pointDistance > minDistance && pointDistance < maxDistance
		}
		function pointOnLine(start, end) {
			if (!start || !end) return false
			return lineSegDistance(element, start, end, coords) < distance
		}
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	// Custom selected callback for modified notification
	toolSelectedCallback(evt, annotation, interactionType = 'mouse') {
		moveAnnotation(evt, this, annotation, interactionType)

		// wait 180ms for mouseup/touchend, indicating a click rather than a drag
		const UP_EVENT = interactionType === 'mouse' ? 'mouseup' : 'touchend'

		// Notify once we're done moving the tool, regardless of click vs drag
		const changedEvent = e => {
			const { element, image } = evt.detail
			triggerDoneModifying(this.name, element, image)
			evt.detail.element.removeEventListener(UP_EVENT, changedEvent)
		}
		evt.detail.element.addEventListener(UP_EVENT, changedEvent)
		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	/**
	 *
	 *
	 * @param {*} evt
	 * @returns
	 */
	renderToolData(evt) {
		const eventData = evt.detail
		const element = eventData.element
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData || !toolData.data || !toolData.data.length) return
		const context = getNewContext(eventData.canvasContext.canvas)
		const data = toolData.data[0] // only ever one instance of this tool

		if (data.visible === false) return

		draw(context, context => {
			if (this.configuration.shadow) {
				context.shadowColor = this.configuration.shadowColor
				context.shadowOffsetX = this.configuration.shadowOffsetX
				context.shadowOffsetY = this.configuration.shadowOffsetY
			}
			const color = toolColors.getColorIfActive(data)

			const haveTalusCircle = data.handles.length >= 2
			const haveTibialAxis = data.handles.length >= 3
			const havePlateau = data.handles.length >= 5

			// Draw handles
			if (this.configuration.drawHandlesOnHover && data.active !== true) {
				drawHandles(context, eventData, data.handles, {
					color,
					drawHandlesIfActive: true,
				})
			} else {
				drawHandles(context, eventData, data.handles, { color })
			}

			// Draw lines
			if (haveTalusCircle) drawTalusCircle()
			if (haveTibialAxis) drawLine(context, element, data.handles[1], data.handles[2], { color })
			if (havePlateau) {
				drawLine(context, element, data.handles[3], data.handles[4], { color })
				const intersection = _getIntersection(data.handles)
				if (!intersection) return
				const referenceLine = _getReferenceLine(data.handles)
				drawLine(context, element, referenceLine.start, referenceLine.end, {
					color,
				})
				drawCircle(context, element, intersection, _getCutRadius(data.handles, element), { color })
				drawTextBox(data.handles[5], referenceLine.end, [`TPA: ${getTPA()}`, `Diameter: ${getCutDiameter()}`])
			}

			function drawTalusCircle() {
				const outer = pixelToCanvas(element, data.handles[0])
				const inner = pixelToCanvas(element, data.handles[1])
				const leftRadius = point.distance(outer, inner)
				drawCircle(context, element, data.handles[1], leftRadius, { color })
			}
			function drawTextBox(textBox, linkedLineHandle, text) {
				const color = toolColors.getColorIfActive(data)
				let textBoxOffset = 0
				if (!textBox.hasMoved) {
					textBoxOffset = 6
					textBox.x = linkedLineHandle.x + textBoxOffset
					textBox.y = linkedLineHandle.y
				}
				drawLinkedTextBox(
					context,
					element,
					textBox,
					text,
					[linkedLineHandle],
					() => [linkedLineHandle],
					color,
					toolStyle.getToolWidth(),
					textBoxOffset,
					true
				)
			}
			function getTPA() {
				const tibialAxis = {
					start: data.handles[1],
					end: data.handles[2],
				}
				const plateau = {
					start: data.handles[3],
					end: data.handles[4],
				}
				const slopeA = getSlope(tibialAxis)
				const slopeB = getSlope(plateau)
				let angle = 0
				if ([-1, 0].includes(slopeA * slopeB)) {
					angle = Math.PI / 2
				} else {
					angle = Math.abs(Math.atan((slopeA - slopeB) / (1 + slopeA * slopeB)))
				}
				if (Number.isNaN(angle)) return
				angle = 90 - (angle * 180) / Math.PI
				return `${formatNumber(round(angle, 1))}°`

				function getSlope(line) {
					if (line.start.x - line.end.x === 0) return Number.MAX_SAFE_INTEGER
					return (line.start.y - line.end.y) / (line.start.x - line.end.x)
				}
			}
			function getCutDiameter() {
				const { rowPixelSpacing, columnPixelSpacing, isPixelSpacingDefined } = getImagePixelSpacing(eventData.image)
				const distanceA = getAspectDistance(data.handles[3])
				const distanceB = getAspectDistance(data.handles[4])
				const unit = isPixelSpacingDefined ? 'mm' : 'px'
				const diameter = Math.max(distanceA, distanceB) * 2
				return `${formatNumber(round(diameter, 2))} ${unit}`

				function getAspectDistance(point) {
					const intersection = _getIntersection(data.handles)
					const dx = (point.x - intersection.x) * columnPixelSpacing
					const dy = (point.y - intersection.y) * rowPixelSpacing
					return Math.sqrt(dx * dx + dy * dy)
				}
			}
		})
	}

	/**
	 * Clears the tool's state if it is not a completed annotation. Usually
	 * fired when exiting the tool's `active` mode.
	 *
	 * @memberof AstTplo
	 */
	_clearIncompleteToolData(element, options) {
		const toolData = getToolState(this.element, this.name)
		const isToolInstance = toolData && toolData.data && toolData.data.length
		const isIncompleteDrawing = isToolInstance && toolData.data[0].handles.length < 6
		if (isIncompleteDrawing) {
			clearToolState(this.element, this.name)
			updateImage(this.element)
		}
	}
}

/**
 * Get the point where the tibial axis and plateau intersect
 *
 * @param {*} handles
 * @returns
 */
function _getIntersection(handles) {
	const tibialAxis = {
		start: handles[1],
		end: handles[2],
	}
	const plateau = {
		start: handles[3],
		end: handles[4],
	}
	return lineSegment.intersectLine(tibialAxis, plateau)
}

/**
 * Get the osteotomy cut radius
 *
 * @param {*} handles
 * @param {*} element
 * @returns {Number} Osteotomy cut radius
 */
function _getCutRadius(handles, element) {
	let intersection = _getIntersection(handles)
	if (!intersection) return
	let aspectA = handles[3]
	let aspectB = handles[4]
	if (element) {
		intersection = pixelToCanvas(element, intersection)
		aspectA = pixelToCanvas(element, aspectA)
		aspectB = pixelToCanvas(element, aspectB)
	}
	const distanceA = point.distance(intersection, aspectA)
	const distanceB = point.distance(intersection, aspectB)
	return Math.max(distanceA, distanceB)
}

/**
 * Get the perpendicular reference line for TPA
 *
 * @param {Array} handles
 * @returns {Object} {start: {x, y}, end: {x, y}}
 */
function _getReferenceLine(handles) {
	const intersection = _getIntersection(handles)
	if (!intersection) return
	const referenceSlope = ((handles[2].x - handles[1].x) / (handles[2].y - handles[1].y)) * -1
	const cutRadius = _getCutRadius(handles)
	const cos = 1 / Math.sqrt(1 + referenceSlope * referenceSlope)
	const sin = referenceSlope / Math.sqrt(1 + referenceSlope * referenceSlope)
	return {
		start: {
			x: intersection.x - cutRadius * cos,
			y: intersection.y - cutRadius * sin,
		},
		end: {
			x: intersection.x + cutRadius * cos,
			y: intersection.y + cutRadius * sin,
		},
	}
}
