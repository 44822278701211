import {
	EllipticalRoiTool,
	external,
	addToolState,
	import as csTools,
	getToolState,
	toolStyle,
	getModule,
	toolColors,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

import toolSelectedCallback from './_shared/toolSelectedCallback.js'
import { formatNumber } from '@utils/numberUtils'

// Manipulators
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import moveHandle from '@/cornerstone/manipulators/moveHandle'

// Handle helpers
import { initHandle } from './util/handles.js'
import getImagePixelSpacing from './util/getImagePixelSpacing'
import round from 'lodash/round'

const getNewContext = csTools('drawing/getNewContext')
const draw = csTools('drawing/draw')
const setShadow = csTools('drawing/setShadow')
const drawEllipse = csTools('drawing/drawEllipse')
const drawHandles = csTools('drawing/drawHandles')
const getROITextBoxCoords = csTools('util/getROITextBoxCoords')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const calculateSUV = csTools('util/calculateSUV')

export default class AstEllipticalRoi extends EllipticalRoiTool {
	constructor(configuration = {}) {
		super({
			name: 'AstEllipticalRoi',
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandles: true,
				showHounsfieldUnits: true,
				showMinMax: true,
			},
			...configuration,
		})
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	addNewMeasurement(evt, interactionType) {
		evt.preventDefault()
		evt.stopPropagation()
		const eventData = evt.detail
		const element = eventData.element
		const measurementData = this.createNewMeasurement(eventData)

		if (!measurementData) {
			return
		}

		initHandle(measurementData.handles.start, null, interactionType, this.options)
		initHandle(measurementData.handles.end, null, interactionType, this.options)

		// Associate this data with this imageId so we can render it and manipulate it
		addToolState(element, this.name, measurementData)

		external.cornerstone.updateImage(element)

		moveNewHandle(eventData, this.name, measurementData, measurementData.handles.end, this.options, interactionType)
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	renderToolData(evt) {
		const toolData = getToolState(evt.currentTarget, this.name)

		if (!toolData) {
			return
		}

		const eventData = evt.detail
		const { image, element } = eventData
		const lineWidth = toolStyle.getToolWidth()
		const lineDash = getModule('globalConfiguration').configuration.lineDash
		const { handleRadius, drawHandlesOnHover, renderDashed } = this.configuration
		const context = getNewContext(eventData.canvasContext.canvas)
		const { isPixelSpacingDefined } = getImagePixelSpacing(image)

		// Meta
		const seriesModule = external.cornerstone.metaData.get('series', image.imageId) || {}

		// Pixel Spacing
		const modality = seriesModule.modality

		draw(context, context => {
			// If we have tool data for this element - iterate over each set and draw it
			for (let i = 0; i < toolData.data.length; i++) {
				const data = toolData.data[i]

				if (data.visible === false) {
					continue
				}

				// Configure
				const color = toolColors.getColorIfActive(data)
				const handleOptions = {
					color,
					handleRadius,
					drawHandlesIfActive: drawHandlesOnHover,
				}

				setShadow(context, this.configuration)

				const ellipseOptions = { color }

				if (renderDashed) {
					ellipseOptions.lineDash = lineDash
				}

				// Draw
				drawEllipse(
					context,
					element,
					data.handles.start,
					data.handles.end,
					ellipseOptions,
					'pixel',
					data.handles.initialRotation
				)
				drawHandles(context, eventData, data.handles, handleOptions)

				// Update textbox stats
				if (data.invalidated === true) {
					if (data.cachedStats) {
						this.throttledUpdateCachedStats(image, element, data)
					} else {
						this.updateCachedStats(image, element, data)
					}
				}

				// Default to textbox on right side of ROI
				if (!data.handles.textBox.hasMoved) {
					const defaultCoords = getROITextBoxCoords(eventData.viewport, data.handles)

					Object.assign(data.handles.textBox, defaultCoords)
				}

				const textBoxAnchorPoints = handles => _findTextBoxAnchorPoints(handles.start, handles.end)
				const textBoxContent = _createTextBoxContent(
					context,
					image.color,
					data.cachedStats,
					modality,
					isPixelSpacingDefined,
					this.configuration
				)
				data.handles.textBox.text = textBoxContent

				data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits)

				if (!data.handles.textBox.hidden) {
					drawLinkedTextBox(
						context,
						element,
						data.handles.textBox,
						textBoxContent,
						data.handles,
						textBoxAnchorPoints,
						color,
						lineWidth,
						10,
						true
					)
				}
			}
		})
	}
}

/**
 *
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {Array.<{x: number, y: number}>}
 */
function _findTextBoxAnchorPoints(startHandle, endHandle) {
	const { left, top, width, height } = _getEllipseImageCoordinates(startHandle, endHandle)

	return [
		{
			// Top middle point of ellipse
			x: left + width / 2,
			y: top,
		},
		{
			// Left middle point of ellipse
			x: left,
			y: top + height / 2,
		},
		{
			// Bottom middle point of ellipse
			x: left + width / 2,
			y: top + height,
		},
		{
			// Right middle point of ellipse
			x: left + width,
			y: top + height / 2,
		},
	]
}

function _getUnit(modality, showHounsfieldUnits) {
	return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : ''
}

/**
 *
 *
 * @param {*} context
 * @param {*} isColorImage
 * @param {*} { area, mean, stdDev, min, max, meanStdDevSUV }
 * @param {*} modality
 * @param {*} hasPixelSpacing
 * @param {*} [options={}] - { showMinMax, showHounsfieldUnits }
 * @returns {string[]}
 */
function _createTextBoxContent(
	context,
	isColorImage,
	{ area, mean, stdDev, min, max, meanStdDevSUV } = {},
	modality,
	hasPixelSpacing,
	options = {}
) {
	const showMinMax = options.showMinMax || false
	const textLines = []

	// Don't display mean/standardDev for color images
	const otherLines = []

	const showStats = !isColorImage && ['PT', 'CT'].includes(modality)

	if (showStats) {
		const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0
		const unit = _getUnit(modality, options.showHounsfieldUnits)

		let meanString = `Mean: ${formatNumber(round(mean, 2))} ${unit}`
		const stdDevString = `Std Dev: ${formatNumber(round(stdDev, 2))} ${unit}`

		// If this image has SUV values to display, concatenate them to the text line
		if (hasStandardUptakeValues) {
			const SUVtext = ' SUV: '

			const meanSuvString = `${SUVtext}${formatNumber(round(meanStdDevSUV.mean, 2))}`
			const stdDevSuvString = `${SUVtext}${formatNumber(round(meanStdDevSUV.stdDev, 2))}`

			const targetStringLength = Math.floor(context.measureText(`${stdDevString}     `).width)

			while (context.measureText(meanString).width < targetStringLength) {
				meanString += ' '
			}

			otherLines.push(`${meanString}${meanSuvString}`)
			otherLines.push(`${stdDevString}     ${stdDevSuvString}`)
		} else {
			otherLines.push(`${meanString}     ${stdDevString}`)
		}

		if (showMinMax) {
			let minString = `Min: ${formatNumber(min)} ${unit}`
			const maxString = `Max: ${formatNumber(max)} ${unit}`
			const targetStringLength = hasStandardUptakeValues
				? Math.floor(context.measureText(`${stdDevString}     `).width)
				: Math.floor(context.measureText(`${meanString}     `).width)

			while (context.measureText(minString).width < targetStringLength) {
				minString += ' '
			}

			otherLines.push(`${minString}${maxString}`)
		}
	}

	textLines.push(_formatArea(area, hasPixelSpacing))
	otherLines.forEach(x => textLines.push(x))

	return textLines
}

/**
 *
 *
 * @param {*} area
 * @param {*} hasPixelSpacing
 * @returns {string} The formatted label for showing area
 */
function _formatArea(area, hasPixelSpacing) {
	// This uses Char code 178 for a superscript 2
	const suffix = hasPixelSpacing ? ` mm${String.fromCharCode(178)}` : ` px${String.fromCharCode(178)}`

	return `Area: ${formatNumber(round(area, 2))}${suffix}`
}

/**
 *
 *
 * @param {*} image
 * @param {*} element
 * @param {*} handles
 * @param {*} modality
 * @param {*} pixelSpacing
 * @returns {Object} The Stats object
 */
function _calculateStats(image, element, handles, modality, pixelSpacing) {
	// Retrieve the bounds of the ellipse in image coordinates
	const ellipseCoordinates = _getEllipseImageCoordinates(handles.start, handles.end)

	// Retrieve the array of pixels that the ellipse bounds cover
	const pixels = external.cornerstone.getPixels(
		element,
		ellipseCoordinates.left,
		ellipseCoordinates.top,
		ellipseCoordinates.width,
		ellipseCoordinates.height
	)

	// Calculate the mean & standard deviation from the pixels and the ellipse details.
	const ellipseMeanStdDev = _calculateEllipseStatistics(pixels, ellipseCoordinates)

	let meanStdDevSUV

	if (modality === 'PT') {
		meanStdDevSUV = {
			mean: calculateSUV(image, ellipseMeanStdDev.mean, true) || 0,
			stdDev: calculateSUV(image, ellipseMeanStdDev.stdDev, true) || 0,
		}
	}

	// Calculate the image area from the ellipse dimensions and pixel spacing
	const area =
		Math.PI *
		((ellipseCoordinates.width * (pixelSpacing.colPixelSpacing || 1)) / 2) *
		((ellipseCoordinates.height * (pixelSpacing.rowPixelSpacing || 1)) / 2)

	return {
		area: area || 0,
		count: ellipseMeanStdDev.count || 0,
		mean: ellipseMeanStdDev.mean || 0,
		variance: ellipseMeanStdDev.variance || 0,
		stdDev: ellipseMeanStdDev.stdDev || 0,
		min: ellipseMeanStdDev.min || 0,
		max: ellipseMeanStdDev.max || 0,
		meanStdDevSUV,
	}
}

/**
 * Retrieve the bounds of the ellipse in image coordinates
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {{ left: number, top: number, width: number, height: number }}
 */
function _getEllipseImageCoordinates(startHandle, endHandle) {
	return {
		left: Math.min(startHandle.x, endHandle.x),
		top: Math.min(startHandle.y, endHandle.y),
		width: Math.abs(startHandle.x - endHandle.x),
		height: Math.abs(startHandle.y - endHandle.y),
	}
}

/**
 * Calculates the statistics of an elliptical region of interest.
 *
 * @private
 * @function calculateEllipseStatistics
 *
 * @param {number[]} sp - Array of the image data's pixel values.
 * @param {Object} ellipse - { top, left, height, width } - An object describing the ellipse.
 * @returns {Object} { count, mean, variance, stdDev, min, max }
 */
function _calculateEllipseStatistics(sp, ellipse) {
	let sum = 0
	let sumSquared = 0
	let count = 0
	let index = 0
	let min = null
	let max = null

	for (let y = ellipse.top; y < ellipse.top + ellipse.height; y++) {
		for (let x = ellipse.left; x < ellipse.left + ellipse.width; x++) {
			const point = {
				x,
				y,
			}

			if (_pointInEllipse(ellipse, point)) {
				if (min === null) {
					min = sp[index]
					max = sp[index]
				}

				sum += sp[index]
				sumSquared += sp[index] * sp[index]
				min = Math.min(min, sp[index])
				max = Math.max(max, sp[index])
				count++
			}

			index++
		}
	}

	if (count === 0) {
		return {
			count,
			mean: 0.0,
			variance: 0.0,
			stdDev: 0.0,
			min: 0.0,
			max: 0.0,
		}
	}

	const mean = sum / count
	const variance = sumSquared / count - mean * mean

	return {
		count,
		mean,
		variance,
		stdDev: Math.sqrt(variance),
		min,
		max,
	}
}

/**
 * Returns true if a point is within an ellipse
 * @export @public @method
 * @name pointInEllipse
 *
 * @param  {Object} ellipse  Object defining the ellipse.
 * @param  {Object} location The location of the point.
 * @returns {boolean} True if the point is within the ellipse.
 */
function _pointInEllipse(ellipse, location) {
	const xRadius = ellipse.width / 2
	const yRadius = ellipse.height / 2

	if (xRadius <= 0.0 || yRadius <= 0.0) {
		return false
	}

	const center = {
		x: ellipse.left + xRadius,
		y: ellipse.top + yRadius,
	}

	/* This is a more general form of the circle equation
	 *
	 * X^2/a^2 + Y^2/b^2 <= 1
	 */

	const normalized = {
		x: location.x - center.x,
		y: location.y - center.y,
	}

	const inEllipse =
		(normalized.x * normalized.x) / (xRadius * xRadius) + (normalized.y * normalized.y) / (yRadius * yRadius) <= 1.0

	return inEllipse
}
