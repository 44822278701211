import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { pixelToCanvas } from 'cornerstone-core/dist/cornerstone.js'
import { point } from 'cornerstone-math'

export const _radii = {
	mouse: {
		default: {
			displayRadius: 6,
			hitRadius: 15,
		},
		precision: {
			displayRadius: 50,
			hitRadius: 54,
		},
	},
	touch: {
		default: {
			displayRadius: 6,
			hitRadius: 25,
		},
		precision: {
			displayRadius: 50,
			hitRadius: 56,
		},
	},
}

export const precisionTools = [
	'AstCalibration',
	'AstLengthAngle',
	'AstRectangleRoi',
	'AstAngle',
	'AstVertebralHeart',
	'AstTplo',
	'AstTta',
	'AstHipDysplasia',
	'AstPennHip',
]

export function initHandle(handle, coords, interactionType, options = {}) {
	if (handle.hasBoundingBox) return
	if (!handle.pointNearHandle) {
		handle.pointNearHandle = pointNearHandle
	}
	if (!handle.radii) {
		handle.radii = _radii[interactionType]
	}
	if (!handle.radius) {
		handle.radius = handle.radii.default.displayRadius
	}
	if (options.precisionHandlesEnabled) {
		handle.precisionEnabled = true
	}
	if (coords) {
		handle.drag = {
			originX: handle.x,
			originY: handle.y,
			originDragX: coords.x,
			originDragY: coords.y,
		}
	}
}

export function handleLengthsAreValid(handles) {
	const { start, middle, end } = handles
	let isValid = true
	if (start && middle) {
		isValid = handleLengthIsValid(start, middle)
	} else if (start && end) {
		isValid = handleLengthIsValid(start, end)
	}
	return isValid
}

export function handleLengthIsValid(from, to) {
	const handleLengthTolerance = 0.8
	return !(from && to && point.distance(from, to) <= handleLengthTolerance)
}

export function pointNearHandle(el, handle, coords) {
	const handleCanvas = pixelToCanvas(el, handle)
	const distance = point.distance(handleCanvas, coords)
	const radiusType = handle.precision ? 'precision' : 'default'
	const radius = handle.radii[radiusType].hitRadius
	return distance < radius
}

export function setPrecisionMode(handle, precision) {
	if (!handle.precisionEnabled) return
	handle.precision = !!precision
	const radiusType = handle.precision ? 'precision' : 'default'
	handle.radius = handle.radii[radiusType].displayRadius
}

export function disablePrecisionForInactiveHandles(element) {
	iteratePrecisionHandles(element, handle => {
		if (handle.active || !handle.precision) return
		setPrecisionMode(handle, false)
	})
}

export function iteratePrecisionHandles(element, fn) {
	precisionTools.forEach(tool => {
		const state = cornerstoneTools.getToolState(element, tool)
		if (!state || !state.data) return
		state.data.forEach(data => {
			const { handles } = data
			if (!handles) return
			Object.entries(handles).forEach(([handleType, handleValue]) => {
				if (typeof handleValue !== 'object') return
				handleValue.type = handleType
				fn(handleValue, tool)
			})
		})
	})
}
