import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'

// Pulling in modules :celebrate:
const BaseTool = cornerstoneTools.import('base/BaseTool')
const drawTextBox = cornerstoneTools.import('drawing/drawTextBox')
const textBoxWidth = cornerstoneTools.import('drawing/textBoxWidth')
const getNewContext = cornerstoneTools.import('drawing/getNewContext')

export default class AstOrientationMarkers extends BaseTool {
  constructor(configuration = {}) {
    const defaultConfig = {
      name: 'AstOrientationMarkers',
      mixins: ['enabledOrDisabledBinaryTool'],
    }
    const initialConfiguration = Object.assign(defaultConfig, configuration)

    super(initialConfiguration)

    this.initialConfiguration = initialConfiguration
  }

  enabledCallback() {
    this._forceImageUpdate()
  }

  disabledCallback() {
    this._forceImageUpdate()
  }

  _forceImageUpdate() {
    const enabledElement = cornerstone.getEnabledElement(this.element)
    const hasImageToUpdate = enabledElement.image

    if (hasImageToUpdate) {
      cornerstone.updateImage(this.element, true)
    }
  }

  renderToolData(evt) {
    const eventData = evt.detail
    const { element, viewport } = eventData

    const markers = getOrientationMarkers(viewport, element)

    if (!markers) {
      return
    }

    const coords = getOrientationMarkerPositions(element, markers)
    const context = getNewContext(eventData.canvasContext.canvas)
    const color = cornerstoneTools.toolColors.getToolColor()

    const textWidths = {
      top: textBoxWidth(context, markers.top, 0),
      left: textBoxWidth(context, markers.left, 0),
      right: textBoxWidth(context, markers.right, 0),
      bottom: textBoxWidth(context, markers.bottom, 0),
    }

    drawTextBox(
      context,
      markers.top,
      coords.top.x - textWidths.top / 2,
      coords.top.y,
      color
    )
    drawTextBox(
      context,
      markers.left,
      coords.left.x - textWidths.left / 2,
      coords.left.y,
      color
    )

    const config = this.configuration

    if (config && config.drawAllMarkers) {
      drawTextBox(
        context,
        markers.right,
        coords.right.x - textWidths.right / 2,
        coords.right.y,
        color
      )
      drawTextBox(
        context,
        markers.bottom,
        coords.bottom.x - textWidths.bottom / 2,
        coords.bottom.y,
        color
      )
    }
  }
}

function getOrientationMarkers(viewport, element) {
  const enabledElement = cornerstone.getEnabledElement(element)
  const imagePlaneMetaData = cornerstone.metaData.get(
    'imagePlaneModule',
    enabledElement.image.imageId
  )

  if (
    !imagePlaneMetaData ||
    !imagePlaneMetaData.rowCosines ||
    !imagePlaneMetaData.columnCosines
  ) {
    return
  }

  const rowString = cornerstoneTools.orientation.getOrientationString(
    imagePlaneMetaData.rowCosines
  )
  const columnString = cornerstoneTools.orientation.getOrientationString(
    imagePlaneMetaData.columnCosines
  )

  const oppositeRowString = cornerstoneTools.orientation.invertOrientationString(
    rowString
  )
  const oppositeColumnString = cornerstoneTools.orientation.invertOrientationString(
    columnString
  )

  const rotation = viewport.rotation - (viewport.rotation % 90)
  const isRotatedClockwise = rotation > 0
  const steps = Math.abs(rotation) / 90
  const orientationStrings = [
    viewport.vflip ? columnString : oppositeColumnString,
    viewport.hflip ? oppositeRowString : rowString,
    viewport.vflip ? oppositeColumnString : columnString,
    viewport.hflip ? rowString : oppositeRowString,
  ]

  for (let i = 0; i < steps; i++) {
    if (isRotatedClockwise) {
      orientationStrings.unshift(orientationStrings.pop())
    } else {
      orientationStrings.push(orientationStrings.shift())
    }
  }

  return {
    top: orientationStrings[0],
    right: orientationStrings[1],
    bottom: orientationStrings[2],
    left: orientationStrings[3],
  }
}

function getOrientationMarkerPositions(element) {
  const enabledElement = cornerstone.getEnabledElement(element)

  const top = {
    x: enabledElement.canvas.width / 2,
    y: 5,
  }
  const bottom = {
    x: enabledElement.canvas.width / 2,
    y: enabledElement.canvas.height - 27,
  }
  const left = {
    x: 10,
    y: enabledElement.canvas.height / 2,
  }
  const right = {
    x: enabledElement.canvas.width - 24,
    y: enabledElement.canvas.height / 2,
  }

  return {
    top,
    bottom,
    left,
    right,
  }
}
