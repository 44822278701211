import { metaData } from 'cornerstone-core/dist/cornerstone.js'

export default function getPixelSpacing(image: any) {
	let { rowPixelSpacing, columnPixelSpacing } = image || {}
	let isPixelSpacingDefined = true
	const imagePlane = metaData.get('imagePlaneModule', image.imageId)
	if (imagePlane) {
		rowPixelSpacing = imagePlane.rowPixelSpacing || imagePlane.rowImagePixelSpacing
		columnPixelSpacing = imagePlane.columnPixelSpacing || imagePlane.colImagePixelSpacing
		isPixelSpacingDefined = imagePlane.isPixelSpacingDefined
	}
	if (rowPixelSpacing == null || columnPixelSpacing == null) {
		rowPixelSpacing = 1
		columnPixelSpacing = 1
		isPixelSpacingDefined = false
	}
	return {
		rowPixelSpacing,
		columnPixelSpacing,
		isPixelSpacingDefined,
	}
}
