<template>
	<div>
		<h5 style="margin-bottom: 4px;">Diameter (mm)</h5>
		<div style="display: flex; margin-bottom: 1rem;">
			<input v-model="formattedDiameter" v-focus class="input" @mousedown.stop @keydown.enter="onEnter" />
			<button class="btn btn-primary" :disabled="!canSetDiameter" @click="setDiameter" @mousedown.stop>
				<svg-icon icon="check" style="opacity: 1;" />
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CircleAnnotationDialog',
	props: {
		annotation: { type: Object, required: true },
	},
	data() {
		return {
			diameter: '',
		}
	},
	computed: {
		formattedDiameter: {
			get() {
				return this.$options.filters.formatNumber(+this.diameter)
			},
			set(value) {
				this.diameter = value === '' ? value : this.$options.filters.parseNumber(value)
			},
		},
		component() {
			return this.annotation.componentRef
		},
		canSetDiameter() {
			const diameter = this.component.validateDiameter(this.diameter)
			return !Number.isNaN(diameter) && diameter !== this.component.worldDiameter
		},
	},
	watch: {
		'component.worldDiameter': {
			immediate: true,
			handler() {
				this.diameter = `${this.component.worldDiameter}`
			},
		},
	},
	methods: {
		setDiameter() {
			this.component.updateDiameter(this.diameter)
		},
		onEnter() {
			if (this.canSetDiameter) {
				this.setDiameter()
			}
		},
	},
}
</script>
