const preventDefaultAndStopPropagation = function(event) {
	event.preventDefault()
	event.stopPropagation()
}

const onCanvasDragOver = function(hoveredCanvasIndex, event) {
	// Only allow drags from current window for repository/email viewers
	if ((this.isRepository || this.isEmail) && !this.draggingFromThumbnailList) {
		return
	}

	if (event.dataTransfer.types.length > 0) {
		// Set active canvas to the one we're hovering over
		if (this.activeCanvasIndex !== hoveredCanvasIndex) {
			this.$store.dispatch('setActiveCanvas', {
				canvasIndex: hoveredCanvasIndex,
			})
		}
		// Tell the world that it is okay to drop here
		preventDefaultAndStopPropagation(event)
		event.dataTransfer.dropEffect = 'copy'
	}
}

const onCanvasDrop = function(event) {
	try {
		const data = JSON.parse(event.dataTransfer.getData('text/plain'))
		if (data) this.switchSeries(data)
	} catch (err) {
		// ignore exceptions when non-JSON text is dropped onto canvas
	} finally {
		preventDefaultAndStopPropagation(event)
	}
}

export default {
	onCanvasDragEnter: preventDefaultAndStopPropagation,
	onCanvasDragExit: preventDefaultAndStopPropagation,
	onCanvasDragLeave: preventDefaultAndStopPropagation,
	onCanvasDragOver,
	onCanvasDrop,
}
