




























































import { eventBus } from '@services/eventBus'
import AnnotationCheckbox from './ViewerAnnotationDialogCheckbox.vue'
import AstCircleRoi from './ViewerAnnotationDialog/AstCircleRoi.vue'
import AstCalibration from './ViewerAnnotationDialog/AstCalibration.vue'
import { updateImage, pixelToCanvas } from 'cornerstone-core'
import { createPopper, VirtualElement } from '@popperjs/core'
import round from 'lodash/round'
import { formatNumber } from '@utils/numberUtils'
import getImagePixelSpacing from '@/cornerstone/tools/util/getImagePixelSpacing'
import { EVENTS as csEvents, removeToolState } from 'cornerstone-tools'

export default {
	name: 'AnnotationDialog',
	components: {
		AnnotationCheckbox,
		AstCalibration,
		AstCircleRoi,
	},
	data() {
		return {
			annotation: null,
			event: null,
			isOpen: false,
			popper: null,
			tool: null,
			toolState: null,
		}
	},
	computed: {
		angleTextboxes() {
			if (this.tool.name !== 'AstLengthAngle') return []
			if (!this.$store.state.viewer.settingsPanel.isLengthAnglesEnabled) return []
			const textboxes = []
			// angle textboxes need to show for either annotation, regardless of which annotation
			// the handles are actually attached to, so we find those related textboxes via lineId
			this.toolState.data.forEach(annotation => {
				const isRelatedAngle = t => t.lineId === this.annotation.uuid || annotation === this.annotation
				textboxes.push(...annotation.handles.textBoxes.filter(isRelatedAngle))
			})
			return textboxes
		},
		textboxes() {
			if (!this.annotation || !this.annotation.handles) return []
			const isTextbox = handle => handle.hasBoundingBox
			// handles array
			if (Array.isArray(this.annotation.handles)) {
				return this.annotation.handles.filter(isTextbox)
			}
			// handles object
			const textboxes = []
			for (const key in this.annotation.handles) {
				const handle = this.annotation.handles[key]
				if (isTextbox(handle)) textboxes.push(handle)
			}
			return textboxes.concat(this.angleTextboxes)
		},
		canHideTextbox() {
			return this.tool.name !== 'AstText'
		},
		canEditTextbox() {
			return ['AstArrowAnnotate', 'AstText'].includes(this.tool.name)
		},
		toolInfo() {
			if (!this.tool) return {}
			return this.$store.getters.availableTools.find(t => t.alias === this.tool.name)
		},
		unit() {
			if (!this.isOpen) return
			const { isPixelSpacingDefined } = getImagePixelSpacing(this.event.detail?.image)
			return isPixelSpacingDefined ? 'mm' : 'px'
		},
	},
	watch: {
		'$store.getters.activeImage'() {
			if (this.isOpen) this.close()
		},
		'annotation.handles': {
			// HACK: Fixes view not updating (though devtools does) for annotations that are not the first
			// annotation for that tool AND were created after the dialog was first opened.  Vue 3 might
			// fix this.
			handler() {
				if (this.isOpen) this.$forceUpdate()
			},
			deep: true,
		},
	},
	created() {
		eventBus.on(eventBus.type.ANNOTATION_CLICK, this.onAnnotationClick)
		eventBus.on(eventBus.type.ANNOTATIONS_CLEARED, this.onAnnotationRemoved)
	},
	mounted() {
		const onKeydown = e => {
			if (!this.isOpen) return
			const activeElement = document.activeElement as HTMLInputElement
			if (activeElement.tagName.toLowerCase() === 'input' && activeElement.type.toLowerCase() === 'text') return
			if (e.key === 'Delete') this.erase()
		}
		document.addEventListener('keydown', onKeydown)
		this.$once('beforeDestroy', () => {
			document.removeEventListener('keydown', onKeydown)
		})
	},
	destroyed() {
		eventBus.off(eventBus.type.ANNOTATION_CLICK, this.onAnnotationClick)
		eventBus.off(eventBus.type.ANNOTATIONS_CLEARED, this.onAnnotationRemoved)
		this.listenForAnnotationRemoved(false)
		if (this.popper) this.popper.destroy()
	},
	methods: {
		onAnnotationClick({ tool, annotation, toolState, event }) {
			this.listenForAnnotationRemoved(false)
			this.tool = tool // cornerstone tool class instance
			this.toolState = toolState // all annotations for the tool
			this.annotation = annotation // the clicked annotation
			this.event = event // the click event
			this.listenForAnnotationRemoved(true)
			if (!this.isOpen) {
				this.isOpen = true
				if (this.mq.small) this.$nextTick(this.positionDialog)
			}
		},
		positionDialog() {
			if (this.popper) this.popper.destroy()
			const referenceElement: VirtualElement = createReferenceElement(
				this.annotation,
				this.textboxes, // pass textboxes since it contains related angle textboxes from other annotations
				this.event
			)
			this.popper = createPopper(referenceElement, this.$refs.dialog, {
				placement: 'left',
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [16, 16],
						},
					},
					// if no room on the left, try the other placements
					{
						name: 'flip',
						options: {
							fallbackPlacements: ['top', 'bottom', 'right'],
							padding: 8,
							boundary: document.querySelector('.layout-pane-container'),
						},
					},
					// otherwise overlap the annotation if no room in the layout-pane-container
					{
						name: 'preventOverflow',
						options: {
							altAxis: true,
							padding: 8,
							boundary: document.querySelector('.layout-pane-container'),
						},
					},
				],
			})
		},
		listenForAnnotationRemoved(isAddingListener) {
			if (!this.event) return
			const toggleListener = isAddingListener ? 'addEventListener' : 'removeEventListener'
			this.event.detail.element[toggleListener](csEvents.MEASUREMENT_REMOVED, this.onAnnotationRemoved)
		},
		erase() {
			removeToolState(this.event.detail.element, this.tool.name, this.annotation)
			this.updateImage()
		},
		onAnnotationRemoved(e) {
			if (!e || e.detail.measurementData === this.annotation) this.close()
		},
		getTextboxLabel(textbox) {
			if (textbox.text) return textbox.text.join('\n') // ellipse, rectangle & circle
			if (this.annotation.text) return this.annotation.text // text/arrow annotation
			if (textbox.angle) return formatNumber(round(textbox.angle, 1)) + '°' // length angles
			if (this.annotation.rAngle) return this.annotation.rAngle + '°' // angle
			if (this.annotation.length) return formatNumber(round(this.annotation.length, 2)) + ' ' + this.unit // length
		},
		setCircleDiameter(newDiameter) {
			this.tool._createCircleFromDiameter(
				this.event.detail,
				this.event.detail.element,
				this.annotation,
				Number(newDiameter)
			)
		},
		updateImage() {
			updateImage(this.event.detail.element)
		},
		close() {
			this.isOpen = false
		},
	},
}

function createReferenceElement(annotation, textboxes, event): VirtualElement {
	// get min and max x and y values for all handles
	const bounds = event.detail.element.getBoundingClientRect()
	const coords: { x: number; y: number }[] = getHandleCoords()
	const xValues = coords.map(c => c.x + bounds.left)
	const yValues = coords.map(c => c.y + bounds.top)
	const left = Math.max(Math.min(...xValues), bounds.left)
	const right = Math.min(Math.max(...xValues), bounds.right)
	const top = Math.max(Math.min(...yValues), bounds.top)
	const bottom = Math.min(Math.max(...yValues), bounds.bottom)
	// offset canvas coordinates to get page coordinates
	const getBoundingClientRect:any = () => {
		return {
			width: right - left,
			height: bottom - top,
			top,
			right,
			bottom,
			left,
		}
	}
	return { getBoundingClientRect }

	function getHandleCoords(): { x: number; y: number }[] {
		let regularHandles = [] // not textboxes
		if (Array.isArray(annotation.handles)) {
			regularHandles = annotation.handles.filter(h => !h.hasBoundingBox)
		} else {
			for (const key in annotation.handles) {
				const handle = annotation.handles[key]
				if (!handle.hasBoundingBox) regularHandles.push(handle)
			}
		}
		let coords = [].concat(...regularHandles.map(handleToCanvasCoords)) // convert regular handles
		coords = coords.concat(...textboxes.map(handleToCanvasCoords)) // convert textboxes
		return coords
	}

	function handleToCanvasCoords(handle): { x: number; y: number }[] {
		if (!handle.x) return []
		const textBoxToCoords = textBox => {
			// textbox coordinates are already relative to the canvas
			return [
				{
					x: textBox.left,
					y: textBox.top,
				},
				{
					x: textBox.left + textBox.width,
					y: textBox.top + textBox.height,
				},
			]
		}
		// convert textbox handles
		if (handle.boundingBox) return textBoxToCoords(handle.boundingBox)
		// other handles' coordinates are relative to the image, so they must be transformed
		return [pixelToCanvas(event.detail.element, handle)]
	}
}
