import * as cornerstoneMath from 'cornerstone-math'

/**
 * @param {Object} (x, y, z)
 * @returns Vector3
 */
const convertToVector3 = (obj: { x: number; y: number; z: number }) => {
	return new cornerstoneMath.Vector3(obj.x, obj.y, obj.z)
}
export default convertToVector3
