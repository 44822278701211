<template>
	<div class="navigation">
		<div class="buttons">
			<ast-toolbar-button
				icon="play-box-outline"
				title="CINE"
				:disabled="!hasMultipleImages"
				@click.native="toggleCine"
			/>
			<template v-if="!hangingProtocol">
				<ast-toolbar-button
					icon="skip-previous"
					title="Previous Series"
					:disabled="!hasPreviousSeries"
					@click.native="$store.dispatch('previousSeries')"
				/>
				<ast-toolbar-button
					icon="skip-next"
					title="Next Series"
					:disabled="!hasNextSeries"
					@click.native="$store.dispatch('nextSeries')"
				/>
			</template>
			<template v-else>
				<ast-toolbar-button
					icon="skip-previous"
					title="Previous Display Set"
					:disabled="!hasPreviousDisplaySet"
					@click.native="setHangingProtocolDisplaySet(activeDisplaySetIndex - 1)"
				/>
				<ast-toolbar-button
					icon="skip-next"
					title="Next Display Set"
					:disabled="!hasNextDisplaySet"
					@click.native="setHangingProtocolDisplaySet(activeDisplaySetIndex + 1)"
				/>
			</template>
		</div>
		<div v-if="hangingProtocols && hangingProtocols.length" class="dropdowns">
			<ast-toolbar-button
				title="Hanging Protocol"
				:disabled="isHangingProtocolLoading"
				:dropdown-location="showDropdownsBelow ? 'bottom' : 'top'"
			>
				<template #label>
					<p class="label full-label">{{ hangingProtocolLabel }}</p>
					<p class="label compact-label">Hanging Protocol</p>
				</template>
				<template #dropdown="{ hide }">
					<ast-toolbar-menu
						:items="hangingProtocolOptions"
						@select="protocol => setHangingProtocol(protocol.value, hide)"
					>
						<template #item="{ item: protocol }">
							<span :class="{ selected: hangingProtocol && protocol.value === hangingProtocol.id }">
								{{ protocol.label }}
							</span>
						</template>
					</ast-toolbar-menu>
				</template>
			</ast-toolbar-button>
			<ast-toolbar-button
				v-if="hangingProtocol"
				title="Display Sets"
				:disabled="isHangingProtocolLoading"
				:dropdown-location="showDropdownsBelow ? 'bottom' : 'top'"
			>
				<template #label>
					<p class="label full-label"> DS: {{ hangingProtocol.displaySets[activeDisplaySetIndex].name }} </p>
					<p class="label compact-label">Display Set</p>
				</template>
				<template #dropdown="{ hide }">
					<ast-toolbar-menu :items="hangingProtocol.displaySets" @select="e => switchDisplaySet(e, hide)">
						<template #item="{ item: displaySet }">
							<span
								:class="{
									selected: displaySet === hangingProtocol.displaySets[activeDisplaySetIndex],
								}"
							>
								{{ displaySet.name }}
							</span>
						</template>
					</ast-toolbar-menu>
				</template>
			</ast-toolbar-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AstToolbarButton from '@components/ToolbarButton'
import AstToolbarMenu from '@components/ToolbarMenu'
import Hammer from 'hammerjs'

export default {
	components: {
		AstToolbarButton,
		AstToolbarMenu,
	},
	props: {
		activeSeries: {
			type: Object,
			required: false,
		},
		allSeries: {
			type: Array,
			required: true,
		},
		showDropdownsBelow: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapState({
			activeDisplaySetIndex: state => state.viewer.activeDisplaySetIndex,
			isHangingProtocolLoading: state => state.viewer.isHangingProtocolLoading,
			hangingProtocol: state => state.viewer.hangingProtocol,
			hangingProtocols: state => state.viewer.hangingProtocols,
		}),
		hasMultipleImages() {
			return this.activeSeries && this.activeSeries.images && this.activeSeries.images.length > 1
		},
		activeSeriesIndex() {
			if (!this.activeSeries) return
			return this.allSeries.findIndex(series => series.seriesId === this.activeSeries.seriesId)
		},
		hasPreviousSeries() {
			return this.activeSeriesIndex > 0
		},
		hasNextSeries() {
			return this.activeSeriesIndex + 1 < this.allSeries.length
		},
		hasPreviousDisplaySet() {
			return this.activeDisplaySetIndex > 0
		},
		hasNextDisplaySet() {
			return this.activeDisplaySetIndex + 1 < this.hangingProtocol.displaySets.length
		},
		hangingProtocolOptions() {
			if (!this.hangingProtocols || !this.hangingProtocols.length) return
			const noHangingProtocol = {
				label: 'No Hanging Protocol',
				value: undefined,
			}
			return [].concat([noHangingProtocol], this.hangingProtocols)
		},
		hangingProtocolLabel() {
			if (!this.hangingProtocol) return 'Hanging Protocol'
			return 'HP: ' + this.hangingProtocol.name
		},
	},
	mounted() {
		let config = {
			inputClass: Hammer.TouchInput,
			recognizers: [
				[
					Hammer.Swipe,
					{
						direction: Hammer.DIRECTION_HORIZONTAL,
						threshold: 10,
						velocity: 0.5,
					},
				],
			],
		}
		this.hammertime = new Hammer(this.$el, config)
		this.hammertime.on('swipe', ev => {
			if (ev.velocityX < 0) {
				this.$store.dispatch('previousSeries')
			} else {
				this.$store.dispatch('nextSeries')
			}
		})
	},
	beforeDestroy() {
		this.hammertime.destroy()
	},
	methods: {
		...mapActions(['setHangingProtocolDisplaySet', 'updateHangingProtocol']),
		toggleCine() {
			if (this.hasMultipleImages) this.$emit('toggle-cine')
		},
		setHangingProtocol(hangingProtocol, hideDropdown) {
			hideDropdown()
			this.$store.commit('SET_USER_SETTINGS', { hangingProtocol })
			this.updateHangingProtocol({ id: hangingProtocol })
		},
		switchDisplaySet(displaySet, hideDropdown) {
			hideDropdown()
			const displaySetIndex = this.hangingProtocol.displaySets.indexOf(displaySet)
			this.setHangingProtocolDisplaySet(displaySetIndex)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row-reverse;
	flex-grow: 1;
	.buttons,
	.dropdowns {
		display: flex;
	}
}

.hanging-protocol-menu li:hover {
	background: var(--menu-item-hover-bg);
}

.selected {
	font-weight: bold;
}

.full-label {
	display: none;
}

.compact-label {
	display: block;
}

@media (min-width: $mqSmall) {
	.full-label {
		display: block;
	}
	.compact-label {
		display: none;
	}
}
</style>
