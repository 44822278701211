/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { updateImage, metaData, pixelToCanvas, getPixels } from 'cornerstone-core/dist/cornerstone.js'
import {
	addToolState,
	getToolState,
	import as csTools,
	removeToolState,
	store,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import { point } from 'cornerstone-math/dist/cornerstoneMath.js'

//
import throttle from '@/utils/throttle.js'
import { formatNumber, parseNumber } from '@utils/numberUtils'
import { openPromptDlg } from '@dialogs/TextPromptDlg'

import toolSelectedCallback from './_shared/toolSelectedCallback.js'

// Manipulators
import moveHandle from './../manipulators/moveHandle.js'
import moveNewHandle from './../manipulators/moveNewHandle.js'
import getROITextBoxCoords from './util/getROITextBoxCoords.js'

// Handle helpers
import { initHandle } from './util/handles.js'
import getImagePixelSpacing from './util/getImagePixelSpacing'
import { eventBus } from '@services/eventBus'
import round from 'lodash/round'
import triggerDoneModifying from './util/triggerDoneModifying.js'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawEllipse = csTools('drawing/drawEllipse')
const drawHandles = csTools('drawing/drawHandles')
const getNewContext = csTools('drawing/getNewContext')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const setShadow = csTools('drawing/setShadow')

// Util
const { pointInEllipse, calculateEllipseStatistics } = csTools('util/ellipseUtils')
const calculateSUV = csTools('util/calculateSUV')

/**
 * @export @public @class
 * @name AstCircleRoi
 * @classdesc
 * @extends BaseAnnotationTool
 */
export default class AstCircleRoi extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstCircleRoi',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
				maxClickDuration: 150,
				showHounsfieldUnits: true,
				showMinMax: true,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
		this.isDragging = true
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	/**
	 * Create the measurement data for this tool with the end handle activated
	 *
	 * @param {*} eventData
	 * @returns
	 */
	createNewMeasurement({ currentPoints, element }) {
		const { x, y } = currentPoints.image
		return {
			visible: true,
			active: true,
			invalidated: true,
			handles: {
				start: {
					x,
					y,
					highlight: true,
					active: false,
				},
				end: {
					x,
					y,
					highlight: true,
					active: true,
				},
				textBox: {
					active: false,
					hasMoved: false,
					movesIndependently: false,
					drawnIndependently: true,
					allowedOutsideImage: true,
					hasBoundingBox: true,
				},
			},
		}
	}

	/**
	 *
	 *
	 * @param {HTMLElement} element
	 * @param {*} data
	 * @param {*} coords
	 * @returns {boolean}
	 */
	pointNearTool(element, data, coords, isMouse = true) {
		const distance = isMouse ? 15 : 25
		const startCanvas = pixelToCanvas(element, data.handles.start)
		const endCanvas = pixelToCanvas(element, data.handles.end)

		const minorEllipse = {
			left: Math.min(startCanvas.x, endCanvas.x) + distance / 2,
			top: Math.min(startCanvas.y, endCanvas.y) + distance / 2,
			width: Math.abs(startCanvas.x - endCanvas.x) - distance,
			height: Math.abs(startCanvas.y - endCanvas.y) - distance,
		}

		const majorEllipse = {
			left: Math.min(startCanvas.x, endCanvas.x) - distance / 2,
			top: Math.min(startCanvas.y, endCanvas.y) - distance / 2,
			width: Math.abs(startCanvas.x - endCanvas.x) + distance,
			height: Math.abs(startCanvas.y - endCanvas.y) + distance,
		}

		const centerCrossHairs = {
			left: startCanvas.x + (endCanvas.x - startCanvas.x) / 2 - distance,
			top: startCanvas.y + (endCanvas.y - startCanvas.y) / 2 - distance,
			width: distance * 2,
			height: distance * 2,
		}

		const pointInMinorEllipse = pointInEllipse(minorEllipse, coords)
		const pointInCenter = pointInEllipse(centerCrossHairs, coords)
		const pointInMajorEllipse = pointInEllipse(majorEllipse, coords)

		if ((pointInMajorEllipse && !pointInMinorEllipse) || pointInCenter) {
			return true
		}

		return false
	}

	addNewMeasurement(evt, interactionType = 'mouse') {
		const isMouse = interactionType === 'mouse'
		const eventData = evt.detail
		const element = eventData.element
		const measurementData = this.createNewMeasurement(eventData)

		if (!measurementData) {
			return
		}

		initHandle(measurementData.handles.start, null, interactionType, this.options)
		initHandle(measurementData.handles.end, null, interactionType, this.options)

		// Drag handle if we haven't released in `maxClickDuration`
		const createCircleFromDiameter = this._createCircleFromDiameter.bind(this, eventData, element, null, null)
		const upEvent = isMouse ? 'mouseup' : 'touchend'
		const handleDoneMove = () => {
			store.state.isToolLocked = false
		}

		setTimeout(() => {
			element.removeEventListener(upEvent, createCircleFromDiameter)
			if (this.isDragging) {
				// Associate this data with this imageId so we can render it and manipulate it
				addToolState(element, this.name, measurementData)
				updateImage(element)

				store.state.isToolLocked = true
				moveNewHandle(
					eventData,
					this.name,
					measurementData,
					measurementData.handles.end,
					{ constrainSquare: true },
					interactionType,
					handleDoneMove
				)
			} else {
				handleDoneMove()
			}
		}, this.configuration.maxClickDuration)

		this.isDragging = true
		element.addEventListener(upEvent, createCircleFromDiameter)

		evt.preventDefault()
		evt.stopPropagation()
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		store.state.isToolLocked = true
		toolData.active = true
		const eventData = evt.detail

		// Move handle
		const handleDoneMove = () => {
			store.state.isToolLocked = false
		}

		moveHandle(
			eventData,
			this.name,
			toolData,
			handle,
			{ constrainSquare: handle !== toolData.handles.textBox },
			interactionType,
			handleDoneMove
		)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	/**
	 *
	 *
	 * @param {*} evt
	 * @returns
	 */
	renderToolData(evt) {
		const toolData = getToolState(evt.currentTarget, this.name)

		// If we have no toolData for this element, return immediately as there is nothing to do
		if (!toolData || !toolData.data || !toolData.data.length) {
			return
		}

		const eventData = evt.detail
		const { image, element } = eventData
		const lineWidth = toolStyle.getToolWidth()
		const { handleDiameter, drawHandlesOnHover } = this.configuration
		const context = getNewContext(eventData.canvasContext.canvas)

		// Meta
		const seriesModule = metaData.get('series', image.imageId) || {}

		// Pixel Spacing
		const modality = seriesModule.modality

		const { isPixelSpacingDefined, ...pixelSpacing } = getImagePixelSpacing(image)

		draw(context, context => {
			// If we have tool data for this element - iterate over each set and draw it
			for (let i = 0; i < toolData.data.length; i++) {
				const data = toolData.data[i]

				// Configure
				const color = toolColors.getColorIfActive(data)
				const handleOptions = {
					color,
					handleDiameter,
					drawHandlesIfActive: drawHandlesOnHover,
				}

				setShadow(context, this.configuration)

				// Draw ellipse, center, and handles
				drawEllipse(context, element, data.handles.start, data.handles.end, {
					color,
				})
				_drawCenter(context, element, data.handles.start, data.handles.end)
				drawHandles(context, eventData, data.handles, handleOptions)

				// Update textbox stats
				if (data.invalidated === true) {
					if (data.cachedStats) {
						_throttledUpdateCachedStats(image, element, data, modality, pixelSpacing)
					} else {
						_updateCachedStats(image, element, data, modality, pixelSpacing)
					}
				}

				// Default position to right most side of circle
				if (!data.handles.textBox.hasMoved) {
					const defaultCoords = getROITextBoxCoords(eventData.viewport, data.handles)
					Object.assign(data.handles.textBox, defaultCoords)
				}

				if (!data.cachedStats.diameter)
					data.cachedStats.diameter = _getRadius(data.handles, pixelSpacing.rowPixelSpacing) * 2

				const textBoxAnchorPoints = handles => {
					return _findTextBoxAnchorPoints(handles.start, handles.end)
				}
				const textBoxContent = _createTextBoxContent(
					context,
					image.color,
					data.cachedStats,
					modality,
					isPixelSpacingDefined,
					this.configuration
				)
				data.handles.textBox.text = textBoxContent

				// If the textbox has moved, it will use `textBoxAnchorPoints` to
				// find the best location to link the textbox to the annotation
				if (!data.handles.textBox.hidden) {
					drawLinkedTextBox(
						context,
						element,
						data.handles.textBox,
						textBoxContent,
						data.handles,
						textBoxAnchorPoints,
						color,
						lineWidth,
						10,
						true
					)
				}
			}
		})
	}

	async _createCircleFromDiameter(eventData, element, existingCircle, diameter) {
		/* eslint-disable no-alert */
		this.isDragging = false
		// do not prompt if click was outside image
		const imageBounds = {
			left: 0,
			top: 0,
			width: eventData.image.width,
			height: eventData.image.height,
		}
		if (!point.insideRect(eventData.currentPoints.image, imageBounds)) return

		const { rowPixelSpacing, columnPixelSpacing, isPixelSpacingDefined } = getImagePixelSpacing(eventData.image)

		const defaultDiameter =
			existingCircle && existingCircle.cachedStats && existingCircle.cachedStats.diameter
				? round(existingCircle.cachedStats.diameter, 2)
				: ''
		if (!diameter) {
			const unit = isPixelSpacingDefined ? 'mm' : 'px'
			diameter = parseNumber(
				await openPromptDlg({
					title: 'New Circle',
					prompt: `Enter circle diameter [${unit}]:`,
					defaultValue: formatNumber(defaultDiameter),
				})
			)
		}
		if (!diameter) return
		let origin = eventData.currentPoints && eventData.currentPoints.image

		if (existingCircle) {
			const start = existingCircle.handles.start
			const end = existingCircle.handles.end

			origin = {
				x: (end.x - start.x) / 2 + start.x,
				y: (end.y - start.y) / 2 + start.y,
			}
		}

		const pixelSpacing = columnPixelSpacing || rowPixelSpacing
		if (pixelSpacing) {
			diameter /= pixelSpacing
		}
		let newCircle = this.createNewMeasurement(eventData)
		const radius = diameter / 2
		newCircle.handles.start.x = origin.x - radius
		newCircle.handles.start.y = origin.y - radius
		newCircle.handles.end.x = origin.x + radius
		newCircle.handles.end.y = origin.y + radius
		newCircle.handles.end.active = false

		/* Create and render new circle */
		addToolState(element, this.name, newCircle)
		removeToolState(element, this.name, existingCircle)
		updateImage(element)

		// Simulate tool selected to refresh annotation dialog
		setTimeout(() => {
			const event = new CustomEvent('circlecreatedfromdiameter', {
				detail: eventData,
			})
			const toolState = getToolState(eventData.element, this.name)
			const annotation = toolState.data[toolState.data.length - 1]
			eventBus.post(eventBus.type.ANNOTATION_CLICK, {
				tool: this,
				toolState,
				annotation,
				event,
			})
			triggerDoneModifying(this.name, element, eventData.image)
		}, 50) // Generous delay for cached stats
	}

	updateCachedStats(image, element, data) {
		// Meta
		const seriesModule = metaData.get('series', image.imageId) || {}
		// Pixel Spacing
		const modality = seriesModule.modality
		// const data = toolData.data[i]
		const { isPixelSpacingDefined, ...pixelSpacing } = getImagePixelSpacing(image)
		_updateCachedStats(image, element, data, modality, pixelSpacing)
	}
}

/**
 *
 */
const _throttledUpdateCachedStats = throttle(_updateCachedStats, 110)

/**
 *
 *
 * @param {*} image
 * @param {*} element
 * @param {*} data
 * @param {*} pixelSpacing
 */
function _updateCachedStats(image, element, data, modality, pixelSpacing) {
	const circleStatistics = _calculateCircleStatistics(
		image,
		element,
		data.handles,
		modality,
		pixelSpacing.rowPixelSpacing
	)
	data.cachedStats = circleStatistics
}

function _findTextBoxAnchorPoints(startHandle, endHandle) {
	const { left, top, width, height } = _getCircleImageCoordinates(startHandle, endHandle)

	return [
		{
			// Top middle point of ellipse
			x: left + width / 2,
			y: top,
		},
		{
			// Left middle point of ellipse
			x: left,
			y: top + height / 2,
		},
		{
			// Bottom middle point of ellipse
			x: left + width / 2,
			y: top + height,
		},
		{
			// Right middle point of ellipse
			x: left + width,
			y: top + height / 2,
		},
	]
}

/**
 *
 *
 * @param {CanvasRenderingContext2D} context
 * @param {*} element
 * @param {*} startHandle
 * @param {*} endHandle
 */
function _drawCenter(context, element, startHandle, endHandle) {
	// Convert Image coordinates to Canvas coordinates given the element
	const handleStartCanvas = pixelToCanvas(element, startHandle)
	const handleEndCanvas = pixelToCanvas(element, endHandle)

	// Retrieve the bounds of the ellipse (left, top, width, and height)
	// In Canvas coordinates
	const leftCanvas = Math.min(handleStartCanvas.x, handleEndCanvas.x)
	const topCanvas = Math.min(handleStartCanvas.y, handleEndCanvas.y)
	const widthCanvas = Math.abs(handleStartCanvas.x - handleEndCanvas.x)
	const heightCanvas = Math.abs(handleStartCanvas.y - handleEndCanvas.y)

	// Draw center +
	const xCenter = leftCanvas + widthCanvas / 2
	const yCenter = topCanvas + heightCanvas / 2
	const plusSize = 4

	context.beginPath()
	context.moveTo(xCenter - plusSize, yCenter)
	context.lineTo(xCenter + plusSize, yCenter)
	context.closePath()
	context.stroke()
	context.beginPath()
	context.moveTo(xCenter, yCenter - plusSize)
	context.lineTo(xCenter, yCenter + plusSize)
	context.closePath()
	context.stroke()
	context.closePath()
}

function _getUnit(modality, showHounsfieldUnits) {
	return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : ''
}

/**
 *
 *
 * @param {*} context
 * @param {*} isColorImage
 * @param {*} [{ area, diameter, mean, stdDev, min, max }={}]
 * @param {*} modality
 * @param {*} hasPixelSpacing
 * @param {*} options
 * @returns
 */
function _createTextBoxContent(
	context,
	isColorImage,
	{ area, diameter, mean, stdDev, min, max, meanStdDevSUV } = {},
	modality,
	hasPixelSpacing,
	options
) {
	const showMinMax = options.showMinMax || false

	const textLines = []

	// Don't display mean/standardDev for color images
	let otherLines = []
	const showStats = !isColorImage && ['PT', 'CT'].includes(modality)

	if (showStats) {
		const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0
		const unit = _getUnit(modality, options.showHounsfieldUnits)

		let meanString = `Mean: ${formatNumber(round(mean, 2))} ${unit}`
		const stdDevString = `Std Dev: ${formatNumber(round(stdDev, 2))} ${unit}`

		// If this image has SUV values to display, concatenate them to the text line
		if (hasStandardUptakeValues) {
			const SUVtext = ' SUV: '

			const meanSuvString = `${SUVtext}${formatNumber(round(meanStdDevSUV.mean, 2))}`
			const stdDevSuvString = `${SUVtext}${formatNumber(round(meanStdDevSUV.stdDev, 2))}`

			const targetStringLength = Math.floor(context.measureText(`${stdDevString}     `).width)

			while (context.measureText(meanString).width < targetStringLength) {
				meanString += ' '
			}

			otherLines.push(`${meanString}${meanSuvString}`)
			otherLines.push(`${stdDevString}     ${stdDevSuvString}`)
		} else {
			otherLines.push(`${meanString}     ${stdDevString}`)
		}

		if (showMinMax) {
			let minString = `Min: ${formatNumber(min)} ${unit}`
			const maxString = `Max: ${formatNumber(max)} ${unit}`
			const targetStringLength = hasStandardUptakeValues
				? Math.floor(context.measureText(`${stdDevString}     `).width)
				: Math.floor(context.measureText(`${meanString}     `).width)

			while (context.measureText(minString).width < targetStringLength) {
				minString += ' '
			}

			otherLines.push(`${minString}${maxString}`)
		}
	}

	// Build our text content
	textLines.push(_formatArea(area, hasPixelSpacing))
	textLines.push(_formatDiameter(diameter, hasPixelSpacing))
	otherLines.forEach(x => textLines.push(x))

	return textLines
}

/**
 *
 *
 * @param {*} area
 * @param {*} hasPixelSpacing
 * @returns
 */
function _formatArea(area, hasPixelSpacing) {
	// This uses Char code 178 for a superscript 2
	const suffix = hasPixelSpacing ? ` mm${String.fromCharCode(178)}` : ` px${String.fromCharCode(178)}`

	return `Area: ${formatNumber(round(area, 2))}${suffix}`
}

/**
 *
 *
 * @param {*} diameter
 * @param {*} hasPixelSpacing
 * @returns
 */
function _formatDiameter(diameter, hasPixelSpacing) {
	const suffix = hasPixelSpacing ? ' mm' : ' px'
	return `Diameter: ${formatNumber(round(diameter, 2))}${suffix}`
}

/**
 *
 *
 * @param {*} image
 * @param {*} element
 * @param {*} handles
 * @param {number} rowPixelSpacing
 * @returns
 */
function _calculateCircleStatistics(image, element, handles, modality, rowPixelSpacing) {
	// Retrieve the bounds of the ellipse in image coordinates
	const circleCoordinates = _getCircleImageCoordinates(handles.start, handles.end)

	// Retrieve the array of pixels that the ellipse bounds cover
	const pixels = getPixels(
		element,
		circleCoordinates.left,
		circleCoordinates.top,
		circleCoordinates.width,
		circleCoordinates.height
	)

	// Calculate the mean & standard deviation from the pixels and the ellipse details
	const circleMeanStdDev = calculateEllipseStatistics(pixels, circleCoordinates)

	let meanStdDevSUV

	if (modality === 'PT') {
		meanStdDevSUV = {
			mean: calculateSUV(image, circleMeanStdDev.mean, true) || 0,
			stdDev: calculateSUV(image, circleMeanStdDev.stdDev, true) || 0,
		}
	}

	// Calculate the image area from the ellipse dimensions and pixel spacing
	const radius = _getRadius(handles, rowPixelSpacing)
	const area = Math.PI * radius * radius

	return {
		diameter: radius ? radius * 2 : 0,
		area: area || 0,
		//
		count: circleMeanStdDev.count || 0,
		mean: circleMeanStdDev.mean || 0,
		variance: circleMeanStdDev.variance || 0,
		stdDev: circleMeanStdDev.stdDev || 0,
		min: circleMeanStdDev.min || 0,
		max: circleMeanStdDev.max || 0,
		//
		meanStdDevSUV,
	}
}

function _getRadius(handles, rowPixelSpacing) {
	const circleCoordinates = _getCircleImageCoordinates(handles.start, handles.end)
	return (circleCoordinates.width * rowPixelSpacing) / 2
}

/**
 *
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns
 */
function _getCircleImageCoordinates(startHandle, endHandle) {
	return {
		left: Math.min(startHandle.x, endHandle.x),
		top: Math.min(startHandle.y, endHandle.y),
		width: Math.abs(startHandle.x - endHandle.x),
		height: Math.abs(startHandle.y - endHandle.y),
	}
}
