import { getToolState, import as csTools } from 'cornerstone-tools'
import { eventBus } from '@services/eventBus'
import triggerDoneModifying from '../util/triggerDoneModifying'

const moveAnnotation = csTools('manipulators/moveAnnotation')

export default function toolSelectedCallback(event, annotation, interactionType = 'mouse') {
	moveAnnotation(event, this, annotation, interactionType)
	const UP_EVENT = interactionType === 'mouse' ? 'mouseup' : 'touchend'

	// Notify once we're done moving the tool, regardless of click vs drag
	// Do this before the right click check to be notified whenever moveAnnotation happens
	const changedEvent = evt => {
		const { element, image } = event.detail
		triggerDoneModifying(this.name, element, image)
		event.detail.element.removeEventListener(UP_EVENT, changedEvent)
	}
	event.detail.element.addEventListener(UP_EVENT, changedEvent)

	if (event.detail.buttons === 2) return

	// wait 180ms for mouseup/touchend, indicating a click rather than a drag
	const postClickEvent = () => {
		const toolState = getToolState(event.detail.element, this.name)
		eventBus.post(eventBus.type.ANNOTATION_CLICK, {
			tool: this,
			toolState,
			annotation,
			event,
		})
	}
	event.detail.element.addEventListener(UP_EVENT, postClickEvent)
	setTimeout(() => {
		event.detail.element.removeEventListener(UP_EVENT, postClickEvent)
	}, 180)
}
