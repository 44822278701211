<template>
  <div
    class="image-placeholder"
    oncontextmenu="return false"
    unselectable="on"
    onselectstart="return false;"
  >
    <p class="placeholder-text">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ImagePlaceholder'
}
</script>

<style lang="scss" scoped>
.image-placeholder {
  border: 1px solid #333;
  display: flex;
  height: 100%;
  align-content: center;
  align-items: center;
  color: #fff;
  background: #000;

  &.isActiveViewer {
    border: 2px solid white;
  }

  .placeholder-text {
    display: block;
    padding: 1rem;
    margin: 0 auto;
    font-size: 1.5rem;
    text-align: center;
  }
}
</style>
