import { updateImage, pageToPixel } from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { initHandle, handleLengthsAreValid, disablePrecisionForInactiveHandles } from '@/cornerstone/tools/util/handles'
import triggerDoneModifying from '../tools/util/triggerDoneModifying'

const { EVENTS, removeToolState, import: csTools, store } = cornerstoneTools
const anyHandlesOutsideImage = csTools('manipulators/anyHandlesOutsideImage')
const clipToBox = csTools('util/clipToBox')
const triggerEvent = csTools('util/triggerEvent')
const state = store.state

const _moveEvents = {
	mouse: [EVENTS.MOUSE_MOVE, EVENTS.MOUSE_DRAG],
	touch: [EVENTS.TOUCH_DRAG],
}

const _moveEndEvents = {
	mouse: [EVENTS.MOUSE_UP, EVENTS.MOUSE_CLICK],
	touch: [EVENTS.TOUCH_END, EVENTS.TOUCH_PINCH, EVENTS.TAP],
}

/**
 * Move a new handle
 * @public
 * @method moveNewHandle
 * @memberof Manipulators
 *
 * @param {*} evtDetail
 * @param {*} toolName
 * @param {*} annotation
 * @param {*} handle
 * @param {*} [options={}]
 * @param {Boolean}  [options.deleteIfHandleOutsideImage]
 * @param {Boolean}  [options.preventHandleOutsideImage]
 * @param {*} [interactionType=mouse]
 * @param {function} [doneMovingCallback]
 * @returns {undefined}
 */
export default function(
	evtDetail,
	toolName,
	annotation,
	handle,
	options,
	interactionType = 'mouse',
	doneMovingCallback
) {
	// Use global defaults, unless overidden by provided options
	options = Object.assign(
		{
			deleteIfHandleOutsideImage: state.deleteIfHandleOutsideImage,
			preventHandleOutsideImage: state.preventHandleOutsideImage,
		},
		options
	)

	annotation.active = true
	handle.active = true
	state.isToolLocked = true

	const { element, currentPoints } = evtDetail
	const coords = currentPoints.image
	initHandle(handle, coords, interactionType, options)
	disablePrecisionForInactiveHandles(element)

	const moveHandler = _moveHandler.bind(this, toolName, annotation, handle, options, interactionType)
	// So we don't need to inline the entire `moveEndEventHandler` function
	const moveEndHandler = evt => {
		_moveEndHandler(
			toolName,
			annotation,
			handle,
			options,
			interactionType,
			{
				moveHandler,
				moveEndHandler,
			},
			evt,
			doneMovingCallback
		)
	}

	// Add event listeners
	_moveEvents[interactionType].forEach(eventType => {
		element.addEventListener(eventType, moveHandler)
	})
	_moveEndEvents[interactionType].forEach(eventType => {
		element.addEventListener(eventType, moveEndHandler)
	})
	element.addEventListener(EVENTS.TOUCH_START, _stopImmediatePropagation)
}

function _moveHandler(toolName, annotation, handle, options, interactionType, evt) {
	const { currentPoints, image, element } = evt.detail
	const page = currentPoints.page
	const targetLocation = pageToPixel(element, page.x, page.y)

	annotation.invalidated = true
	handle.active = true

	const drag = handle.drag
	if (drag) {
		handle.x = drag.originX + currentPoints.image.x - drag.originDragX
		handle.y = drag.originY + currentPoints.image.y - drag.originDragY
		if (options.constrainSquare) {
			const distanceDragged = {
				x: currentPoints.image.x - drag.originX,
				y: currentPoints.image.y - drag.originY,
			}
			const isDifferentDirection =
				(distanceDragged.x < 0 && distanceDragged.y > 0) || (distanceDragged.x > 0 && distanceDragged.y < 0)

			if (Math.abs(distanceDragged.x) > Math.abs(distanceDragged.y)) {
				handle.y = isDifferentDirection ? drag.originY - distanceDragged.x : drag.originY + distanceDragged.x
			} else {
				handle.x = isDifferentDirection ? drag.originX - distanceDragged.y : drag.originX + distanceDragged.y
			}
		}
	} else {
		handle.x = targetLocation.x
		handle.y = targetLocation.y
	}

	if (options && options.preventHandleOutsideImage) {
		clipToBox(handle, image)
	}

	updateImage(element)

	const eventType = EVENTS.MEASUREMENT_MODIFIED
	const modifiedEventData = {
		toolName,
		element,
		measurementData: annotation,
	}

	triggerEvent(element, eventType, modifiedEventData)
}

function _moveEndHandler(
	toolName,
	annotation,
	handle,
	options,
	interactionType,
	{ moveHandler, moveEndHandler },
	evt,
	doneMovingCallback
) {
	const { element, image } = evt.detail

	// "Release" the handle
	annotation.active = false
	annotation.invalidated = true
	handle.active = false
	state.isToolLocked = false

	let success = true

	// Remove event listeners
	_moveEvents[interactionType].forEach(eventType => {
		element.removeEventListener(eventType, moveHandler)
	})
	_moveEndEvents[interactionType].forEach(eventType => {
		element.removeEventListener(eventType, moveEndHandler)
	})
	element.removeEventListener(EVENTS.TOUCH_START, _stopImmediatePropagation)

	if (options.preventHandleOutsideImage) {
		clipToBox(handle, evt.detail.image)
	}

	// Delete the tool data if any handle is outside the image
	if (options.deleteIfHandleOutsideImage && anyHandlesOutsideImage(evt.detail, annotation.handles)) {
		removeToolState(element, toolName, annotation)
		success = false
	}

	// Delete the tool data if handle lengths are invalid
	if (!handleLengthsAreValid(annotation.handles)) {
		removeToolState(element, toolName, annotation)
		success = false
	}

	// Update Image
	updateImage(element)
	triggerDoneModifying(toolName, element, evt.detail.image)

	if (typeof doneMovingCallback === 'function') {
		doneMovingCallback(evt, success)
	}
}

/**
 * Stop the CornerstoneToolsTouchStart event from
 * Becoming a CornerstoneToolsTouchStartActive event when
 * MoveNewHandle ends
 *
 * @private
 * @function _stopImmediatePropagation
 *
 * @param {*} evt
 * @returns {Boolean} false
 */
function _stopImmediatePropagation(evt) {
	evt.stopImmediatePropagation()

	return false
}
