<template>
	<ast-toolbar-button icon="layout" title="Viewer Settings">
		<template #dropdown="{ hide }">
			<ast-toolbar-menu class="viewer-layout-menu">
				<layout-grid-selector :value="activeLayout" style="margin: 1em 0" @input="layoutSelected($event, hide)" />
				<slot name="mpr"></slot>
				<ul>
					<li v-for="(setting, i) in settings" :key="i" @mousedown.stop="setting.toggle">
						<checkbox :value="_self[setting.computed]" style="margin:0">
							{{ setting.label }}
						</checkbox>
					</li>
				</ul>
				<div v-if="mq.small" class="dock-toolbar">
					Dock Toolbar
					<div>
						<svg-icon
							v-for="(location, i) in ['top', 'bottom', 'left', 'right']"
							:key="i"
							:icon="i <= 1 ? 'dock-bottom' : 'dock-left'"
							:class="{ 'is-selected': toolbarLocation === location }"
							:title="$options.filters.capitalize(location)"
							:style="{ transform: i === 0 || i === 3 ? 'rotate(180deg)' : '' }"
							@click.native="toolbarLocation = location"
						/>
					</div>
				</div>
			</ast-toolbar-menu>
		</template>
	</ast-toolbar-button>
</template>

<script>
import { mapState } from 'vuex'
import AstToolbarMenu from '@components/ToolbarMenu.vue'
import AstToolbarButton from '@components/ToolbarButton.vue'
import Checkbox from '@components/Checkbox.vue'
import LayoutGridSelector from '@components/layout/LayoutGridSelector.vue'
import api from "@services/api";

export default {
	name: 'LayoutSelector',
	components: {
		AstToolbarButton,
		AstToolbarMenu,
		Checkbox,
		LayoutGridSelector,
	},
	props: {
		settingsConfig: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			maxRows: 3,
			maxColumns: 3,
			settings: [
				{
					computed: 'overlayText',
					label: 'Overlay Text',
					toggle: () => {
            this.$store.commit('TOGGLE_OVERLAY_TEXT')
            this.saveViewerSetting()
          },
				},
				{
					computed: 'showImageOrientationMarkers',
					label: 'Orientation Markers',
					toggle: () => {
            this.$store.dispatch('toggleImageOrientationMarkers')
            this.saveViewerSetting()
          },
				},
				{
					computed: 'isLengthAnglesEnabled',
					label: 'Intersecting Line Angles',
					toggle: () => {
            this.$store.dispatch('toggleLengthAngles')
            this.saveViewerSetting()
          },
				},
				{
					computed: 'isReferenceLineSynchronizationEnabled',
					label: 'Reference Lines',
					toggle: () => {
            this.$store.dispatch('toggleReferenceLineSynchronization')
            this.saveViewerSetting()
          },
				},
			],
		}
	},
	computed: {
		...mapState({
			overlayText: state => state.viewer.settingsPanel.overlayText,
			showImageOrientationMarkers: state => state.viewer.settingsPanel.isImageOrientationMarkersEnabled,
			isLengthAnglesEnabled: state => state.viewer.settingsPanel.isLengthAnglesEnabled,
			isReferenceLineSynchronizationEnabled: state => state.viewer.settingsPanel.isReferenceLineSynchronizationEnabled,
			activeLayout: state => {
				return {
					c: state.viewer.canvasLayout.canvasesPerRow,
					r: Math.floor(state.viewer.canvasLayout.numCanvases / state.viewer.canvasLayout.canvasesPerRow),
				}
			},
		}),
		toolbarLocation: {
			get() {
				return this.$store.state.viewer.settingsPanel.toolbarLocation
			},
			set(location) {
				this.$store.dispatch('setViewerToolbarLocation', location)
			},
		},
	},
	mounted() {
		this.settings = this.settings.filter(s => {
			if (this.settingsConfig[s.label] && this.settingsConfig[s.label].hidden) return false
			return true
		})
		let debounceTimer
		const setMaxRowsAndColumns = () => {
			clearTimeout(debounceTimer)
			debounceTimer = setTimeout(() => {
				const minCanvasHeight = 100
				const minCanvasWidth = 150
				let maxRows = Math.floor(window.innerHeight / minCanvasHeight) || 1
				let maxColumns = Math.floor(window.innerWidth / minCanvasWidth) || 1
				this.maxRows = Math.min(maxRows, 3)
				this.maxColumns = Math.min(maxColumns, 3)
			}, 100)
		}
		window.addEventListener('resize', setMaxRowsAndColumns)
		this.$once('hook:beforeDestroy', () => {
			window.removeEventListener('resize', this.setMaxRowsAndColumns)
		})
		setMaxRowsAndColumns()
	},
	methods: {
		layoutSelected(e, hideDropdown) {
			hideDropdown()
			this.$emit('layout-selected', e)
		},
    async saveViewerSetting(){
      let payload = {
        overlayTextEnabled: this.overlayText,
        imageOrientationMarkersEnabled: this.showImageOrientationMarkers,
        lengthAnglesEnabled: this.isLengthAnglesEnabled,
        referenceLineSynchronizationEnabled: this.isReferenceLineSynchronizationEnabled
      }
      try {
        await api.user.setUserSetting('ViewerSettings', 'OmniReport', JSON.stringify(payload))
      } finally {
      }
    }
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.viewer-layout-menu {
	flex-direction: column;
	align-items: center;

	.dock-toolbar {
		width: 100%;
		padding: 8px;
		border-top: 1px solid var(--primary-border);
		.icon {
			font-size: 28px;
			opacity: 0.5;
			cursor: pointer;
		}
		.icon + .icon {
			margin-left: 4px;
		}
		.icon.is-selected {
			opacity: 1;
			color: var(--checkbox-checked-bg);
		}
	}

	ul {
		border-top: 1px solid var(--divider);
		width: 100%;
	}
	li {
		padding: 12px 8px;
		& > * {
			pointer-events: none;
		}
	}
	li.disabled {
		user-select: none;
		pointer-events: none;
		& > *:not(.no-disable) {
			opacity: 0.5;
		}
	}
}
</style>
