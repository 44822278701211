<template>
	<viewer-layout
		:primary-toolbar-config="{
			Back: {
				group: 'left',
				class: 'back-btn',
				icon: 'arrow-left',
				action: () => this.$router.push('/consigner-studies'),
			},
		}"
		:settings-config="{
			'Intersecting Line Angles': {
				hidden: true,
			},
			'Reference Lines': {
				hidden: true,
			},
		}"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ViewerLayout from '@router/layouts/Viewer'

export default {
	name: 'ConsignerStudiesViewer',
	components: {
		ViewerLayout,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState({
			// Global
			userId: state => state.auth.claims.userId,
			clientName: state => state.repository.clientName,
			activeClinicCode: state => state.auth.claims.activeClinicCode,
		}),
	},
	async mounted() {
		await Promise.all([this.getConsignerStudyViewerVmAsync({ id: this.id }), this.getHangingProtocols()])
		await this.getUserViewerSettings()
	},
	beforeRouteLeave(to, from, next) {
		this.endRepositoryViewing()
		next()
	},
	methods: {
		...mapActions([
			'getConsignerStudyViewerVmAsync',
			'endRepositoryViewing',
			'getUserViewerSettings',
			'getHangingProtocols',
		]),
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
</style>
