<template>
	<div class="dialog">
		<modal-dialog :is-open="true" title="Multi-Planar Reconstruction" @close="close">
			<template v-if="reasonKeys.length">
				<p>
					We are unable to run MPR for this series, for the following reason{{ reasonKeys.length > 1 ? 's' : '' }}:
				</p>
				<ul>
					<li v-for="reasonKey in reasonKeys" :key="reasonKey">
						{{ disabledReasons[reasonKey] }}
					</li>
				</ul>
			</template>
			<template v-if="warningKeys.length">
				<p><strong>Warning:</strong></p>
				<ul>
					<li v-if="warningKeys.includes('512')">
						This Series has over 900 images, which have a width and height of 512 pixels. This is approaching the limit
						of what Keystone Omni can handle, and you may experience slow loading, instability, or it just won't load.
					</li>
					<li v-if="warningKeys.includes('1024')">
						This Series has over 200 images, which have a width and height of 1024 pixels. This is approaching the limit
						of what Keystone Omni can handle, and you may experience slow loading, instability, or it just won't load.
					</li>
					<br />
					<div>
						<ast-button @click.native="launchMPR">Attempt Loading MPR</ast-button>
						<checkbox v-model="hideWarningChecked" style="margin:16px 0 0 0">
							Don't warn me again
						</checkbox>
					</div>
				</ul>
			</template>
		</modal-dialog>
	</div>
</template>

<script>
import ModalDialog from '@components/ModalDialog.vue'
import { dlg } from '@utils/dlgUtils'
import AstButton from '@components/Button.vue'
import Checkbox from '@components/Checkbox.vue'
import storage from '@services/storage'

const MprDisabledDlg = {
	name: 'MprDisabledDlg',
	components: {
		ModalDialog,
		AstButton,
		Checkbox,
	},
	props: {
		reasonKeys: {
			type: Array,
			required: true,
		},
		warningKeys: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			hideWarningChecked: false,
		}
	},
	computed: {
		disabledReasons() {
			const minimumImages = 15
			return {
				mobile: 'Mobile devices not supported at this time. They are unable to run MPR.',
				size: 'Your window size is too small. Please maximize your browser window.',
				edge:
					'Microsoft Edge has performance and compatibility issues preventing MPR from running. Try using Firefox or Chrome.',
				modality: 'MPR can only be run on CT or MR modalities.',
				minimg: `MPR requires ${minimumImages} or more images in a series.`,
				invalidmin: `MPR requires ${minimumImages} or more images in a series. There are images in this series that don't meet requirements, making it incompatible with MPR.`,
				missing: 'MPR requires metadata for all images in a series',
				orientation:
					'MPR requires a consistent orientation between images in a series. Irregular orientation can cause dramatic rendering and calculation errors.',
				spacing:
					'MPR requires a consistent spacing between images in a series. Irregular spacing can cause dramatic rendering and calculation errors.',
				derived: 'MPR requires a series with original images. This series contains derived images.',
			}
		},
	},
	watch: {
		hideWarningChecked(hide) {
			storage.setItem('hideMPRSizeWarning', hide)
		},
	},
	methods: {
		close() {
			dlg.close(this, true, false, false)
		},
		launchMPR() {
			this.$store.commit('SET_MPR_ACTIVE', true)
			this.close()
		},
	},
}

export default MprDisabledDlg

export function showMprDisabledDialog(reasonKeys, warningKeys) {
	return dlg.open(MprDisabledDlg, { reasonKeys, warningKeys }, false)
}
</script>

<style lang="scss" scoped>
ul {
	margin-block-start: 1em;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 24px;
	> li:not(:last-child) {
		margin-bottom: 8px;
	}
}
</style>
