import {
	external,
	addToolState,
	getToolState,
	import as csTools,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

import toolSelectedCallback from './_shared/toolSelectedCallback.js'

// Manipulators
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import moveHandle from '@/cornerstone/manipulators/moveHandle'

// Handle helpers
import { initHandle } from './util/handles'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawHandles = csTools('drawing/drawHandles')
const drawJoinedLines = csTools('drawing/drawJoinedLines')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const getNewContext = csTools('drawing/getNewContext')
const setShadow = csTools('drawing/setShadow')
const textBoxWidth = csTools('drawing/textBoxWidth')

// Utils
const lineSegDistance = csTools('util/lineSegDistance')

// Cursors
const { angleCursor } = csTools('tools/cursors')

/**
 * @public
 * @class AstAngle
 * @memberof Tools.Annotation
 * @classdesc Create and position an angle by placing three consecutive points.
 * @extends Tools.Base.BaseAnnotationTool
 * @hideconstructor
 *
 * @param {ToolConfiguration} [props={}]
 */
export default class AstAngle extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstAngle',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
			},
			svgCursor: angleCursor,
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)
		super(initialConfiguration)
		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
		this.preventNewMeasurement = false
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	// No stats to cache when moving, but CS complains otherwise
	updateCachedStats() {}

	createNewMeasurement(eventData) {
		// Create the measurement data for this tool with the end handle activated
		return {
			visible: true,
			active: true,
			color: undefined,
			handles: {
				start: {
					x: eventData.currentPoints.image.x,
					y: eventData.currentPoints.image.y,
					highlight: true,
					active: false,
				},
				middle: {
					x: eventData.currentPoints.image.x,
					y: eventData.currentPoints.image.y,
					highlight: true,
					active: true,
				},
				end: {
					x: eventData.currentPoints.image.x,
					y: eventData.currentPoints.image.y,
					highlight: true,
					active: false,
				},
				textBox: {
					active: false,
					hasMoved: false,
					movesIndependently: false,
					drawnIndependently: true,
					allowedOutsideImage: true,
					hasBoundingBox: true,
				},
			},
		}
	}

	pointNearTool(element, data, coords, isMouse = true) {
		if (data.visible === false) {
			return false
		}

		const distance = isMouse ? 15 : 25
		return (
			lineSegDistance(element, data.handles.start, data.handles.middle, coords) < distance ||
			lineSegDistance(element, data.handles.middle, data.handles.end, coords) < distance
		)
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	renderToolData(evt) {
		const eventData = evt.detail
		const enabledElement = eventData.enabledElement
		const { handleRadius, drawHandlesOnHover } = this.configuration
		// If we have no toolData for this element, return immediately as there is nothing to do
		const toolData = getToolState(evt.currentTarget, this.name)

		if (!toolData || !toolData.data || !toolData.data.length) return

		// We have tool data for this element - iterate over each one and draw it
		const context = getNewContext(eventData.canvasContext.canvas)

		const lineWidth = toolStyle.getToolWidth()

		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i]

			if (data.visible === false) {
				continue
			}

			draw(context, context => {
				setShadow(context, this.configuration)

				// Differentiate the color of activation tool
				const color = toolColors.getColorIfActive(data)

				const handleStartCanvas = external.cornerstone.pixelToCanvas(eventData.element, data.handles.start)
				const handleMiddleCanvas = external.cornerstone.pixelToCanvas(eventData.element, data.handles.middle)
				handleMiddleCanvas.radius = data.handles.middle.radius || 6

				drawJoinedLines(context, eventData.element, data.handles.start, [data.handles.middle, data.handles.end], {
					color,
				})

				// Draw the handles
				const handleOptions = {
					color,
					handleRadius,
					drawHandlesIfActive: drawHandlesOnHover,
				}

				drawHandles(context, eventData, data.handles, handleOptions)

				// Default to isotropic pixel size, update suffix to reflect this
				const columnPixelSpacing = eventData.image.columnPixelSpacing || 1
				const rowPixelSpacing = eventData.image.rowPixelSpacing || 1

				const sideA = {
					x: (data.handles.middle.x - data.handles.start.x) * columnPixelSpacing,
					y: (data.handles.middle.y - data.handles.start.y) * rowPixelSpacing,
				}

				const sideB = {
					x: (data.handles.end.x - data.handles.middle.x) * columnPixelSpacing,
					y: (data.handles.end.y - data.handles.middle.y) * rowPixelSpacing,
				}

				const sideC = {
					x: (data.handles.end.x - data.handles.start.x) * columnPixelSpacing,
					y: (data.handles.end.y - data.handles.start.y) * rowPixelSpacing,
				}

				const sideALength = length(sideA)
				const sideBLength = length(sideB)
				const sideCLength = length(sideC)

				// Cosine law
				let angle = Math.acos(
					(Math.pow(sideALength, 2) + Math.pow(sideBLength, 2) - Math.pow(sideCLength, 2)) /
						(2 * sideALength * sideBLength)
				)

				angle *= 180 / Math.PI

				data.rAngle = formatNumber(round(angle, 2) || '')

				if (data.rAngle) {
					const text = textBoxText(data, eventData.image.rowPixelSpacing, eventData.image.columnPixelSpacing)

					const distance = handleMiddleCanvas.radius + 10

					let textCoords

					if (!data.handles.textBox.hasMoved) {
						textCoords = {
							x: handleMiddleCanvas.x,
							y: handleMiddleCanvas.y,
						}

						const padding = 5
						const textWidth = textBoxWidth(context, text, padding)

						if (handleMiddleCanvas.x < handleStartCanvas.x) {
							textCoords.x -= distance + textWidth
						} else {
							textCoords.x += distance
						}

						const transform = external.cornerstone.internal.getTransform(enabledElement)

						transform.invert()

						const coords = transform.transformPoint(textCoords.x, textCoords.y)

						data.handles.textBox.x = coords.x
						data.handles.textBox.y = coords.y
					}
					if (!data.handles.textBox.hidden) {
						drawLinkedTextBox(
							context,
							eventData.element,
							data.handles.textBox,
							text,
							data.handles,
							textBoxAnchorPoints,
							color,
							lineWidth,
							0,
							true
						)
					}
				}
			})
		}

		function textBoxText(data, rowPixelSpacing, columnPixelSpacing) {
			const suffix = !rowPixelSpacing || !columnPixelSpacing ? ' (isotropic)' : ''
			const str = '00B0' // Degrees symbol

			return data.rAngle.toString() + String.fromCharCode(parseInt(str, 16)) + suffix
		}

		function textBoxAnchorPoints(handles) {
			return [handles.start, handles.middle, handles.end]
		}
	}

	addNewMeasurement(evt, interactionType) {
		evt.preventDefault()
		evt.stopPropagation()

		const eventData = evt.detail
		const element = evt.detail.element
		const measurementData = this.createNewMeasurement(eventData)

		if (!measurementData) return

		initHandle(measurementData.handles.start, null, interactionType, this.options)
		initHandle(measurementData.handles.middle, null, interactionType, this.options)
		initHandle(measurementData.handles.end, null, interactionType, this.options)

		// Associate this data with this imageId so we can render it and manipulate it
		addToolState(element, this.name, measurementData)

		external.cornerstone.updateImage(element)

		const doneMovingEndHandleCallback = () => {
			measurementData.active = false
			this.preventNewMeasurement = false
			external.cornerstone.updateImage(element)
		}

		const doneMovingMiddleHandleCallback = doneEvt => {
			// Prevents if the user clicks instead of move the angle points
			if (doneEvt.type !== 'cornerstonetoolsmouseup' && doneEvt.type !== 'cornerstonetoolstouchend') return

			measurementData.active = false
			const { middle, end } = measurementData.handles
			end.active = true
			end.x = middle.x
			end.y = middle.y
			if (interactionType === 'touch') {
				end.x += 50
				end.y += 50
			} else {
				moveNewHandle(
					doneEvt.detail,
					this.name,
					measurementData,
					measurementData.handles.end,
					this.options,
					interactionType,
					doneMovingEndHandleCallback
				)
			}
			external.cornerstone.updateImage(element)
		}

		// Step 1, create start and second middle
		moveNewHandle(
			eventData,
			this.name,
			measurementData,
			measurementData.handles.middle,
			this.options,
			interactionType,
			doneMovingMiddleHandleCallback
		)
	}
}

function length(vector) {
	return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2))
}
