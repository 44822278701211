/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { metaData, updateImage, getEnabledElementsByImageId } from 'cornerstone-core/dist/cornerstone.js'
import { eventBus } from '@services/eventBus'
import {
	addToolState,
	getToolState,
	import as csTools,
	removeToolState,
	toolColors,
	store as csStore,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

import calculateLength from './astCalibration/calculateLength.js'
import store from '@store/'
import toolSelectedCallback from './_shared/toolSelectedCallback.js'

// Manipulators
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import moveHandle from '@/cornerstone/manipulators/moveHandle'
import { initHandle } from './util/handles'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawHandles = csTools('drawing/drawHandles')
const drawLine = csTools('drawing/drawLine')
const setShadow = csTools('drawing/setShadow')
const getNewContext = csTools('drawing/getNewContext')
const lineSegDistance = csTools('util/lineSegDistance')

/**
 * @public
 * @class AstCalibration
 * @memberof Tools.Custom
 *
 * @classdesc Tool for measuring distances.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class AstCalibration extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstCalibration',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	createNewMeasurement(eventData) {
		const { x, y } = eventData.currentPoints.image
		return {
			visible: true,
			active: true,
			color: undefined,
			handles: {
				start: {
					x,
					y,
					highlight: true,
					active: false,
				},
				end: {
					x,
					y,
					highlight: true,
					active: true,
				},
			},
		}
	}

	addNewMeasurement(event, interactionType) {
		const { element, image } = event.detail
		const toolState = getToolState(element, this.name)
		if (toolState && toolState.data && toolState.data.length) return // prevent multiple calibration annotations
		const annotation = this.createNewMeasurement(event.detail)

		addToolState(element, this.name, annotation)
		updateImage(element)

		const doneMovingCallback = () => {
			eventBus.post(eventBus.type.ANNOTATION_CLICK, {
				tool: this,
				toolState,
				annotation,
				event,
			})
			annotation.active = false
		}

		initHandle(annotation.handles.start, null, interactionType, this.options)
		moveNewHandle(
			event.detail,
			this.name,
			annotation,
			annotation.handles.end,
			this.options,
			interactionType,
			doneMovingCallback
		)
	}

	calibrate(event, annotation, newLength) {
		const { image, element } = event.detail
		const imagePlane = metaData.get('imagePlaneModule', image.imageId)
		const rowPixelSpacing = (imagePlane ? imagePlane.rowPixelSpacing : image.rowPixelSpacing) || 1
		const colPixelSpacing = (imagePlane ? imagePlane.columnPixelSpacing : image.columnPixelSpacing) || 1
		const oldPixelSpacing = {
			colPixelSpacing,
			rowPixelSpacing,
		}
		const oldLength = calculateLength(annotation.handles, colPixelSpacing, rowPixelSpacing)
		store.dispatch('updateCalibration', { imageId: image.asterisImageId, oldLength, oldPixelSpacing, newLength })
		eventBus.broadcast(eventBus.type.VUEX_ACTION, {
			type: 'calibrateFromExternalImage',
			payload: {
				imageId: image.asterisImageId,
				oldLength,
				oldPixelSpacing,
				newLength,
			},
		})
		removeToolState(element, this.name, annotation)

		// Force cached stats update for all tool data
		csStore.state.tools.forEach(tool => {
			const toolState = getToolState(element, tool.name)
			if (toolState && toolState.data && tool.updateCachedStats) {
				toolState.data.forEach(d => {
					tool.updateCachedStats(image, element, d)
				})
			}
		})
		updateImage(element)
		// NOTE: Could potentially remove the filter and the above `updateImage` call,
		// but this way we're guaranteed to update the current canvas element, should something strange happen upstream
		getEnabledElementsByImageId(image.imageId)
			.filter(enabledElement => enabledElement.element !== element)
			.forEach(enabledElement => {
				updateImage(enabledElement.element)
			})
	}

	pointNearTool(element, data, coords) {
		return lineSegDistance(element, data.handles.start, data.handles.end, coords) < 25
	}

	handleSelectedCallback(evt, annotation, handle, interactionType = 'mouse') {
		const { element, image } = event.detail
		const toolState = getToolState(element, this.name)
		const doneMovingCallback = () => {
			const imagePlane = metaData.get('imagePlaneModule', image.imageId)
			const rowPixelSpacing = (imagePlane ? imagePlane.rowPixelSpacing : image.rowPixelSpacing) || 1
			const colPixelSpacing = (imagePlane ? imagePlane.columnPixelSpacing : image.columnPixelSpacing) || 1
			annotation.length = calculateLength(annotation.handles, colPixelSpacing, rowPixelSpacing).toFixed(2)
		}
		moveHandle(evt.detail, this.name, annotation, handle, this.options, interactionType, doneMovingCallback)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	renderToolData(evt) {
		const eventData = evt.detail
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData) return
		const context = getNewContext(eventData.canvasContext.canvas)
		const { element, image } = eventData
		const config = this.configuration
		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i]
			if (data.visible === false) continue

			// update length
			const imagePlane = metaData.get('imagePlaneModule', image.imageId)
			const rowPixelSpacing = (imagePlane ? imagePlane.rowPixelSpacing : image.rowPixelSpacing) || 1
			const colPixelSpacing = (imagePlane ? imagePlane.columnPixelSpacing : image.columnPixelSpacing) || 1
			data.length = calculateLength(data.handles, colPixelSpacing, rowPixelSpacing).toFixed(2)

			draw(context, context => {
				setShadow(context, config)
				const color = toolColors.getColorIfActive(data)
				drawLine(context, element, data.handles.start, data.handles.end, {
					color,
					lineDash: [5, 5],
				})
				const handleOptions = {
					color,
					drawHandlesIfActive: config && config.drawHandlesOnHover,
				}
				drawHandles(context, eventData, data.handles, handleOptions)
			})
		}
	}
}
