/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { updateImage, metaData, pixelToCanvas } from 'cornerstone-core/dist/cornerstone.js'
import { point } from 'cornerstone-math/dist/cornerstoneMath.js'
import {
	addToolState,
	clearToolState,
	getToolState,
	import as csTools,
	store,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import { openPromptDlg } from '@dialogs/TextPromptDlg'
import { formatNumber, parseNumber } from '@utils/numberUtils'
import {
	moveAndRotateHandlesMouse,
	moveAndRotateHandlesTouch,
} from '@/cornerstone/manipulators/moveAndRotateHandles.js'
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import round from 'lodash/round'
import triggerDoneModifying from './util/triggerDoneModifying'
import getImagePixelSpacing from './util/getImagePixelSpacing'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawHandles = csTools('drawing/drawHandles')
const drawLine = csTools('drawing/drawLine')
const drawLink = csTools('drawing/drawLink')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const drawTextBox = csTools('drawing/drawTextBox')
const getNewContext = csTools('drawing/getNewContext')

// Manipulators
const moveAllHandles = csTools('manipulators/moveAllHandles')

// Util
const lineSegDistance = csTools('util/lineSegDistance')

/**
 * @export @public @class
 * @name AstVertebralHeart
 * @classdesc
 * @extends BaseAnnotationTool
 */
export default class AstVertebralHeart extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstVertebralHeart',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
				defaultAngle: 105,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
		this.isDragging = true

		this.disabledCallback = this._clearIncompleteToolData.bind(this)
		this.passiveCallback = this._clearIncompleteToolData.bind(this)
		this.enabledCallback = this._clearIncompleteToolData.bind(this)
	}

	// No stats to cache when moving, but CS complains otherwise
	updateCachedStats() {}

	/**
	 * Create the measurement data for this tool with the end handle activated
	 *
	 * @param {*} eventData
	 * @returns
	 */
	createNewMeasurement({ currentPoints, element }) {
		const { x, y } = currentPoints.image
		return {
			visible: true,
			active: true,
			invalidated: true,
			color: undefined,
			handles: [
				{
					x,
					y,
					highlight: true,
					active: false,
				},
				{
					x,
					y,
					highlight: true,
					active: true,
				},
				{
					active: false,
					hasMoved: false,
					movesIndependently: true,
					drawnIndependently: true,
					allowedOutsideImage: true,
					hasBoundingBox: true,
				},
			],
		}
	}

	addNewMeasurement(evt, interactionType = 'mouse') {
		const eventData = evt.detail
		const { element } = eventData
		const toolData = getToolState(element, this.name)

		// Determine Current Step
		const isExistingInstance = toolData && toolData.data && toolData.data.length
		const isAddingSecondLine = isExistingInstance && toolData.data[0].handles.length === 3
		const isAddingVertEdge = isExistingInstance && toolData.data[0].handles.length === 6

		let data
		if (isExistingInstance) {
			data = toolData.data[0]
		} else {
			data = this.createNewMeasurement(eventData)
			addToolState(element, this.name, data)
		}
		if (isAddingSecondLine) {
			data.handles = data.handles.concat(this.createNewMeasurement(eventData).handles)
		}
		if (!isExistingInstance || isAddingSecondLine)
			this._addHeartLine(eventData, data, isAddingSecondLine, interactionType)
		if (isAddingVertEdge) this._addVertEdge(eventData, data)
		evt.preventDefault()
		evt.stopPropagation()
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		store.state.isToolLocked = true
		toolData.active = true
		const isMouse = interactionType === 'mouse'
		const eventData = evt.detail
		const { element } = eventData
		const upEvent = isMouse ? 'mouseup' : 'touchend'

		// Move handle
		const handleMove = isMouse ? moveAndRotateHandlesMouse : moveAndRotateHandlesTouch
		const isVhsTextbox = toolData.handles.indexOf(handle) === 9
		const handleDoneMove = _handleDoneMove.bind(this, eventData, toolData, element, this.name)
		// If VHS textbox, show prompt if mouseup event occurs right away (a click)
		if (isVhsTextbox) {
			const maxClickDuration = 150
			const promptForVertebrae = _promptForVertebrae.bind(this, eventData, toolData)
			this.isDragging = true

			// Drag handle if we haven't released in `maxClickDuration`
			setTimeout(() => {
				element.removeEventListener(upEvent, promptForVertebrae)
				if (this.isDragging) {
					handleMove(
						isMouse ? eventData : evt,
						this.name,
						toolData,
						handle,
						handleDoneMove,
						this.configuration.preventHandleOutsideImage,
						true
					)
				} else {
					_handleDoneMove(eventData, toolData, element, this.name)
				}
			}, maxClickDuration)

			// Prompt for vertebrae count if we released within `maxClickDuration`
			element.addEventListener(upEvent, promptForVertebrae)
		} else {
			handleMove(
				isMouse ? eventData : evt,
				this.name,
				toolData,
				handle,
				handleDoneMove,
				this.configuration.preventHandleOutsideImage,
				true
			)
		}

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	toolSelectedCallback(evt, toolData, interactionType = 'mouse') {
		const isMouse = interactionType === 'mouse'
		const shapeHandles = {
			heart1: [0, 1],
			heart2: [3, 4],
			vert: [6, 7],
		}

		const eventData = evt.detail
		const { element } = eventData
		const coords = eventData.startPoints.canvas
		const shape = this.pointNearTool(element, toolData, coords, true, isMouse)
		if (!shape) return

		toolData.active = true

		// Move shape
		_makeHandlesMoveAsGroup(toolData, shapeHandles[shape])

		const doneMovingCallback = _handleDoneMove.bind(this, eventData, toolData, element, this.name)
		moveAllHandles(evt.detail, this.name, toolData, null, this.options, interactionType, doneMovingCallback)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	/**
	 *
	 *
	 * @param {*} element
	 * @param {*} data
	 * @param {*} coords
	 * @returns
	 */
	pointNearTool(element, data, coords, returnShape = true, isMouse = true) {
		const distance = isMouse ? 15 : 25
		if (pointOnLine(data.handles[0], data.handles[1])) return returnShape ? 'heart1' : true
		if (pointOnLine(data.handles[3], data.handles[4])) return returnShape ? 'heart2' : true
		if (pointNearVert()) return returnShape ? 'vert' : true
		return false

		function pointNearVert() {
			if (data.handles.length < 10) return
			if (pointOnLine(data.handles[6], data.handles[7])) return true
			const vertLines = _getVertLines(data.handles)
			if (pointOnLine(vertLines[0].start, vertLines[0].end)) return true
			if (pointOnLine(vertLines[1].start, vertLines[1].end)) return true
		}
		function pointOnLine(start, end) {
			if (!start || !end) return false
			return lineSegDistance(element, start, end, coords) < distance
		}
	}

	/**
	 *
	 *
	 * @param {*} evt
	 * @returns
	 */
	renderToolData(evt) {
		const eventData = evt.detail
		const { image, element } = eventData
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData || !toolData.data || !toolData.data.length) return
		const context = getNewContext(eventData.canvasContext.canvas)
		const data = toolData.data[0] // only ever one instance of this tool

		if (data.visible === false) return

		draw(context, context => {
			if (this.configuration.shadow) {
				context.shadowColor = this.configuration.shadowColor || '#000000'
				context.shadowOffsetX = this.configuration.shadowOffsetX || 1
				context.shadowOffsetY = this.configuration.shadowOffsetY || 1
			}
			const color = toolColors.getColorIfActive(data)

			const haveFirstLine = data.handles.length >= 3
			const haveSecondLine = data.handles.length >= 6
			const haveVertEdge = data.handles.length >= 10

			// Draw handles
			if (this.configuration.drawHandlesOnHover && data.active !== true) {
				drawHandles(context, eventData, data.handles, {
					color,
					drawHandlesIfActive: true,
				})
			} else {
				drawHandles(context, eventData, data.handles, { color })
			}

			// Draw lines
			const { rowPixelSpacing, columnPixelSpacing, isPixelSpacingDefined } = getImagePixelSpacing(image)
			let firstLength, secondLength, unit
			if (haveFirstLine) {
				drawLine(context, element, data.handles[0], data.handles[1], { color })
			}
			if (haveSecondLine) {
				drawLine(context, element, data.handles[3], data.handles[4], { color })
			}
			if (haveVertEdge) {
				drawLine(context, element, data.handles[6], data.handles[7], { color })
				const vertLines = _getVertLines(data.handles)
				drawLine(context, element, vertLines[0].start, vertLines[0].end, {
					color,
				})
				drawLine(context, element, vertLines[1].start, vertLines[1].end, {
					color,
				})
				drawCenteredTextBox(data.handles[8], data.handles[7], 'T4', true)
				drawCenteredTextBox(data.handles[9], data.handles[6], getVhsText(), false)
			}

			// Draw measurement textboxes
			if (haveFirstLine) {
				unit = isPixelSpacingDefined ? 'mm' : 'px'
				let label = ''
				firstLength = getLength(data.handles[0], data.handles[1])
				if (haveSecondLine) {
					secondLength = getLength(data.handles[3], data.handles[4])
					label = firstLength > secondLength ? 'L: ' : 'S: '
				}
				drawAnchoredTextBox(data.handles[2], data.handles[1], `${label}${formatNumber(round(firstLength, 2))} ${unit}`)
			}
			if (haveSecondLine) {
				const label = secondLength > firstLength ? 'L: ' : 'S: '
				drawAnchoredTextBox(data.handles[5], data.handles[4], `${label}${formatNumber(round(secondLength, 2))} ${unit}`)
			}

			function getLength(start, end) {
				const dx = (end.x - start.x) * columnPixelSpacing
				const dy = (end.y - start.y) * rowPixelSpacing
				return Math.sqrt(dx * dx + dy * dy)
			}
			function getVhsText() {
				if (!data.long || !data.short) return '[Click to add score]'
				const vhs = formatNumber(round(data.long + data.short, 1))
				return `VHS: ${vhs} (${formatNumber(data.long)} + ${formatNumber(data.short)})`
			}
			function drawCenteredTextBox(textBox, linkedLineHandle, text, placeAbove) {
				if (!textBox.hasMoved) {
					textBox.x = linkedLineHandle.x
					textBox.y = linkedLineHandle.y
				}
				const textCoords = pixelToCanvas(element, textBox)
				const verticalOffset = (20 + (linkedLineHandle.radius || 6)) * (placeAbove ? -1 : 1)
				if (!textBox.hasMoved) textCoords.y += verticalOffset
				const options = {
					centering: {
						x: true,
						y: true,
					},
				}
				textBox.boundingBox = drawTextBox(context, [text], textCoords.x, textCoords.y, color, options)
				if (textBox.hasMoved) {
					// Draw dashed link line between tool and text
					drawLink(
						[pixelToCanvas(element, linkedLineHandle)],
						textCoords,
						textBox.boundingBox,
						context,
						color,
						toolStyle.getToolWidth()
					)
				}
			}
			function drawAnchoredTextBox(textBox, linkedLineHandle, text) {
				const color = toolColors.getColorIfActive(data)
				let textBoxOffset = 0
				if (!textBox.hasMoved) {
					textBoxOffset = linkedLineHandle.radius + 6
					textBox.x = linkedLineHandle.x
					textBox.y = linkedLineHandle.y
				}
				drawLinkedTextBox(
					context,
					element,
					textBox,
					[text],
					[linkedLineHandle],
					() => [linkedLineHandle],
					color,
					toolStyle.getToolWidth(),
					textBoxOffset,
					false
				)
			}
		})
	}

	_addHeartLine(eventData, data, isAddingSecondLine, interactionType) {
		const doneMovingCallback = () => {
			data.invalidated = true

			if (isAddingSecondLine) {
				if (isIntersecting()) snapToPerpendicular()
				if (!isIntersecting()) data.handles = data.handles.slice(0, 3) // remove badly placed second line
			}
			updateImage(this.element)
		}

		// Move our new handle :+1:
		moveNewHandle(
			eventData,
			this.name,
			data,
			data.handles[data.handles.length - 2],
			this.options,
			interactionType,
			doneMovingCallback
		)

		function isIntersecting() {
			const lineA = [data.handles[0], data.handles[1]]
			const lineB = [data.handles[3], data.handles[4]]
			const det =
				(lineA[1].x - lineA[0].x) * (lineB[1].y - lineB[0].y) - (lineB[1].x - lineB[0].x) * (lineA[1].y - lineA[0].y)
			if (det === 0) return false
			const lambda =
				((lineB[1].y - lineB[0].y) * (lineB[1].x - lineA[0].x) +
					(lineB[0].x - lineB[1].x) * (lineB[1].y - lineA[0].y)) /
				det
			const gamma =
				((lineA[0].y - lineA[1].y) * (lineB[1].x - lineA[0].x) +
					(lineA[1].x - lineA[0].x) * (lineB[1].y - lineA[0].y)) /
				det
			return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1
		}
		function snapToPerpendicular() {
			const crossPoint = _getCrossPoint(data.handles)
			const slope = (crossPoint.y - data.handles[3].y) / (crossPoint.x - data.handles[3].x)
			const isHorizontal = Math.abs(slope) < 1
			const padding = 6
			if (isHorizontal) {
				snapToX()
				if (!isWithinBounds()) {
					if (data.handles[4].y < padding) data.handles[4].y = padding
					if (data.handles[4].y > eventData.image.height - padding) data.handles[4].y = eventData.image.height - padding
					snapToY()
				}
			} else {
				snapToY()
				if (!isWithinBounds()) {
					if (data.handles[4].x < padding) data.handles[4].x = padding
					if (data.handles[4].x > eventData.image.width - padding) data.handles[4].x = eventData.image.width - padding
					snapToX()
				}
			}
			if (!isWithinBounds()) data.handles = data.handles.slice(0, 3)

			function snapToX() {
				data.handles[4].y = slope * (data.handles[4].x - crossPoint.x) + crossPoint.y
			}
			function snapToY() {
				data.handles[4].x = (data.handles[4].y - crossPoint.y) / slope + crossPoint.x
			}
			function isWithinBounds() {
				const yInBounds = data.handles[4].y >= padding && data.handles[4].y <= eventData.image.height - padding
				const xInBounds = data.handles[4].x >= padding && data.handles[4].x <= eventData.image.width - padding
				return yInBounds && xInBounds
			}
		}
	}

	_addVertEdge(eventData, data) {
		const getDistance = (a, b) => Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2))
		const heartLengths = [getDistance(data.handles[0], data.handles[1]), getDistance(data.handles[3], data.handles[4])]
		const defaultHeight = Math.max(...heartLengths) / 2
		const topHandle = {
			x: eventData.currentPoints.image.x,
			y: eventData.currentPoints.image.y,
			highlight: true,
			active: true,
			movesIndependently: true,
		}
		const bottomHandle = {
			x: eventData.currentPoints.image.x,
			y: eventData.currentPoints.image.y + defaultHeight,
			highlight: true,
			active: false,
			movesIndependently: true,
		}
		const textBox = {
			active: false,
			hasMoved: false,
			movesIndependently: true,
			drawnIndependently: true,
			allowedOutsideImage: true,
			hasBoundingBox: true,
		}
		data.handles.push(bottomHandle, topHandle, Object.assign({}, textBox), Object.assign({}, textBox))
		data.invalidated = true
		updateImage(this.element)
		triggerDoneModifying(this.name, this.element, eventData.image)
	}

	/**
	 * Clears the tool's state if it is not a completed annotation. Usually
	 * fired when exiting the tool's `active` mode.
	 *
	 * @memberof AstVertebralHeart
	 */
	_clearIncompleteToolData(element, options) {
		const toolData = getToolState(this.element, this.name)
		const isToolInstance = toolData && toolData.data && toolData.data.length
		const isIncompleteDrawing = isToolInstance && toolData.data[0].handles.length < 10
		if (isIncompleteDrawing) {
			clearToolState(this.element, this.name)
			updateImage(this.element)
		}
	}
}

/**
 *
 *
 * @param {*} data
 * @returns
 */
async function _promptForVertebrae(eventData, data) {
	/* eslint-disable no-alert */
	this.isDragging = false
	const lengths = _getVertLines(data.handles).map(line => point.distance(line.start, line.end))
	const [longLength, shortLength] = lengths
	const longToShortRatio = longLength / shortLength
	const long = parseNumber(
		await openPromptDlg({
			title: 'Vertabrae',
			prompt: 'Enter the vertebrae count for the long axis:',
			defaultValue: (data.long && formatNumber(data.long)) || '',
		})
	)
	if (!long) return
	const calculatedShortCount = round(long * longToShortRatio, 1)
	const short = parseNumber(
		await openPromptDlg({
			title: 'Vertabrae',
			prompt: 'Enter the vertebrae count for the short axis:',
			defaultValue: formatNumber(data.short || calculatedShortCount),
		})
	)
	if (!short) return
	data.long = long
	data.short = short
	updateImage(this.element)
	triggerDoneModifying(this.name, this.element, eventData.image)
}

/**
 *
 *
 * @param {*} data
 * @param {*} indeces
 */
function _makeHandlesMoveAsGroup(data, indeces) {
	data.handles = data.handles.map((handle, i) => {
		handle.movesIndependently = !indeces.includes(i)
		return handle
	})
}

/**
 *
 *
 * @param {*} eventData
 * @param {*} toolData
 * @param {HTMLElement} element
 * @param {string} toolName - The tool's name
 */
function _handleDoneMove(eventData, toolData, element, toolName) {
	const shapeHandles = {
		heart1: [0, 1],
		heart2: [3, 4],
		vert: [6, 7],
	}
	_makeHandlesMoveAsGroup(toolData, shapeHandles.heart1.concat(shapeHandles.heart2)) // reset heart back to move as group for rotation
	toolData.active = false
	toolData.invalidated = true
	store.state.isToolLocked = false
	updateImage(element)

	triggerDoneModifying(toolName, element, eventData.image)
}

/**
 * Gets points for vertebrae lines (for both drawing and pointNearTool)
 *
 * @private @function
 * @param {*} handles
 * @returns {Array} {start, end} Sorted array of vertebrae lines
 */
const _getVertLines = function(handles) {
	const lengths = [point.distance(handles[0], handles[1]), point.distance(handles[3], handles[4])]
	return lengths.sort((a, b) => a - b).map((length, i) => getVertLine(handles, length, i))

	function getVertLine(handles, length, index) {
		const position = index === 0 ? 0.4 : 0.6
		const edgeDeltaX = handles[7].x - handles[6].x
		const edgeDeltaY = handles[7].y - handles[6].y
		if (edgeDeltaY > 0) length *= -1 // allow lines to be rotated to left side
		const start = {
			x: handles[6].x + edgeDeltaX * position,
			y: handles[6].y + edgeDeltaY * position,
		}
		const slope = edgeDeltaX / edgeDeltaY
		const end = {
			x: start.x + length * Math.sqrt(1 / (1 + Math.pow(slope, 2))),
			y: start.y - length * slope * Math.sqrt(1 / (1 + Math.pow(slope, 2))),
		}
		return {
			start,
			end,
		}
	}
}

/**
 * Gets points where heart lines intersect (for snapping last heart handle)
 *
 * @private @function
 * @param {*} handles
 * @returns {Object} {x, y}
 */
const _getCrossPoint = function(handles) {
	const lineDeltaY = handles[1].y - handles[0].y
	const lineDeltaX = handles[1].x - handles[0].x
	const originDeltaX = handles[3].x - handles[0].x
	const originDeltaY = handles[3].y - handles[0].y
	const k =
		(lineDeltaY * originDeltaX - lineDeltaX * originDeltaY) / (lineDeltaY * lineDeltaY + lineDeltaX * lineDeltaX)
	return {
		x: handles[3].x - k * lineDeltaY,
		y: handles[3].y + k * lineDeltaX,
	}
}
