/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { updateImage, pageToPixel } from 'cornerstone-core/dist/cornerstone.js'
import { import as csTools, EVENTS } from 'cornerstone-tools/dist/cornerstoneTools.js'
import {
	initHandle,
	setPrecisionMode,
	disablePrecisionForInactiveHandles,
} from '@/cornerstone/tools/util/handles'

const triggerEvent = csTools('util/triggerEvent')

const MOUSE = true
const TOUCH = false
export const moveAndRotateHandlesMouse = moveAndRotateHandles(MOUSE)
export const moveAndRotateHandlesTouch = moveAndRotateHandles(TOUCH)

// Move handles and rotate other movable handles relative to opposite handle
function moveAndRotateHandles(isMouse) {
	const dragEvents = isMouse ? ['MOUSE_DRAG'] : ['TOUCH_DRAG']
	const upEvents = isMouse
		? ['MOUSE_UP', 'MOUSE_CLICK']
		: ['TOUCH_END', 'TOUCH_DRAG_END', 'TOUCH_PINCH', 'TOUCH_PRESS', 'TAP']

	return function(eventData, toolType, data, handle, doneMovingCallback) {
		if (!isMouse) eventData = eventData.detail
		handle.active = true
		const element = eventData.element
		const coords = isMouse
			? eventData.currentPoints.image
			: pageToPixel(element, eventData.currentPoints.page.x, eventData.currentPoints.page.y)
		const interactionType = isMouse ? 'mouse' : 'touch'
		initHandle(handle, coords, interactionType, { precisionHandlesEnabled: true })
		disablePrecisionForInactiveHandles(element)

		// Store each handle's starting position for relative angle calculation
		data.handles = data.handles.map(handle => {
			handle.startingPoint = {
				x: handle.x,
				y: handle.y,
			}
			return handle
		})

		dragEvents.forEach(event => {
			element.addEventListener(EVENTS[event], dragCallback)
		})
		upEvents.forEach(event => {
			element.addEventListener(EVENTS[event], upCallback)
		})

		function dragCallback({ detail }) {
			const coords = isMouse
				? detail.currentPoints.image
				: pageToPixel(element, detail.currentPoints.page.x, detail.currentPoints.page.y)
			if (handle.hasMoved === false) handle.hasMoved = true
			handle.active = true
			const drag = handle.drag
			if (drag) {
				handle.x = drag.originX + coords.x - drag.originDragX
				handle.y = drag.originY + coords.y - drag.originDragY
			} else {
				handle.x = coords.x
				handle.y = coords.y
			}

			if (!handle.movesIndependently) {
				const oppositeHandleIndeces = {
					0: 1,
					1: 0,
					3: 4,
					4: 3,
				}
				const handleIndex = data.handles.indexOf(handle)
				const oppositeHandleIndex = oppositeHandleIndeces[handleIndex]
				const oppositeHandle = data.handles[oppositeHandleIndex]
				const angleDelta =
					_getRelativeAngle(oppositeHandle, handle) -
					_getRelativeAngle(oppositeHandle, handle.startingPoint)
				data.handles.forEach((otherHandle, i) => {
					if (i === handleIndex || i === oppositeHandleIndex) return
					if (otherHandle.movesIndependently) return
					const newLocation = _rotatePoint(
						otherHandle.startingPoint,
						oppositeHandle,
						angleDelta || 0
					)
					otherHandle = Object.assign(otherHandle, newLocation)
				})
			}
			updateImage(element)
			const eventType = EVENTS.MEASUREMENT_MODIFIED
			const modifiedEventData = {
				toolType,
				element,
				measurementData: data,
			}
			triggerEvent(element, eventType, modifiedEventData)
		}

		function upCallback(evt) {
			const { element, currentPoints } = evt.detail
			const coords = currentPoints.canvas
			const nearHandle = handle.pointNearHandle && handle.pointNearHandle(element, handle, coords)
			if (nearHandle && [EVENTS.MOUSE_CLICK, EVENTS.TOUCH_END].includes(evt.type)) {
				// Toggle precision mode for handle on click or tap
				setPrecisionMode(handle, nearHandle && !handle.precision)
			}

			handle.active = false

			dragEvents.forEach(event => {
				element.removeEventListener(EVENTS[event], dragCallback)
			})
			upEvents.forEach(event => {
				element.removeEventListener(EVENTS[event], upCallback)
			})
			updateImage(element)
			if (typeof doneMovingCallback === 'function') {
				doneMovingCallback()
			}
		}
	}
}

/**
 *
 * @private @function
 * @param {*} center
 * @param {*} endpoint
 * @returns {Number} angle
 */
const _getRelativeAngle = function(center, endpoint) {
	const dy = endpoint.y - center.y
	const dx = endpoint.x - center.x
	return Math.atan2(dy, dx)
}

/**
 *
 * @private @function
 * @param {*} point
 * @param {*} origin
 * @param {*} angle
 * @returns {Object} {x, y}
 */
const _rotatePoint = function(point, origin, angle) {
	return {
		x: Math.cos(angle) * (point.x - origin.x) - Math.sin(angle) * (point.y - origin.y) + origin.x,
		y: Math.sin(angle) * (point.x - origin.x) + Math.cos(angle) * (point.y - origin.y) + origin.y,
	}
}
