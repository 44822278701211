import {
	getEnabledElement,
	metaData,
	pixelToCanvas,
	updateImage,
	getStoredPixels,
} from 'cornerstone-core/dist/cornerstone.js'
import convertToVector3 from '@/utils/convertToVector3'
import {
	addToolState,
	clearToolState,
	getToolState,
	import as csTools,
	textStyle,
	toolColors,
	store,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import isLocalizerImage from '../_shared/isLocalizerImage'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'

// Base
const BaseTool = csTools('base/BaseTool')

// Drawing
const drawLine = csTools('drawing/drawLine')
const getNewContext = csTools('drawing/getNewContext')
const draw = csTools('drawing/draw')
const setShadow = csTools('drawing/setShadow')
const drawTextBox = csTools('drawing/drawTextBox')

// Util
const imagePointToPatientPoint = csTools('util/imagePointToPatientPoint')
const projectPatientPointToImagePlane = csTools('util/projectPatientPointToImagePlane')

/**
 * @public
 * @class AstProbe
 * @memberof Tools
 *
 * @classdesc Tool which provides a probe of the image data at the
 * input position on drag.
 * @extends Tools.Base.BaseTool
 */
export default class AstProbe extends BaseTool {
	constructor(props = {}) {
		const defaultProps = {
			name: 'AstProbe',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			svgCursor: null,
			options: {
				preventHandleOutsideImage: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
			},
		}
		super(props, defaultProps)
		this.mergeOptions(defaultProps.options)

		this.postTouchStartCallback = this._movingEventCallback.bind(this)
		this.touchDragCallback = this._movingEventCallback.bind(this)
		this.touchEndCallback = this._endMovingEventCallback.bind(this)

		this.postMouseDownCallback = this._movingEventCallback.bind(this)
		this.mouseDragCallback = this._movingEventCallback.bind(this)
		this.mouseUpCallback = this._endMovingEventCallback.bind(this)

		this.mouseClickCallback = this._endMovingEventCallback.bind(this)

		this.updatePoint = _updatePoint.bind(this)
		this.syncedId = null

		// Used to ensure the actively clicked canvas doesn't render a crosshair
		this.activeInstance = false
	}

	_movingEventCallback(evt) {
		this.updatePoint(evt)
		this.activeInstance = true
	}

	_endMovingEventCallback() {
		this.activeInstance = false
		this._clearAllPoints()
	}

	renderToolData(evt) {
		if (evt && evt.detail) {
			const eventData = evt.detail
			const element = eventData.element
			const toolData = getToolState(evt.currentTarget, this.name)
			if (!toolData || !toolData.data || !toolData.data.length) return
			const context = getNewContext(eventData.canvasContext.canvas)
			toolData.data.forEach(data => {
				if (data.visible === false) return
				if (!data.point) return
				if (
					data.point.x < 0 ||
					data.point.y < 0 ||
					data.point.x >= evt.detail.image.columns ||
					data.point.y >= evt.detail.image.rows
				) {
					return
				}
				drawProbe.bind(this)(data.point, evt.detail.image, context, element)
				if (!this.activeInstance) _drawCrosshairs(data.point, context, element)
			})
		}
	}
	_clearAllPoints() {
		store.state.enabledElements.forEach(enabledElement => {
			clearToolState(enabledElement, this.name)
			updateImage(enabledElement)
		})
	}
}

/**
 * Minimal strategy will use the mouse position to get the HU value and display probe data.
 *
 * @param  {Object} evt Image rendered event
 * @returns {void}
 */
function drawProbe(toolCoords, image, context, element) {
	const config = this.configuration
	const color = toolColors.getToolColor()

	draw(context, context => {
		setShadow(context, config)
		let storedPixels

		// Assume modality is CT or we wouldn't be able to get to this point
		storedPixels = getStoredPixels(element, toolCoords.x, toolCoords.y, 1, 1)
		const sp = storedPixels[0]
		const mo = sp * image.slope + image.intercept
		const text = `${formatNumber(round(mo, 2))} HU`

		// Prepare text
		const textCoords = pixelToCanvas(element, toolCoords)

		// Translate the x/y away from the cursor
		textCoords.x += 12
		textCoords.y += -18 - (textStyle.getFontSize() + 10) / 2
		drawTextBox(context, text, textCoords.x, textCoords.y, color)
	})
}

/**
 * @param {*} data
 * @param {*} context
 * @param {*} element
 */
function _drawCrosshairs(pixelPoint, context, element) {
	const color = toolColors.getActiveColor()
	const point = pixelToCanvas(element, pixelPoint)
	const distance = 15
	const top = {
		x: point.x,
		y: point.y - distance,
	}
	const right = {
		x: point.x + distance,
		y: point.y,
	}
	const bottom = {
		x: point.x,
		y: point.y + distance,
	}
	const left = {
		x: point.x - distance,
		y: point.y,
	}
	drawLine(context, element, top, bottom, { color }, 'canvas')
	drawLine(context, element, left, right, { color }, 'canvas')
}

// Very similar to AstCrossPoint's tool
const _updatePoint = function(evt) {
	const eventData = evt.detail
	evt.stopImmediatePropagation()
	const sourceElement = evt.currentTarget
	const sourceEnabledElement = getEnabledElement(sourceElement)
	const sourceImageId = sourceEnabledElement.image.imageId
	const sourceImagePlane = metaData.get('imagePlaneModule', sourceImageId)
	if (
		!sourceImagePlane ||
		!sourceImagePlane.rowCosines ||
		!sourceImagePlane.columnCosines ||
		!sourceImagePlane.imagePositionPatient ||
		!sourceImagePlane.frameOfReferenceUID
	)
		return

	const sourceImagePoint = eventData.currentPoints.image
	const sourcePatientPoint = imagePointToPatientPoint(sourceImagePoint, sourceImagePlane)

	store.state.enabledElements.forEach(async targetElement => {
		const stackToolDataSource = getToolState(targetElement, 'stack')
		const isMissingStackData = !stackToolDataSource || !stackToolDataSource.data || !stackToolDataSource.data.length
		if (isMissingStackData) return
		const seriesStack = stackToolDataSource.data[0]

		// Clear
		clearToolState(targetElement, this.name)

		// Update
		const bestImageIdIndex = _findBestImageIdIndex(
			seriesStack,
			sourcePatientPoint,
			sourceImagePlane.frameOfReferenceUID,
			sourceImageId
		)
		// Only show where the "best" ImageID is the currently shown ID. Ignores out of sync images.
		if (bestImageIdIndex !== null && seriesStack.currentImageIdIndex === bestImageIdIndex) {
			const imageId = seriesStack.imageIds[bestImageIdIndex]
			const targetTool = store.state.tools.find(tool => tool.element === targetElement && tool.name === this.name)

			if (targetTool) {
				targetTool.syncedId = imageId
			}
			// New ToolState w/ bestImageId
			const targetMeta = metaData.get('imagePlaneModule', imageId)
			if (!targetMeta || !targetMeta.rowCosines || !targetMeta.columnCosines || !targetMeta.imagePositionPatient) return

			const crossPoint = projectPatientPointToImagePlane(sourcePatientPoint, targetMeta)
			const toolData = getToolState(targetElement, this.name)
			if (!toolData || !toolData.data || !toolData.data.length) {
				addToolState(targetElement, this.name, {
					point: crossPoint,
				})
			} else {
				toolData.data[0].point = crossPoint
			}
		}

		// Force redraw
		updateImage(targetElement)
	})
}

const _findBestImageIdIndex = function(seriesStack, sourcePatientPoint, sourceFrameOfReference, sourceImageId) {
	let newImageIdIndex = null
	let minDistance = Number.MAX_VALUE
	for (let index = 0; index < seriesStack.imageIds.length; index++) {
		const imageId = seriesStack.imageIds[index]
		// if this is the source image, use this index
		if (imageId === sourceImageId) {
			newImageIdIndex = index
			break
		}
		const targetMeta = metaData.get('imagePlaneModule', imageId)
		if (
			!targetMeta ||
			!targetMeta.imagePositionPatient ||
			!targetMeta.rowCosines ||
			!targetMeta.columnCosines ||
			!targetMeta.sliceThickness
		)
			continue
		// Verify image is from same study as source image
		if (targetMeta.frameOfReferenceUID !== sourceFrameOfReference) continue
		// Verify image is not a localizer image [ch4638]
		if (isLocalizerImage(seriesStack.imageIds, imageId, targetMeta)) continue
		const imagePosition = convertToVector3(targetMeta.imagePositionPatient)
		const row = convertToVector3(targetMeta.rowCosines)
		const column = convertToVector3(targetMeta.columnCosines)
		// A vector that is perpendicular to both `column` and `row` and thus 'normal'
		const normal = column.clone().cross(row.clone())
		// Distance from image's plane to normal's origin
		const targetPlanePosition = normal.clone().dot(imagePosition)
		// Distance from a same-oriented plane containing the source point to normal's origin
		const sourcePointPlanePosition = normal.clone().dot(sourcePatientPoint)
		// Distance between derived target and source planes
		const distance = Math.abs(targetPlanePosition - sourcePointPlanePosition)
		const tolerance = targetMeta.sliceThickness * 2
		// Do not scroll to an image that is more than double the slice thickness away
		if (distance > tolerance) continue
		if (distance < minDistance) {
			minDistance = distance
			newImageIdIndex = index
		}
	}

	return newImageIdIndex
}
