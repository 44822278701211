import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import {
	initHandle,
	setPrecisionMode,
	handleLengthsAreValid,
	disablePrecisionForInactiveHandles,
} from '@/cornerstone/tools/util/handles'
import triggerDoneModifying from '../tools/util/triggerDoneModifying'

const { EVENTS, external, removeToolState, import: csTools, store } = cornerstoneTools
const anyHandlesOutsideImage = csTools('manipulators/anyHandlesOutsideImage')
const clipToBox = csTools('util/clipToBox')
const triggerEvent = csTools('util/triggerEvent')
const state = store.state

const _dragEvents = {
	mouse: [EVENTS.MOUSE_DRAG],
	touch: [EVENTS.TOUCH_DRAG],
}

const _upOrEndEvents = {
	mouse: [EVENTS.MOUSE_UP, EVENTS.MOUSE_CLICK],
	touch: [EVENTS.TOUCH_END, EVENTS.TOUCH_DRAG_END, EVENTS.TOUCH_PINCH, EVENTS.TOUCH_PRESS, EVENTS.TAP],
}

/**
 * Move the provided handle
 *
 * @public
 * @method moveHandle
 * @memberof Manipulators
 *
 * @param {*} evtDetail
 * @param {*} toolName
 * @param {*} annotation
 * @param {*} handle
 * @param {*} [options={}]
 * @param {Boolean}  [options.deleteIfHandleOutsideImage]
 * @param {Boolean}  [options.preventHandleOutsideImage]
 * @param {*} [interactionType=mouse]
 * @param {function} [doneMovingCallback]
 * @returns {undefined}
 */
export default function(
	evtDetail,
	toolName,
	annotation,
	handle,
	options = {},
	interactionType = 'mouse',
	doneMovingCallback
) {
	// Use global defaults, unless overidden by provided options
	options = Object.assign(
		{
			deleteIfHandleOutsideImage: state.deleteIfHandleOutsideImage,
			preventHandleOutsideImage: state.preventHandleOutsideImage,
		},
		options
	)

	const dragHandler = _dragHandler.bind(this, toolName, annotation, handle, options, interactionType)
	// So we don't need to inline the entire `upOrEndHandler` function
	const upOrEndHandler = evt => {
		_upOrEndHandler(
			toolName,
			evtDetail,
			annotation,
			handle,
			options,
			interactionType,
			{
				dragHandler,
				upOrEndHandler,
			},
			evt,
			doneMovingCallback
		)
	}

	handle.active = true
	annotation.active = true
	state.isToolLocked = true

	const { element, currentPoints } = evtDetail
	const coords = currentPoints.image
	initHandle(handle, coords, interactionType, options)
	disablePrecisionForInactiveHandles(element)

	// Add Event Listeners
	_dragEvents[interactionType].forEach(eventType => {
		element.addEventListener(eventType, dragHandler)
	})
	_upOrEndEvents[interactionType].forEach(eventType => {
		element.addEventListener(eventType, upOrEndHandler)
	})
}

function _dragHandler(toolName, annotation, handle, options, interactionType, evt) {
	const { image, currentPoints, element } = evt.detail
	const page = currentPoints.page
	const targetLocation = external.cornerstone.pageToPixel(element, page.x, page.y)

	handle.active = true
	handle.hasMoved = true

	const drag = handle.drag
	if (drag) {
		handle.x = drag.originX + currentPoints.image.x - drag.originDragX
		handle.y = drag.originY + currentPoints.image.y - drag.originDragY
		if (options.constrainSquare) {
			let oppositeHandle
			if (Math.abs(annotation.handles.start.x - handle.x) > Math.abs(annotation.handles.end.x - handle.x)) {
				oppositeHandle = annotation.handles.start
			} else {
				oppositeHandle = annotation.handles.end
			}
			const distanceFromOppositeHandle = {
				x: handle.x - oppositeHandle.x,
				y: handle.y - oppositeHandle.y,
			}
			const isDifferentDirection =
				(distanceFromOppositeHandle.x < 0 && distanceFromOppositeHandle.y > 0) ||
				(distanceFromOppositeHandle.x > 0 && distanceFromOppositeHandle.y < 0)
			if (Math.abs(distanceFromOppositeHandle.x) > Math.abs(distanceFromOppositeHandle.y)) {
				handle.y = isDifferentDirection
					? oppositeHandle.y - distanceFromOppositeHandle.x
					: oppositeHandle.y + distanceFromOppositeHandle.x
			} else {
				handle.x = isDifferentDirection
					? oppositeHandle.x - distanceFromOppositeHandle.y
					: oppositeHandle.x + distanceFromOppositeHandle.y
			}
		}
	} else {
		handle.x = targetLocation.x
		handle.y = targetLocation.y
	}

	// TODO: A way to not flip this for textboxes on annotations
	annotation.invalidated = true

	if (options.preventHandleOutsideImage) {
		clipToBox(handle, image)
	}

	external.cornerstone.updateImage(element)

	const eventType = EVENTS.MEASUREMENT_MODIFIED
	const modifiedEventData = {
		toolName,
		element,
		measurementData: annotation,
	}

	triggerEvent(element, eventType, modifiedEventData)
}

function _upOrEndHandler(
	toolName,
	evtDetail,
	annotation,
	handle,
	options = {},
	interactionType,
	{ dragHandler, upOrEndHandler },
	evt,
	doneMovingCallback
) {
	const image = evtDetail.currentPoints.image
	const { element, currentPoints } = evt.detail
	const coords = currentPoints.canvas
	const nearHandle = handle.pointNearHandle && handle.pointNearHandle(element, handle, coords)
	if (nearHandle && (evt.type === EVENTS.MOUSE_CLICK || evt.type === EVENTS.TOUCH_END)) {
		// Toggle precision mode for handle on click or tap
		setPrecisionMode(handle, !handle.precision)
	}

	handle.active = false
	annotation.active = false

	// TODO: A way to not flip this for textboxes on annotations
	annotation.invalidated = true
	state.isToolLocked = false

	// Remove Event Listeners
	_dragEvents[interactionType].forEach(eventType => {
		element.removeEventListener(eventType, dragHandler)
	})
	_upOrEndEvents[interactionType].forEach(eventType => {
		element.removeEventListener(eventType, upOrEndHandler)
	})

	// Delete the tool data if any handle is outside the image
	if (options.deleteIfHandleOutsideImage && anyHandlesOutsideImage(evtDetail, annotation.handles)) {
		removeToolState(element, toolName, annotation)
	}

	// Delete the tool data if handle lengths are invalid
	if (!handleLengthsAreValid(annotation.handles)) {
		removeToolState(element, toolName, annotation)
	}

	// TODO: What dark magic makes us want to handle TOUCH_PRESS differently?
	if (evt.type === EVENTS.TOUCH_PRESS) {
		evt.detail.handlePressed = annotation
		handle.x = image.x // Original Event
		handle.y = image.y
	}

	external.cornerstone.updateImage(element)
	triggerDoneModifying(toolName, element, evt.detail.image)

	if (typeof doneMovingCallback === 'function') {
		doneMovingCallback()
	}
}
