import {
	RectangleRoiTool,
	external,
	addToolState,
	import as csTools,
	getToolState,
	toolStyle,
	getModule,
	toolColors,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

// Manipulators
import moveHandle from '@/cornerstone/manipulators/moveHandle'
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import toolSelectedCallback from './_shared/toolSelectedCallback.js'

// Handle helpers
import { initHandle } from './util/handles'
import getImagePixelSpacing from './util/getImagePixelSpacing'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'

const getNewContext = csTools('drawing/getNewContext')
const draw = csTools('drawing/draw')
const setShadow = csTools('drawing/setShadow')
const drawRect = csTools('drawing/drawRect')
const drawHandles = csTools('drawing/drawHandles')
const getROITextBoxCoords = csTools('util/getROITextBoxCoords')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const calculateSUV = csTools('util/calculateSUV')

export default class AstRectangleRoi extends RectangleRoiTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstRectangleRoi',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandles: true,
				showHounsfieldUnits: true,
				showMinMax: true,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)
		super(initialConfiguration)
		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	addNewMeasurement(evt, interactionType) {
		evt.preventDefault()
		evt.stopPropagation()
		const eventData = evt.detail
		const element = eventData.element
		const measurementData = this.createNewMeasurement(eventData)

		if (!measurementData) {
			return
		}

		// Associate this data with this imageId so we can render it and manipulate it
		addToolState(element, this.name, measurementData)

		external.cornerstone.updateImage(element)

		initHandle(measurementData.handles.start, null, interactionType, this.options)
		moveNewHandle(eventData, this.name, measurementData, measurementData.handles.end, this.options, interactionType)
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}
	renderToolData(evt) {
		const toolData = getToolState(evt.currentTarget, this.name)

		if (!toolData) {
			return
		}

		const eventData = evt.detail
		const { image, element } = eventData
		const lineWidth = toolStyle.getToolWidth()
		const lineDash = getModule('globalConfiguration').configuration.lineDash
		const { handleRadius, drawHandlesOnHover, renderDashed } = this.configuration
		const context = getNewContext(eventData.canvasContext.canvas)
		const { rowPixelSpacing, columnPixelSpacing, isPixelSpacingDefined } = getImagePixelSpacing(image)

		// Meta
		const seriesModule = external.cornerstone.metaData.get('series', image.imageId) || {}

		// Pixel Spacing
		const modality = seriesModule.modality

		draw(context, context => {
			// If we have tool data for this element - iterate over each set and draw it
			for (let i = 0; i < toolData.data.length; i++) {
				const data = toolData.data[i]

				if (data.visible === false) {
					continue
				}

				// Configure
				const color = toolColors.getColorIfActive(data)
				const handleOptions = {
					color,
					handleRadius,
					drawHandlesIfActive: drawHandlesOnHover,
				}

				setShadow(context, this.configuration)

				const rectOptions = { color }

				if (renderDashed) {
					rectOptions.lineDash = lineDash
				}

				// Draw
				drawRect(
					context,
					element,
					data.handles.start,
					data.handles.end,
					rectOptions,
					'pixel',
					data.handles.initialRotation
				)

				if (this.configuration.drawHandles) {
					drawHandles(context, eventData, data.handles, handleOptions)
				}

				// Update textbox stats
				if (data.invalidated === true) {
					if (data.cachedStats) {
						this.throttledUpdateCachedStats(image, element, data)
					} else {
						this.updateCachedStats(image, element, data)
					}
				}

				// Default to textbox on right side of ROI
				if (!data.handles.textBox.hasMoved) {
					const defaultCoords = getROITextBoxCoords(eventData.viewport, data.handles)

					Object.assign(data.handles.textBox, defaultCoords)
				}

				const textBoxAnchorPoints = handles => _findTextBoxAnchorPoints(handles.start, handles.end)
				const textBoxContent = _createTextBoxContent(
					context,
					image.color,
					data.cachedStats,
					modality,
					isPixelSpacingDefined,
					this.configuration
				)
				data.handles.textBox.text = textBoxContent

				data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits)

				if (!data.handles.textBox.hidden) {
					drawLinkedTextBox(
						context,
						element,
						data.handles.textBox,
						textBoxContent,
						data.handles,
						textBoxAnchorPoints,
						color,
						lineWidth,
						10,
						true
					)
				}
			}
		})
	}
}

/**
 * TODO: This is the same method (+ GetPixels) for the other ROIs
 * TODO: The pixel filtering is the unique bit
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {{ left: number, top: number, width: number, height: number}}
 */
function _getRectangleImageCoordinates(startHandle, endHandle) {
	return {
		left: Math.min(startHandle.x, endHandle.x),
		top: Math.min(startHandle.y, endHandle.y),
		width: Math.abs(startHandle.x - endHandle.x),
		height: Math.abs(startHandle.y - endHandle.y),
	}
}

/**
 *
 *
 * @param {*} image
 * @param {*} element
 * @param {*} handles
 * @param {*} modality
 * @param {*} pixelSpacing
 * @returns {Object} The Stats object
 */
function _calculateStats(image, element, handles, modality, pixelSpacing) {
	// Retrieve the bounds of the rectangle in image coordinates
	const roiCoordinates = _getRectangleImageCoordinates(handles.start, handles.end)

	// Retrieve the array of pixels that the rectangle bounds cover
	const pixels = external.cornerstone.getPixels(
		element,
		roiCoordinates.left,
		roiCoordinates.top,
		roiCoordinates.width,
		roiCoordinates.height
	)

	// Calculate the mean & standard deviation from the pixels and the rectangle details
	const roiMeanStdDev = _calculateRectangleStats(pixels, roiCoordinates)

	let meanStdDevSUV

	if (modality === 'PT') {
		meanStdDevSUV = {
			mean: calculateSUV(image, roiMeanStdDev.mean, true) || 0,
			stdDev: calculateSUV(image, roiMeanStdDev.stdDev, true) || 0,
		}
	}

	// Calculate the image area from the rectangle dimensions and pixel spacing
	const area =
		roiCoordinates.width *
		(pixelSpacing.colPixelSpacing || 1) *
		(roiCoordinates.height * (pixelSpacing.rowPixelSpacing || 1))

	return {
		area: area || 0,
		count: roiMeanStdDev.count || 0,
		mean: roiMeanStdDev.mean || 0,
		variance: roiMeanStdDev.variance || 0,
		stdDev: roiMeanStdDev.stdDev || 0,
		min: roiMeanStdDev.min || 0,
		max: roiMeanStdDev.max || 0,
		meanStdDevSUV,
	}
}

/**
 *
 *
 * @param {*} sp
 * @param {*} rectangle
 * @returns {{ count, number, mean: number,  variance: number,  stdDev: number,  min: number,  max: number }}
 */
function _calculateRectangleStats(sp, rectangle) {
	let sum = 0
	let sumSquared = 0
	let count = 0
	let index = 0
	let min = sp ? sp[0] : null
	let max = sp ? sp[0] : null

	for (let y = rectangle.top; y < rectangle.top + rectangle.height; y++) {
		for (let x = rectangle.left; x < rectangle.left + rectangle.width; x++) {
			sum += sp[index]
			sumSquared += sp[index] * sp[index]
			min = Math.min(min, sp[index])
			max = Math.max(max, sp[index])
			count++ // TODO: Wouldn't this just be sp.length?
			index++
		}
	}

	if (count === 0) {
		return {
			count,
			mean: 0.0,
			variance: 0.0,
			stdDev: 0.0,
			min: 0.0,
			max: 0.0,
		}
	}

	const mean = sum / count
	const variance = sumSquared / count - mean * mean

	return {
		count,
		mean,
		variance,
		stdDev: Math.sqrt(variance),
		min,
		max,
	}
}

/**
 *
 *
 * @param {*} startHandle
 * @param {*} endHandle
 * @returns {Array.<{x: number, y: number}>}
 */
function _findTextBoxAnchorPoints(startHandle, endHandle) {
	const { left, top, width, height } = _getRectangleImageCoordinates(startHandle, endHandle)

	return [
		{
			// Top middle point of rectangle
			x: left + width / 2,
			y: top,
		},
		{
			// Left middle point of rectangle
			x: left,
			y: top + height / 2,
		},
		{
			// Bottom middle point of rectangle
			x: left + width / 2,
			y: top + height,
		},
		{
			// Right middle point of rectangle
			x: left + width,
			y: top + height / 2,
		},
	]
}

/**
 *
 *
 * @param {*} area
 * @param {*} hasPixelSpacing
 * @returns {string} The formatted label for showing area
 */
function _formatArea(area, hasPixelSpacing) {
	// This uses Char code 178 for a superscript 2
	const suffix = hasPixelSpacing ? ` mm${String.fromCharCode(178)}` : ` px${String.fromCharCode(178)}`

	return `Area: ${formatNumber(round(area, 2))}${suffix}`
}

function _getUnit(modality, showHounsfieldUnits) {
	return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : ''
}

/**
 * TODO: This is identical to EllipticalROI's same fn
 * TODO: We may want to make this a utility for ROIs with these values?
 *
 * @param {*} context
 * @param {*} isColorImage
 * @param {*} { area, mean, stdDev, min, max, meanStdDevSUV }
 * @param {*} modality
 * @param {*} hasPixelSpacing
 * @param {*} [options={}]
 * @returns {string[]}
 */
function _createTextBoxContent(
	context,
	isColorImage,
	{ area, mean, stdDev, min, max, meanStdDevSUV } = {},
	modality,
	hasPixelSpacing,
	options = {}
) {
	const showMinMax = options.showMinMax || false
	const textLines = []

	const otherLines = []

	const showStats = !isColorImage && ['PT', 'CT'].includes(modality)

	if (showStats) {
		const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0
		const unit = _getUnit(modality, options.showHounsfieldUnits)

		let meanString = `Mean: ${formatNumber(round(mean, 2))} ${unit}`
		const stdDevString = `Std Dev: ${formatNumber(round(stdDev, 2))} ${unit}`

		// If this image has SUV values to display, concatenate them to the text line
		if (hasStandardUptakeValues) {
			const SUVtext = ' SUV: '

			const meanSuvString = `${SUVtext}${formatNumber(round(meanStdDevSUV.mean, 2))}`
			const stdDevSuvString = `${SUVtext}${formatNumber(round(meanStdDevSUV.stdDev, 2))}`

			const targetStringLength = Math.floor(context.measureText(`${stdDevString}     `).width)

			while (context.measureText(meanString).width < targetStringLength) {
				meanString += ' '
			}

			otherLines.push(`${meanString}${meanSuvString}`)
			otherLines.push(`${stdDevString}     ${stdDevSuvString}`)
		} else {
			otherLines.push(`${meanString}     ${stdDevString}`)
		}

		if (showMinMax) {
			let minString = `Min: ${formatNumber(min)} ${unit}`
			const maxString = `Max: ${formatNumber(max)} ${unit}`
			const targetStringLength = hasStandardUptakeValues
				? Math.floor(context.measureText(`${stdDevString}     `).width)
				: Math.floor(context.measureText(`${meanString}     `).width)

			while (context.measureText(minString).width < targetStringLength) {
				minString += ' '
			}

			otherLines.push(`${minString}${maxString}`)
		}
	}

	textLines.push(_formatArea(area, hasPixelSpacing))
	otherLines.forEach(x => textLines.push(x))

	return textLines
}
