<template>
	<div>
		<h5>Diameter ({{ unit }})</h5>
		<div style="display: flex; margin-bottom: 24px;">
			<input
				:value="diameterInputValue"
				class="input"
				@mousedown.stop
				@input="updateNewCircleDiameter"
				@keydown.enter="onEnterKeydown"
			/>
			<button class="btn btn-primary" :disabled="!canSetDiameter" @click="setCircleDiameter" @mousedown.stop>
				<svg-icon icon="check" style="opacity: 1;" />
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AstCircleRoiAnnotation',
	props: {
		diameter: {
			type: String,
			default: '',
		},
		event: {
			type: CustomEvent,
			required: true,
		},
		tool: {
			type: Object,
			required: true,
		},
		unit: {
			type: String,
			default: 'mm',
		},
	},
	data() {
		return {
			hasChangedDiameter: false,
			newCircleDiameter: null,
		}
	},
	computed: {
		canSetDiameter() {
			if (!this.hasChangedDiameter) return false
			if (!this.newCircleDiameter) return false
			return true
		},
		diameterInputValue() {
			if (this.hasChangedDiameter) return this.newCircleDiameter
			return this.$options.filters.formatNumber(+this.diameter) || ''
		},
	},
	watch: {
		diameter() {
			this.hasChangedDiameter = false
			this.newCircleDiameter = null
		},
	},
	methods: {
		updateNewCircleDiameter(e) {
			this.newCircleDiameter = e.target.value
			this.hasChangedDiameter = true
		},
		onEnterKeydown() {
			if (this.canSetDiameter) {
				this.setCircleDiameter()
			}
		},
		setCircleDiameter() {
			this.$emit('set-diameter', this.$options.filters.parseNumber(this.newCircleDiameter))
		},
	},
}
</script>
