import introJs from 'intro.js'
import storage from '@services/storage'
import { omniDesktop } from '@/electron/omniDesktop'
import store from '@store'

const flagKey = 'intro-new'

const tryStartTour = async function() {
	// Don't auto-start if cookie or localStorage item is set
	const shouldStartTour = !storage.getItem(flagKey) && (await shouldOpenTour())
	if (shouldStartTour) {
		startTour()
	}
}

function shouldOpenTour() {
	return (
		!omniDesktop.isConnected ||
		!store.state.windows.openStudiesInNewWindow ||
		omniDesktop.request('isFirstPrimaryViewerWindow')
	)
}

export const getIcon = icon =>
	`<i class="icon icon-inline" style="margin:0 2px"><svg width="20" height="20"><use xlink:href="#${icon}" /></svg></i>`

let initialBodyStyle

const startTour = function() {
	const topNavigation = document.querySelector('.top-navigation')
	const bottomNavigation = document.querySelector('.bottom-navigation')
	let navigationElement = bottomNavigation
	if (topNavigation && (!bottomNavigation || !bottomNavigation.clientWidth)) {
		navigationElement = topNavigation
	}

	const intro = introJs()
	intro
		.setOptions({
			exitOnEsc: true,
			exitOnOverlayClick: false,
			showStepNumbers: false,
			showBullets: false,
			showProgress: true,
			scrollToElement: false,
			overlayOpacity: 0.75,
			disableInteraction: false,
			steps: [
				// Welcome
				{
					element: null,
					intro: `
						<h4>Welcome to the Keystone Omni viewer!</h4>
						<p>Select <b>Next</b> for an overview of the viewer's features.</p>
					`,
				},
				// Viewer
				{
					element: document.querySelector('.layout-pane-container'),
					intro: `
						<h4>Image Viewer</h4>
						<p>This pane shows the active image, and allows you to interact using your mouse or touch device.</p>
						<p>Go ahead! Try tapping, clicking, or dragging the image.</p>
					`,
				},
				// Thumbnail navigation
				{
					element: document.querySelector('.navigation-drawer'),
					intro: `
						<h4>Navigation</h4>
						<p>You can jump to a specific image in the study by clicking its thumbnail.</p>
						<p>A green bar at the bottom of a thumbnail indicates the progress as it loads images into memory for fast and easy viewing.</p>
						<p>Click the menu icon ${getIcon('menu')} to show or hide this pane.</p>
					`,
					position: 'right',
				},
				// Series navigation
				{
					element: navigationElement,
					intro: `
						<h4>Navigation - Part 2</h4>
						<p>You can also move through the the series in a study using the ${getIcon('skip-previous')} and ${getIcon(
						'skip-next'
					)} navigation buttons.</p>
					`,
					position: 'top',
				},
				// Viewer tools
				{
					element: document.querySelector('.tools'),
					intro: `
						<h4>Image Tools</h4>
						<p>The <b>toolbar</b> offers quick access to commonly used tools.</p>
						<p><b>Select</b> a tool to assign that tool to whichever mouse button you used to click.</p>
					`,
					position: 'bottom',
				},
				// Settings / Help
				{
					element: document.querySelector('.btn-help'),
					intro: `
						<h4>Farewell Notes</h4>
						<p>Open the Help menu ${getIcon('help-circle')} if you want to see this walkthrough again.</p>
						<p>Open the viewer layout menu ${getIcon(
							'layout'
						)} to change the image layout or access advanced settings and features.</p>
						<p><b>Most importantly...</b> Have fun! Play around, and don't be afraid to ask questions.</p>
					`,
					position: 'bottom',
				},
			],
		})
		.onbeforechange(function() {
			initialBodyStyle = initialBodyStyle || document.body.style // store initial body style
			document.body.style.overflow = 'hidden' // prevent tooltip layer from creating scrollbars
		})
		.onexit(function() {
			document.body.style.overflow = initialBodyStyle // restore initial body style
			storage.setItem(flagKey, true)
		})
	intro.start()
}

export { tryStartTour, startTour }
