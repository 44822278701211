





































import CircleAnnotationDialog from './ViewerAnnotationDialog/CircleAnnotationDialog.vue'
import Checkbox from '../Checkbox.vue'
import { createPopper } from '@popperjs/core'
import { eventBus } from '@services/eventBus'
import { consts } from '@vtk'

export default {
	name: 'MprViewerAnnotationDialog',
	components: {
		CircleAnnotationDialog,
		Checkbox,
	},
	data() {
		return {
			annotation: null,
			component: null,
			isOpen: false,
			popper: null,
			TOOLS: consts,
		}
	},
	computed: {
		canHideTextbox() {
			return this.annotation.type !== consts.TEXT_TOOL
		},
		toolInfo() {
			if (!this.annotation) return {}
			return this.$store.state.mpr.tools.find(t => t.alias === this.annotation.type)
		},
	},
	created() {
		eventBus.on(eventBus.type.MPR_ANNOTATION_CLICK, this.onAnnotationClick)
		eventBus.on(eventBus.type.MPR_ANNOTATION_REMOVED, this.onAnnotationRemoved)
		eventBus.on(eventBus.type.MPR_ANNOTATIONS_CLEARED, this.onAnnotationRemoved)
	},
	mounted() {
		const onKeydown = e => {
			if (!this.isOpen) return
			const activeElement = document.activeElement as HTMLInputElement
			if (activeElement.tagName.toLowerCase() === 'input' && activeElement.type.toLowerCase() === 'text') return
			if (e.key === 'Delete') this.erase()
		}
		document.addEventListener('keydown', onKeydown)
		this.$once('beforeDestroy', () => {
			document.removeEventListener('keydown', onKeydown)
		})
	},
	destroyed() {
		eventBus.off(eventBus.type.MPR_ANNOTATION_CLICK, this.onAnnotationClick)
		eventBus.off(eventBus.type.MPR_ANNOTATION_REMOVED, this.onAnnotationRemoved)
		eventBus.off(eventBus.type.MPR_ANNOTATIONS_CLEARED, this.onAnnotationRemoved)
		if (this.popper) this.popper.destroy()
	},
	methods: {
		onAnnotationClick(annotation) {
			this.annotation = annotation
			this.component = annotation.componentRef
			if (!this.isOpen) {
				this.isOpen = true
				if (this.mq.small) this.$nextTick(this.positionDialog)
			}
		},
		erase() {
			this.annotation.componentRef.$emit('delete', this.annotation.componentRef)
		},
		onAnnotationRemoved(annotation) {
			if (!annotation || annotation === this.annotation) {
				this.annotation = null
				this.close()
			}
		},
		positionDialog() {
			// Get nested annotation element with `data-position-ref` attribute
			const ref = this.component.$el.querySelector('[data-position-ref]')
			if (!ref) return
			const selector = '.viewer-content'
			if (this.popper) this.popper.destroy()
			this.popper = createPopper(ref, this.$refs.dialog, {
				placement: 'left',
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [16, 16],
						},
					},
					// If no room on the left, try the other placements
					{
						name: 'flip',
						options: {
							fallbackPlacements: ['top', 'bottom', 'right'],
							padding: 8,
							boundary: document.querySelector(selector),
						},
					},
					// Otherwise overlap the annotation if no room in the container
					{
						name: 'preventOverflow',
						options: {
							altAxis: true,
							padding: 8,
							boundary: document.querySelector(selector),
						},
					},
				],
			})
		},
		close() {
			this.isOpen = false
		},
	},
}
