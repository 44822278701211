/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { metaData, pixelToCanvas } from 'cornerstone-core/dist/cornerstone.js'
import { lineSegment, point } from 'cornerstone-math/dist/cornerstoneMath.js'
import {
	external,
	getToolState,
	addToolState,
	import as csTools,
	toolColors,
	toolStyle,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

import toolSelectedCallback from './_shared/toolSelectedCallback.js'

// Manipulators
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import moveHandle from '@/cornerstone/manipulators/moveHandle'

// Handle helpers
import { initHandle } from './util/handles'
import getImagePixelSpacing from './util/getImagePixelSpacing'
import { formatNumber } from '@utils/numberUtils'
import round from 'lodash/round'

const BaseAnnotationTool = csTools('base/BaseAnnotationTool')

// Drawing
const draw = csTools('drawing/draw')
const drawHandles = csTools('drawing/drawHandles')
const drawLink = csTools('drawing/drawLink')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')
const drawTextBox = csTools('drawing/drawTextBox')
const getNewContext = csTools('drawing/getNewContext')

// Util
const lineSegDistance = csTools('util/lineSegDistance')

/**
 * @export @public @class
 * @name AstLengthAngleTool
 * @classdesc Tool for measuring distances.
 * @extends BaseAnnotationTool
 */
export default class AstLengthAngle extends BaseAnnotationTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstLengthAngle',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
				showAngles: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
				drawHandlesOnHover: false,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)
		super(initialConfiguration)
		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	addNewMeasurement(evt, interactionType) {
		evt.preventDefault()
		evt.stopPropagation()

		const eventData = evt.detail
		const element = evt.detail.element
		const measurementData = this.createNewMeasurement(eventData)

		if (!measurementData) return

		// Associate this data with this imageId so we can render it and manipulate it
		addToolState(element, this.name, measurementData)

		external.cornerstone.updateImage(element)

		const doneMovingCallback = () => {
			measurementData.active = false
			this.preventNewMeasurement = false
			external.cornerstone.updateImage(element)
		}

		// Step 1, create start and end handle
		initHandle(measurementData.handles.start, null, interactionType, this.options)
		moveNewHandle(
			eventData,
			this.name,
			measurementData,
			measurementData.handles.end,
			this.options,
			interactionType,
			doneMovingCallback
		)
	}

	/**
	 * Create the measurement data for this tool with the end handle activated
	 *
	 * @param {*} eventData
	 * @returns
	 */
	createNewMeasurement(eventData) {
		const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image
		if (!goodEventData) return

		const { x, y } = eventData.currentPoints.image
		const measurementData = {
			visible: true,
			active: true,
			invalidated: true,
			color: undefined,
			handles: {
				start: {
					x,
					y,
					highlight: true,
					active: false,
				},
				end: {
					x,
					y,
					highlight: true,
					active: true,
				},
				textBox: {
					active: false,
					hasMoved: false,
					movesIndependently: false,
					drawnIndependently: true,
					allowedOutsideImage: true,
					hasBoundingBox: true,
				},
				textBoxes: [],
			},
		}
		return measurementData
	}

	/**
	 *
	 *
	 * @param {*} element
	 * @param {*} data
	 * @param {*} coords
	 * @returns
	 */
	pointNearTool(element, data, coords, isMouse = true) {
		const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end
		const validParameters = hasStartAndEndHandles
		const distance = isMouse ? 15 : 25

		if (!validParameters) {
			return false
		}

		if (data.visible === false) {
			return false
		}

		return lineSegDistance(element, data.handles.start, data.handles.end, coords) < distance
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	updateCachedStats() {}

	/**
	 *
	 *
	 * @param {*} evt
	 * @returns
	 */
	renderToolData(evt) {
		const eventData = evt.detail
		const { image, element } = eventData
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData || !toolData.data || !toolData.data.length) return

		const context = getNewContext(eventData.canvasContext.canvas)

		const lineWidth = toolStyle.getToolWidth()
		const imagePlane = metaData.get('imagePlaneModule', image.imageId)
		const { rowPixelSpacing, columnPixelSpacing, isPixelSpacingDefined } = getImagePixelSpacing(image)

		// Any lines or angles for lines with length less than or equal to
		// this tolerance value won't get rendered
		const lineLengthTolerance = 0
		toolData.data.forEach((line, i) => {
			if (line.visible === false) return
			context.save()

			draw(context, context => {
				if (this.configuration.shadow) {
					context.shadowColor = this.configuration.shadowColor
					context.shadowOffsetX = this.configuration.shadowOffsetX
					context.shadowOffsetY = this.configuration.shadowOffsetY
				}
				const color = toolColors.getColorIfActive(line)

				const startHandle = line.handles['start']
				const endHandle = line.handles['end']
				const textBoxHandle = line.handles['textBox']
				const handleStartCanvas = pixelToCanvas(element, startHandle)
				const handleEndCanvas = pixelToCanvas(element, endHandle)

				// Don't render 0-length measurement lines
				if (point.distance(startHandle, endHandle) <= lineLengthTolerance) {
					return
				}

				startHandle.radius = startHandle.radius || 6
				endHandle.radius = endHandle.radius || 6

				// Draw the measurement line
				context.beginPath()
				context.strokeStyle = color
				context.lineWidth = lineWidth
				context.moveTo(handleStartCanvas.x, handleStartCanvas.y)
				context.lineTo(handleEndCanvas.x, handleEndCanvas.y)
				context.stroke()

				// Draw the handles
				const handleOptions = {
					color,
					drawHandlesIfActive: this.configuration.drawHandlesOnHover,
				}
				drawHandles(context, eventData, [startHandle, endHandle], handleOptions)

				// Calculate length
				if (!line.length || line.invalidated) {
					const dx = (endHandle.x - startHandle.x) * columnPixelSpacing
					const dy = (endHandle.y - startHandle.y) * rowPixelSpacing
					const length = Math.sqrt(dx * dx + dy * dy)
					line.length = length
				}

				// Draw length textbox
				let textBoxOffset = 0
				if (!textBoxHandle.hasMoved) {
					const rightmostHandle =
						startHandle.x + startHandle.radius > endHandle.x + endHandle.radius ? startHandle : endHandle
					textBoxHandle.x = rightmostHandle.x
					textBoxHandle.y = rightmostHandle.y
					textBoxOffset = (rightmostHandle.radius || 6) + 6
				}
				const lengthText = getLengthText(line, isPixelSpacingDefined)
				if (!textBoxHandle.hidden) {
					drawLinkedTextBox(
						context,
						element,
						textBoxHandle,
						lengthText,
						[startHandle, endHandle, textBoxHandle],
						textBoxAnchorPoints,
						color,
						lineWidth,
						textBoxOffset,
						true
					)
				}

				if (!this.options.showAngles) return

				// Remove any angle textboxes related to destroyed lines
				line.handles['textBoxes'] = line.handles['textBoxes'].filter(isNotDestroyedAngle)

				function isNotDestroyedAngle(handle) {
					if (!('lineId' in handle)) return true
					const existingLineIds = toolData.data.map(line => line.uuid)
					return existingLineIds.includes(handle.lineId)
				}

				// Add/update angle textbox for every other line
				for (let j = i + 1; j < toolData.data.length; j++) {
					const otherLine = toolData.data[j]

					// Don't render angle if the other line doesn't meet length tolerance
					const startHandle = otherLine.handles['start']
					const endHandle = otherLine.handles['end']
					if (point.distance(startHandle, endHandle) <= lineLengthTolerance) {
						continue
					}

					const intersection = getIntersection(line, otherLine)
					const angleTextBoxes = line.handles['textBoxes'].filter(handle => handle.lineId === otherLine.uuid)

					// Create angle on otherLine
					if (!angleTextBoxes.length) {
						line.handles['textBoxes'].push(createAngleTextBox(otherLine.uuid))
					}
					// Create angle on current line
					if (intersection && angleTextBoxes.length === 1) {
						const isObtuseAngle = true
						line.handles['textBoxes'].push(createAngleTextBox(otherLine.uuid, isObtuseAngle))
					}
					// Remove angle on currentLine
					if (!intersection && angleTextBoxes.length === 2) {
						const indexToRemove = line.handles['textBoxes'].findIndex(
							handle => handle.lineId === otherLine.uuid && handle.isObtuseAngle
						)
						line.handles['textBoxes'].splice(indexToRemove, 1)
					}
				}

				// Draw angle textboxes
				const angleTextBoxes = line.handles['textBoxes'].filter(handle => 'lineId' in handle)
				angleTextBoxes.forEach(textBox => {
					const otherLine = toolData.data.find(line => line.uuid === textBox.lineId)
					if (line.invalidated || !textBox.angle) {
						textBox.angle = getAngle(line, otherLine)
						if (textBox.isObtuseAngle) textBox.angle = 180 - textBox.angle
					}
					const intersection = getIntersection(line, otherLine)
					const linkAnchors = intersection ? [intersection] : getClosestHandlePair(line, otherLine)
					if (!textBox.hasMoved) {
						let coords
						if (intersection) {
							const { acute, obtuse } = getAcuteObtuseCoords(line, otherLine, intersection)
							coords = textBox.isObtuseAngle ? obtuse : acute
						} else {
							coords = getMidpoint(...linkAnchors)
						}
						textBox.x = coords.x
						textBox.y = coords.y
					}
					const textCoords = pixelToCanvas(element, textBox)
					const textBoxColor = toolColors.getColorIfActive(line.active ? line : otherLine)
					if (!textBox.hidden) {
						textBox.boundingBox = drawTextBox(
							context,
							`${formatNumber(textBox.angle)}°`,
							textCoords.x,
							textCoords.y,
							textBoxColor,
							{ centering: { x: true, y: true } },
							textBox.hidden
						)
						linkAnchors.forEach(anchor => {
							const anchorCanvas = pixelToCanvas(element, anchor)
							drawLink([anchorCanvas], textCoords, textBox.boundingBox, context, textBoxColor, lineWidth)
						})
					}
				})
			})
			context.restore()
		})
	}
}

// START PRIVATE

function createAngleTextBox(lineId, isObtuseAngle = false) {
	return {
		lineId,
		isObtuseAngle,
		angle: null,
		active: false,
		hasMoved: false,
		movesIndependently: false,
		drawnIndependently: true,
		allowedOutsideImage: true,
		hasBoundingBox: true,
	}
}

function getAngle(lineA, lineB) {
	const slopeA = getSlope(lineA)
	const slopeB = getSlope(lineB)
	let angle = 0
	if ([-1, 0].includes(slopeA * slopeB)) {
		angle = Math.PI / 2
	} else {
		angle = Math.abs(Math.atan((slopeA - slopeB) / (1 + slopeA * slopeB)))
	}
	if (Number.isNaN(angle)) return
	return round(angle * (180 / Math.PI), 1)
}

function getSlope(line) {
	const startHandle = line.handles['start']
	const endHandle = line.handles['end']
	if (startHandle.x - endHandle.x === 0) return Number.MAX_SAFE_INTEGER
	return (startHandle.y - endHandle.y) / (startHandle.x - endHandle.x)
}

function getIntersection(lineA, lineB) {
	function invalidLineHandles(line) {
		return !line || !line.handles || !line.handles.start || !line.handles.end
	}
	if (invalidLineHandles(lineA) || invalidLineHandles(lineB)) {
		return
	}
	return lineSegment.intersectLine(
		{ start: lineA.handles.start, end: lineA.handles.end },
		{ start: lineB.handles.start, end: lineB.handles.end }
	)
}

function getClosestHandlePair(lineA, lineB) {
	const pointPairs = [
		[lineA.handles['start'], lineB.handles['start']],
		[lineA.handles['end'], lineB.handles['start']],
		[lineA.handles['start'], lineB.handles['end']],
		[lineA.handles['end'], lineB.handles['end']],
	]
	let closestPair = pointPairs[0]
	pointPairs.forEach(pointPair => {
		if (point.distance(...pointPair) < point.distance(...closestPair)) {
			closestPair = pointPair
		}
	})
	return closestPair
}

function getAcuteObtuseCoords(lineA, lineB, intersection) {
	const [horizontal, vertical] = [lineA, lineB].sort((a, b) => Math.abs(getSlope(a)) - Math.abs(getSlope(b)))
	const [top] = [vertical.handles['start'], vertical.handles['end']].sort((a, b) => a.y - b.y)
	const [horizontalAcute, horizontalObtuse] = [horizontal.handles['start'], horizontal.handles['end']].sort((a, b) => {
		const angleA = getAngleFromPoints(intersection, top, a)
		const angleB = getAngleFromPoints(intersection, top, b)
		return angleA - angleB
	})
	const acute = getMidpoint(horizontalAcute, top)
	const obtuse = getMidpoint(horizontalObtuse, top)
	let offset = 40 // space textboxes further apart
	acute.y -= offset
	obtuse.y -= offset
	if (acute.x > obtuse.x) offset *= -1
	acute.x -= offset
	obtuse.x += offset
	return { acute, obtuse }
}

function getAngleFromPoints(origin, a, b) {
	const radians = Math.atan2(b.y - origin.y, b.x - origin.x) - Math.atan2(a.y - origin.y, a.x - origin.x)
	let degrees = Math.abs((radians * 180) / Math.PI)
	if (degrees > 360) degrees -= 360
	if (degrees > 180) degrees = 360 - degrees
	return degrees
}

function getMidpoint(pointA, pointB) {
	return {
		x: (pointA.x + pointB.x) / 2,
		y: (pointA.y + pointB.y) / 2,
	}
}

function getLengthText(data, isPixelSpacingDefined) {
	let suffix = isPixelSpacingDefined ? ' mm' : ' px'
	return `${formatNumber(round(data.length, 2))}${suffix}`
}

function textBoxAnchorPoints(handles) {
	const midpoint = {
		x: (handles[0].x + handles[1].x) / 2,
		y: (handles[0].y + handles[1].y) / 2,
	}
	return [handles[0], midpoint, handles[1]]
}
