<template>
	<modal-wrapper v-if="showHotkeys" @close="closeModal">
		<div class="hotkey-modal">
			<!-- HEADER -->
			<dlg-header title="Keyboard Shortcuts" @close="closeModal" />

			<!-- BODY -->
			<div class="hotkey-modal-body">
				<div v-for="keygroup in hotkeyGroups" :key="keygroup.key">
					<div class="hotkey-list">
						<ul>
							<!-- TITLE ROW -->
							<li>
								<h5>{{ keygroup.title }}</h5>
							</li>
							<!-- HOTKEYS -->
							<li v-for="hotkey in keygroup.hotKeys" :key="hotkey.name">
								<div class="key">
									<span>{{ hotkey.keyBinding | hotkey }}</span>
								</div>
								<div class="keyname">
									{{ hotkey.description }}
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import DlgHeader from '@components/DlgHeader.vue'
import { mapState } from 'vuex'

const keyGroups = [
	{ key: 'canvas-tools', title: 'Canvas Tools' },
	{ key: 'navigation-tools', title: 'Navigation Tools' },
	{ key: 'image-tools', title: 'Image Tools' },
	{ key: 'misc-tools', title: 'Misc Tools' },
]

export default {
	name: 'HotkeyModal',
	components: {
		ModalWrapper,
		DlgHeader,
	},
	filters: {
		hotkey: value => {
			if (value === 'pagedown') {
				return 'PgDn'
			}
			if (value === 'pageup') {
				return 'PgUp'
			}
			if (value === 'up') {
				return '↑'
			}
			if (value === 'down') {
				return '↓'
			}
			if (value === 'left') {
				return '←'
			}
			if (value === 'right') {
				return '→'
			}
			if (value === undefined) {
				return value
			}
			return value.toUpperCase()
		},
	},
	props: {
		showHotkeys: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapState({
			hotkeys: state => state.viewerHotkeys.hotkeys,
		}),
		hotkeyGroups() {
			const g = []
			keyGroups.forEach(({ key, title }) => {
				const filtered = this.hotkeys.filter(h => h.group === key)
				if (filtered.length) {
					g.push({ key, title, hotKeys: filtered })
				}
			})
			return g
		},
	},
	methods: {
		closeModal() {
			this.$emit('close', false)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.hotkey-modal {
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	display: flex;
	flex-direction: column;
	position: relative;
	overflow-x: hidden;
	min-width: 320px;
	max-width: 900px;
	max-height: 90vh;
	width: 100%;
}

.hotkey-modal-body {
	padding: 16px;
	overflow: auto;

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

	& > div {
		width: 45%;
		display: flex;
		padding-left: 50px;
		margin-bottom: 20px;
	}
	@media (max-width: $mqMedium) {
		& > div {
			padding-left: 10px;
		}
	}
}

.hotkey-list {
	min-width: 240px;
	ul {
		list-style: none;
	}

	li {
		padding: 10px 0;
		display: flex;
		align-items: center;
	}

	.key {
		text-align: right;
	}

	span {
		display: inline-block;
		text-align: center;
		min-width: 2em;
		padding: 3px;
		margin-right: 8px;
		font-size: 0.8em;
		color: var(--hotkey-indicator-color);
		background: var(--hotkey-indicator-bg);
		border: 1px solid var(--hotkey-indicator-border);
	}
}
</style>
