/* eslint-disable curly, capitalized-comments, newline-before-return, newline-after-var, object-property-newline */
import { EVENTS, getEnabledElement, metaData, pixelToCanvas, updateImage } from 'cornerstone-core/dist/cornerstone.js'
import {
	addToolState,
	clearToolState,
	getToolState,
	import as csTools,
	loadHandlerManager,
	setToolDisabled,
	store,
	toolColors,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import { waitForElementToBeEnabled, waitForEnabledElementImageToLoad } from '@/utils/wait.js'
import convertToVector3 from '@/utils/convertToVector3'
import isLocalizerImage from '../_shared/isLocalizerImage'

const BaseTool = csTools('base/BaseTool')

// Drawing
const drawLine = csTools('drawing/drawLine')
const getNewContext = csTools('drawing/getNewContext')

// Util
const imagePointToPatientPoint = csTools('util/imagePointToPatientPoint')
const projectPatientPointToImagePlane = csTools('util/projectPatientPointToImagePlane')
const scrollToIndex = csTools('util/scrollToIndex')

/**
 * @export @public @class
 * @name AstCrossPoint
 * @classdesc
 * @extends BaseAnnotationTool
 */
export default class AstCrossPoint extends BaseTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstCrossPoint',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				preventHandleOutsideImage: true,
			},
			configuration: {
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)

		super(initialConfiguration)

		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)

		this.updatePoint = _updatePoint.bind(this)
		this.clearPointsIfNotSynced = _clearPointsIfNotSynced.bind(this)
		this.syncedId = null

		this.postTouchStartCallback = this.onStart.bind(this)
		this.touchDragCallback = this.onDrag.bind(this)
		this.touchEndCallback = this.onEnd.bind(this)

		this.postMouseDownCallback = this.onStart.bind(this)
		this.mouseDragCallback = this.onDrag.bind(this)
		this.mouseUpCallback = this.onEnd.bind(this)

		this.mouseMoveCallback = () => false
	}

	activeCallback(element, options) {
		this.element.removeEventListener(EVENTS.NEW_IMAGE, this.clearPointsIfNotSynced)
		this.element.addEventListener(EVENTS.NEW_IMAGE, this.clearPointsIfNotSynced)
	}

	passiveCallback(element, options) {
		setToolDisabled(this.name, options)
	}

	enabledCallback(element, options) {
		setToolDisabled(this.name, options)
	}

	disabledCallback(element, options) {
		this.element.removeEventListener(EVENTS.NEW_IMAGE, this.clearPointsIfNotSynced)
		store.state.enabledElements.forEach(async enabledElement => {
			clearToolState(enabledElement, this.name)
			const isEnabled = await waitForElementToBeEnabled(enabledElement)
			const hasLoadedImage = await waitForEnabledElementImageToLoad(enabledElement)
			if (isEnabled && hasLoadedImage) {
				updateImage(enabledElement)
			}
		})
	}

	renderToolData(evt) {
		const eventData = evt.detail
		const element = eventData.element
		const toolData = getToolState(evt.currentTarget, this.name)
		if (!toolData || !toolData.data || !toolData.data.length) return
		const context = getNewContext(eventData.canvasContext.canvas)
		toolData.data.forEach(data => {
			if (data.visible === false) return
			if (!data.point) return
			_drawCrosshairs(data, context, element)
		})
	}

	// Universal Event Handlers (Touch, Mouse)
	onStart(evt) {
		this.updatePoint(evt)
		evt.preventDefault()
		evt.stopPropagation()

		const consumeEvent = true
		return consumeEvent
	}
	onDrag(evt) {
		this.updatePoint(evt)
		evt.preventDefault()
		evt.stopPropagation()
	}
	onEnd(evt) {
		store.state.enabledElements.forEach(enabledElement => {
			clearToolState(enabledElement, this.name)
			updateImage(enabledElement)
		})
	}
}

/**
 *
 *
 * @param {*} data
 * @param {*} context
 * @param {*} element
 */
const _drawCrosshairs = function(data, context, element) {
	const color = toolColors.getActiveColor()
	const point = pixelToCanvas(element, data.point)
	const distance = 15
	const top = {
		x: point.x,
		y: point.y - distance,
	}
	const right = {
		x: point.x + distance,
		y: point.y,
	}
	const bottom = {
		x: point.x,
		y: point.y + distance,
	}
	const left = {
		x: point.x - distance,
		y: point.y,
	}
	drawLine(context, element, top, bottom, { color }, 'canvas')
	drawLine(context, element, left, right, { color }, 'canvas')
}

/**
 *
 *
 * @param {*} evt
 * @returns
 */
const _updatePoint = function(evt) {
	const eventData = evt.detail
	evt.stopImmediatePropagation()
	const sourceElement = evt.currentTarget
	const sourceEnabledElement = getEnabledElement(sourceElement)
	const sourceImageId = sourceEnabledElement.image.imageId
	const sourceImagePlane = metaData.get('imagePlaneModule', sourceImageId)
	if (
		!sourceImagePlane ||
		!sourceImagePlane.rowCosines ||
		!sourceImagePlane.columnCosines ||
		!sourceImagePlane.imagePositionPatient ||
		!sourceImagePlane.frameOfReferenceUID
	)
		return

	const sourceImagePoint = eventData.currentPoints.image
	const sourcePatientPoint = imagePointToPatientPoint(sourceImagePoint, sourceImagePlane)

	store.state.enabledElements.forEach(async targetElement => {
		const stackToolDataSource = getToolState(targetElement, 'stack')
		const isMissingStackData = !stackToolDataSource || !stackToolDataSource.data || !stackToolDataSource.data.length
		if (isMissingStackData) return
		const seriesStack = stackToolDataSource.data[0]

		// Clear
		clearToolState(targetElement, this.name)

		// Update
		const bestImageIdIndex = _findBestImageIdIndex(
			seriesStack,
			sourcePatientPoint,
			sourceImagePlane.frameOfReferenceUID,
			sourceImageId
		)
		if (bestImageIdIndex !== null) {
			try {
				const imageId = seriesStack.imageIds[bestImageIdIndex]
				const targetTool = store.state.tools.find(tool => tool.element === targetElement && tool.name === this.name)

				if (targetTool) {
					targetTool.syncedId = imageId
				}
				scrollToIndex(targetElement, bestImageIdIndex)

				// New ToolState w/ bestImageId
				const targetMeta = metaData.get('imagePlaneModule', imageId)
				if (!targetMeta || !targetMeta.rowCosines || !targetMeta.columnCosines || !targetMeta.imagePositionPatient)
					return

				const crossPoint = projectPatientPointToImagePlane(sourcePatientPoint, targetMeta)
				const toolData = getToolState(targetElement, this.name)
				if (!toolData || !toolData.data || !toolData.data.length) {
					addToolState(targetElement, this.name, {
						point: crossPoint,
					})
				} else {
					toolData.data[0].point = crossPoint
				}
			} catch (err) {
				const errorLoadingHandler = loadHandlerManager.getErrorLoadingHandler()
				const imageId = seriesStack.imageIds[bestImageIdIndex]
				if (errorLoadingHandler) errorLoadingHandler(targetElement, imageId, err)
			}
		}

		// Force redraw
		updateImage(targetElement)
	})
}

const _clearPointsIfNotSynced = function() {
	const imageId = getEnabledElement(this.element).image.imageId

	if (!imageId) return // No image
	if (!this.syncedId) return // No syncedId
	if (imageId === this.syncedId) return // SyncedId matches :+1:

	store.state.enabledElements.forEach(enabledElement => {
		clearToolState(enabledElement, this.name)
		updateImage(enabledElement)
	})
}

const _findBestImageIdIndex = function(seriesStack, sourcePatientPoint, sourceFrameOfReference, sourceImageId) {
	let newImageIdIndex = null
	let minDistance = Number.MAX_VALUE
	for (let index = 0; index < seriesStack.imageIds.length; index++) {
		const imageId = seriesStack.imageIds[index]
		// if this is the source image, use this index
		if (imageId === sourceImageId) {
			newImageIdIndex = index
			break
		}
		const targetMeta = metaData.get('imagePlaneModule', imageId)
		if (
			!targetMeta ||
			!targetMeta.imagePositionPatient ||
			!targetMeta.rowCosines ||
			!targetMeta.columnCosines ||
			!targetMeta.sliceThickness
		)
			continue
		// Verify image is from same study as source image
		if (targetMeta.frameOfReferenceUID !== sourceFrameOfReference) continue
		// Verify image is not a localizer image [ch4638]
		if (isLocalizerImage(seriesStack.imageIds, imageId, targetMeta)) continue
		const imagePosition = convertToVector3(targetMeta.imagePositionPatient)
		const row = convertToVector3(targetMeta.rowCosines)
		const column = convertToVector3(targetMeta.columnCosines)
		// A vector that is perpendicular to both `column` and `row` and thus 'normal'
		const normal = column.clone().cross(row.clone())
		// Distance from image's plane to normal's origin
		const targetPlanePosition = normal.clone().dot(imagePosition)
		// Distance from a same-oriented plane containing the source point to normal's origin
		const sourcePointPlanePosition = normal.clone().dot(sourcePatientPoint)
		// Distance between derived target and source planes
		const distance = Math.abs(targetPlanePosition - sourcePointPlanePosition)
		const tolerance = targetMeta.sliceThickness * 2
		// Do not scroll to an image that is more than double the slice thickness away
		if (distance > tolerance) continue
		if (distance < minDistance) {
			minDistance = distance
			newImageIdIndex = index
		}
	}

	return newImageIdIndex
}
