import { external, import as csTools } from 'cornerstone-tools/dist/cornerstoneTools.js'
const BaseTool = csTools('base/BaseTool')

/**
 * @public
 * @class RotateTool
 * @memberof Tools
 *
 * @classdesc Tool for rotating the image.
 * @extends Tools.Base.BaseTool
 */

export default class RotateTool extends BaseTool {
	firstTouchOnMobile = true
	constructor(props = {}) {
		const defaultProps = {
			name: 'Rotate',
			strategies: {
				horizontal: horizontalStrategy,
				vertical: verticalStrategy,
			},
			defaultStrategy: 'vertical',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			configuration: {
				roundAngles: true,
				flipHorizontal: true,
				flipVertical: true,
				rotateScale: 0.33,
			},
			rotationValue: 0,
		}
		super(props, defaultProps)
		this.postMouseDownCallback = this._movingEventCallback.bind(this)
		this.touchEndCallback = this._endMobileMovingEventCallback.bind(this)
	}
	touchDragCallback(evt) {
		this.dragCallback(evt)
		if (this.firstTouchOnMobile) {
			if (typeof this.rotationValue === 'undefined') {
				evt.detail.viewport.rotation = 0
				this._movingEventCallback(evt)
			}
		}
		this.firstTouchOnMobile = false
	}

	_endMobileMovingEventCallback(evt) {
		this.rotationValue = evt.detail.viewport.rotation
	}

	mouseDragCallback(evt) {
		this.dragCallback(evt)
	}

	_movingEventCallback(evt) {
		const { viewport } = evt.detail
		this.rotationValue = viewport.rotation
	}

	dragCallback(evt) {
		console.log('dragcallback')
		if (this.initialRotation === undefined) this.initialRotation = evt.detail.viewport.rotation
		evt.detail.viewport.initialRotation = this.initialRotation
		this.applyActiveStrategy(evt)
		external.cornerstone.setViewport(evt.detail.element, evt.detail.viewport)
	}
}

function horizontalStrategy(evt) {
	const { roundAngles, flipHorizontal, rotateScale } = this.configuration
	const { viewport, startPoints, currentPoints } = evt.detail
	if (!startPoints) return
	const initialRotation = viewport.initialRotation
	const initialPointX = startPoints.client.x
	const currentPointX = currentPoints.client.x

	let angle = (currentPointX - initialPointX) * rotateScale

	if (roundAngles) {
		angle = Math.round(Math.abs(angle)) * (angle > 0 ? 1 : -1)
	}
	if (flipHorizontal) {
		angle = -angle
	}

	viewport.rotation = initialRotation + angle
}

function verticalStrategy(evt) {
	const { roundAngles, flipVertical, rotateScale } = this.configuration
	const { viewport, startPoints, currentPoints } = evt.detail
	if (!startPoints) return
	const initialRotation = this.rotationValue
	const initialPointY = startPoints.client.y
	const currentPointY = currentPoints.client.y

	let angle = (currentPointY - initialPointY) * rotateScale

	if (roundAngles) {
		angle = Math.round(Math.abs(angle)) * (angle > 0 ? 1 : -1)
	}
	if (flipVertical) {
		angle = -angle
	}

	viewport.rotation = initialRotation + angle
}
