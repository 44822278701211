import {
  updateImage,
  pixelToCanvas,
} from 'cornerstone-core/dist/cornerstone.js'

import {
  import as csTools,
  store,
  getToolState,
  removeToolState,
} from 'cornerstone-tools/dist/cornerstoneTools.js'

const BaseTool = csTools('base/BaseTool')
const state = store.state
// const { eraserCursor } = csTools('tools/cursors')

/**
 * @public
 * @class AstEraserTool
 * @memberof Tools.Custom
 *
 * @classdesc Tool for deleting the data of other Annotation Tools.
 * @extends Tools.Base.BaseTool
 */
export default class AstEraser extends BaseTool {
  constructor(config = {}) {
    const defaultConfig = {
      name: 'AstEraser',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      // svgCursor: eraserCursor,
    }
    super(Object.assign(defaultConfig, config))

    this.preMouseDownCallback = this._deleteAllNearbyTools.bind(this)
    this.preTouchStartCallback = this._deleteAllNearbyTools.bind(this)
  }

  _deleteAllNearbyTools(evt) {
    const coords = evt.detail.currentPoints.canvas
    const element = evt.detail.element

    state.tools.forEach(tool => {
      const toolState = getToolState(element, tool.name)
      if (toolState) {
        toolState.data.forEach(data => {
          const handles = data.handles
          let removeTool = false
          // Check for handles
          if (handles) {
            if (handles.length) {
              // Remove if coordinates are near any of the handle elements
              removeTool = !!handles.find(h => this._pointsNear(coords, h))
            } else if (handles.start && handles.end) {
              // Remove if coordinates are near the handle start or end
              const startCanvas = pixelToCanvas(element, handles.start)
              const endCanvas = pixelToCanvas(element, handles.end)
              removeTool =
                this._pointsNear(coords, startCanvas) ||
                this._pointsNear(coords, endCanvas)
            }
          }
          // Or remove tool if coordinates are near the tool
          if (
            !removeTool &&
            typeof tool.pointNearTool === 'function' &&
            tool.pointNearTool(element, data, coords)
          ) {
            removeTool = true
          }
          // Remove it!
          if (removeTool) {
            removeToolState(element, tool.name, data)
            updateImage(element)
          }
        })
      }
    })
    return true
  }

  _pointsNear({ x: fromX, y: fromY }, { x: toX, y: toY }, tolerance = 5) {
    return (
      Math.abs(fromX - toX) <= tolerance && Math.abs(fromY - toY) <= tolerance
    )
  }
}
