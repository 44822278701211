<template>
	<div id="email-images-bar">
		<h3>Email Images</h3>
		<div class="buttons">
			<button class="btn btn-default" @click="addItem()">Add Image</button>
			<button class="btn btn-default" :disabled="!canAddSeries" @click="addItem(true)">
				Add Series
			</button>
			<button class="btn btn-success" :disabled="items.length == 0" @click="sendEmail">
				Send Email
			</button>
			<button class="btn btn-danger" @click="close">Cancel</button>
		</div>
		<div class="image-list">
			<span v-for="item in items" :key="item.key" class="item" @click="removeItem(item)">
				<img :src="item.thumbUrl" />
				<span v-if="item.entireSeries" class="seriesTag">{{ item.countText }}</span>
				<span class="removeBtn">Remove</span>
			</span>
		</div>
	</div>
</template>

<script>
import { openEmailStudyDlg } from '@dialogs/emailStudyDlg.vue'
import { EmailItem } from '@components/EmailStudyForm.vue'

export default {
	name: 'EmailImagesSidebar',
	data() {
		return {
			items: [],
		}
	},
	computed: {
		canAddSeries() {
			return this.$store.getters.activeSeries.images.length > 1
		},
	},
	methods: {
		close() {
			this.$emit('close')
		},
		sendEmail() {
			let studies = this.$store.state.viewer.studies
			openEmailStudyDlg(studies, this.items)
				.then(() => {
					this.close()
				})
				.catch(err => {})
		},
		removeItem(item) {
			if (this.items.includes(item)) this.items.splice(this.items.indexOf(item), 1)
		},
		addItem(entireSeries = false) {
			let img = this.$store.getters.activeImage
			let series = this.$store.getters.activeSeries
			let item = new EmailItem(series, img, entireSeries)

			let existingSeriesItems = this.items.filter(i => i.seriesId === item.seriesId)

			// only add item if not already in list, if entires series then only add 1 item for the series
			if (entireSeries) {
				this.items = this.items.filter(i => i.seriesId !== item.seriesId)
			} else {
				let found = existingSeriesItems.find(s => s.entireSeries || s.imageId === item.imageId)
				if (found) {
					return
				}
			}

			this.items.push(item)
		},
	},
}
</script>

<style lang="scss">
#email-images-bar {
	padding: 10px;
	background: var(--secondary-bg);
	height: 100%;
	flex-shrink: 0;
	width: 300px;

	h3 {
		margin-bottom: 6px;
	}

	button {
		margin-right: 4px;
		margin-bottom: 4px;
	}

	.buttons {
		display: flex;
		flex-wrap: wrap;
	}

	.image-list {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;

		.item {
			margin: 1%;
			box-sizing: border-box;
			width: 48%;
			position: relative;
			cursor: pointer;

			.seriesTag {
				position: absolute;
				top: 10px;
				left: 10px;
				color: white;
			}

			.removeBtn {
				display: none;
				position: absolute;
				bottom: 10px;
				left: 10px;
				color: white;
			}

			img {
				width: 100%;
			}

			&:hover {
				img {
					opacity: 0.7;
				}
				.removeBtn {
					display: block;
				}
			}
		}
	}
}
</style>
