<template>
	<main class="viewer-layout">
		<desktop-downloader />
		<template v-if="toolbarLocation === 'bottom'">
			<ast-mpr-viewer-tools v-if="mprActive" :toolbar-location="toolbarLocation" @toggle-drawer="toggleDrawer" />
			<ast-viewer-tools
				v-else
				:active-series="activeSeries"
				:active-image="activeImage"
				:is-repository="isRepository"
				:toolbar-location="toolbarLocation"
				@toggle-drawer="toggleDrawer"
			/>
		</template>

		<div class="viewer-content">
			<transition name="reveal-right" @after-enter="resizeCanvases" @after-leave="resizeCanvases">
				<persistent-navigation-drawer
					v-if="mq.medium && isPersistentDrawerOpen && !mprActive"
					class="navigation-drawer"
					:class="{ 'dragged-over': navPanelDraggedOver }"
					@transition="resizeCanvases"
					@dragover.native="onNavDrawerDragOver"
					@dragleave.native="onNavDrawerDragLeave"
					@drop.native="onNavDrawerDrop"
				>
					<ast-viewer-add-related-study
						v-if="allowRelatedStudies && !isViewerLoading"
						:related-studies="relatedStudies"
					/>
					<div class="study-container">
						<transition-group name="fade">
							<ast-viewer-study
								v-for="study in studies"
								:key="study.studyId"
								:study="study"
								:show-heading="studies.length > 1"
								:show-patient-name="isMultiplePatients"
                :clinic-code ="clinicCode"
							/>
						</transition-group>
					</div>
				</persistent-navigation-drawer>
			</transition>

			<template v-if="toolbarLocation === 'left'">
				<ast-mpr-viewer-tools v-if="mprActive" :toolbar-location="toolbarLocation" @toggle-drawer="toggleDrawer" />
				<ast-viewer-tools
					v-else
					:active-series="activeSeries"
					:active-image="activeImage"
					:is-repository="isRepository"
					:toolbar-location="toolbarLocation"
					@toggle-drawer="toggleDrawer"
				/>
			</template>

			<div class="page-wrapper">
				<div v-if="!mprActive" ref="layoutContainer" class="layout-pane-container">
					<div
						v-for="canvas in visibleCanvases"
						:key="canvas.index"
						class="layout-pane"
						:style="layoutPaneSize"
						@contextmenu="openRelatedMenu"
						@dragover="onCanvasDragOver(canvas.index, $event)"
						@dragenter="onCanvasDragEnter"
						@dragexit="onCanvasDragExit"
						@dragleave="onCanvasDragLeave"
						@drop="onCanvasDrop"
						@mousedown="onImageViewerMouseDown($event, canvas.index)"
						@touchstart="setActiveCanvas({ canvasIndex: canvas.index })"
						@wheel="setActiveCanvas({ canvasIndex: canvas.index })"
					>
						<template v-show="!isViewerLoading">
							<ast-image-viewer
								v-if="canvasIsVisible(canvas)"
								ref="canvases"
								:key="canvas.index"
								v-dblclick="() => $store.dispatch('resetLevels')"
								v-bind="canvas"
								:class="{ isActiveViewer: activeCanvasIndex == canvas.index }"
								:is-repository="isRepository"
								:series="getCanvasSeries(canvas)"
								:series-index="activeStudySeries.findIndex(x => x.seriesId == canvas.seriesId)"
								:series-total="activeStudySeries.length"
								:show-overlay-text="settings.overlayText"
							/>
							<ast-image-placeholder
								v-else-if="isUnmatchedImageView(canvas) || hideLoadingText"
								:key="canvas.index"
								:index="canvas.index"
								:class="{ isActiveViewer: activeCanvasIndex == canvas.index }"
							>
								<template v-if="studies.length && isUnmatchedImageView(canvas)">
									{{ canvas.imageView.name }} is not available
								</template>
							</ast-image-placeholder>
						</template>
					</div>
					<transition v-if="loadingText" name="fade">
						<h1 class="loading-text">{{ loadingText }}</h1>
					</transition>
				</div>
				<ast-mpr-viewer
					v-if="mprActive"
					v-bind="activeCanvas"
					:series="getCanvasSeries(activeCanvas)"
					:initial-slice="mprInitialSlice"
					:initial-voi="mprInitialVoi"
				/>
				<ast-toolbar v-if="!mprActive && showBottomNavigation" class="bottom-navigation">
					<ast-viewer-navigation
						:active-series="activeSeries"
						:all-series="activeStudySeries"
						:show-dropdowns-below="false"
						@toggle-cine="isCineDialogOpen = !isCineDialogOpen"
					/>
				</ast-toolbar>
			</div>

			<template v-if="toolbarLocation === 'right'">
				<ast-mpr-viewer-tools v-if="mprActive" :toolbar-location="toolbarLocation" @toggle-drawer="toggleDrawer" />
				<ast-viewer-tools
					v-else
					:active-series="activeSeries"
					:active-image="activeImage"
					:is-repository="isRepository"
					:toolbar-location="toolbarLocation"
					@toggle-drawer="toggleDrawer"
				/>
			</template>

			<persistent-navigation-drawer class="tool-help-drawer">
				<slot name="right-drawer">
					<ast-viewer-tool-help @transition="resizeCanvases" />
				</slot>
			</persistent-navigation-drawer>

			<email-images-sidebar v-if="showEmailImages" @close="showEmailImages = false" />
		</div>

		<template v-if="toolbarLocation === 'top'">
			<ast-mpr-viewer-tools v-if="mprActive" :toolbar-location="toolbarLocation" @toggle-drawer="toggleDrawer" />
			<ast-viewer-tools
				v-else
				:active-series="activeSeries"
				:active-image="activeImage"
				:is-repository="isRepository"
				:toolbar-location="toolbarLocation"
				@toggle-drawer="toggleDrawer"
			/>
		</template>

		<ast-toolbar :is-primary="true">
			<!--TOOLBAR LEFT SIDE (NON-MPR)-->
			<div v-if="!mprActive" class="left">
				<!--KEYSTONE BRANDING-->
				<ast-toolbar-branding v-if="!isDesktop" />
				<!--PRIMARY BUTTONS (LEFT)-->
				<slot name="primary-buttons-left"></slot>
				<ast-toolbar-button
					v-for="(btn, title) in getPrimaryToolbarGroup('left')"
					:key="title"
					:icon="btn.icon"
					:title="title"
					:label="btn.label"
					:class="btn.class"
					:has-notification="btn.hasNotification"
					@click.native="btn.action()"
				/>
				<!--LAYOUT SELECTOR-->
				<ast-viewer-layout-selector :settings-config="settingsConfig" @layout-selected="handleLayoutSelected">
					<template #mpr>
						<ul v-if="!isRepository">
							<li
								:class="{ disabled: !!mprLoadingReason }"
								style="display: flex; flex-direction: row; align-items: flex-start; max-width: 250px;"
								@mousedown.stop="checkAndLoadMpr"
							>
								<svg-icon
									v-if="hasActiveSeries && !!mprLoadingReason"
									icon="spinner-circle-thick"
									class="no-disable"
									spin
								/>
								<svg-icon v-else icon="layout-mpr-3x1" fixed inline />
								<span style="white-space: normal; text-overflow: initial; flex-grow:1; margin-left: 4px;">
									{{ mprLoadingReason || 'View MPR' }}
								</span>
							</li>
						</ul>
					</template>
				</ast-viewer-layout-selector>
				<ast-toolbar-button
					v-if="isFullScreenSupported"
					:icon="isFullScreen ? 'fullscreen-exit-alt' : 'fullscreen-alt'"
					:title="isFullScreen ? 'Exit Full Screen' : 'Full Screen'"
					@click.native="toggleFullScreen"
				/>
			</div>
			<!--TOOLBAR LEFT SIDE (MPR)-->
			<div v-else class="left">
				<!--KEYSTONE BRANDING-->
				<ast-toolbar-branding />
				<!--EXIT MPR BUTTON-->
				<ast-toolbar-button
					label="Exit MPR"
					class="back-btn"
					icon="arrow-left"
					@click.native="() => setMprActive(false)"
				/>
				<!--MPR LAYOUT SELECTOR-->
				<ast-mpr-viewer-layout-selector />
			</div>
			<!--CENTER VIEWER NAVIGATION-->
			<ast-viewer-navigation
				v-if="!mprActive && !showBottomNavigation"
				class="top-navigation"
				:active-series="activeSeries"
				:all-series="activeStudySeries"
				@toggle-cine="isCineDialogOpen = !isCineDialogOpen"
			/>
			<!--TOOLBAR RIGHT SIDE-->
			<div class="right">
				<slot name="primary-buttons-right"></slot>
				<!-- HELP BUTTONS -->
				<help-menu :items="helpItems" />
				<!--USER DROPDOWN MENU-->
				<user-menu />
				<!--PRIMARY BUTTONS (RIGHT)-->
				<ast-toolbar-button
					v-for="(btn, title) in getPrimaryToolbarGroup('right')"
					:key="title"
					:icon="btn.icon"
					:title="title"
					:label="btn.label"
					:class="btn.class"
					:has-notification="btn.hasNotification"
					@click.native="btn.action()"
				/>
			</div>
		</ast-toolbar>

		<!-- DRAWER -->
		<modal-drawer
			v-if="!mq.medium && isModalDrawerOpen"
			class="navigation-drawer"
			@close="$store.commit('TOGGLE_MODAL_DRAWER', false)"
		>
			<ast-viewer-add-related-study v-if="allowRelatedStudies && !isViewerLoading" :related-studies="relatedStudies" />
			<div class="study-container">
				<transition-group name="fade">
					<ast-viewer-study
						v-for="study in studies"
						:key="study.studyId"
						:study="study"
						:show-heading="studies.length > 1"
						:show-patient-name="isMultiplePatients"
						@drag-thumbnail="$store.commit('TOGGLE_MODAL_DRAWER', false)"
						@openattachment="openAttachment"
					/>
				</transition-group>
			</div>
		</modal-drawer>

		<slot name="dialogs"></slot>

		<slot name="panels"></slot>
		<!-- Panels -->
		<viewer-actions-drawer
			v-if="isDrawerOpen.mprActions"
			@save="saveMprImageAsync"
			@print="printMprImageAsync"
			@copy="copyMprImageAsync"
			@clear-annotations="() => clearMprActiveAnnotations()"
			@burn-in-annotations="$emit('burn-in-annotations', $event)"
			@close="isDrawerOpen.mprActions = false"
		/>

		<viewer-actions-drawer
			v-if="isDrawerOpen.actions"
			@save="saveCanvasAsync"
			@print="printCanvasAsync"
			@copy="copyCanvasAsync"
			@show-email-images="showEmailImages = true"
			@clear-annotations="() => syncClearAnnotations({ activeImage, activeSeries })"
			@burn-in-annotations="$emit('burn-in-annotations', $event)"
			@download-study="$emit('download-study')"
			@download-dicom-images="$emit('download-dicom-images')"
			@download-jpeg-images="$emit('download-jpeg-images')"
			@import-into-keystone="$emit('import-into-keystone')"
			@close="isDrawerOpen.actions = false"
		/>

		<dicom-tags-drawer
			v-if="isDrawerOpen.dicom"
			:preview-image-url="previewImageUrl"
			:dicom-tags="activeDicomTags"
			:canvas="activeCanvas"
			@close="isDrawerOpen.dicom = false"
		/>

		<ast-viewer-context-menu
			v-if="contextMenu.isOpen"
			:top="contextMenu.top"
			:left="contextMenu.left"
			:active-series="activeSeries"
			:allow-related-studies="allowRelatedStudies"
			:related-studies="relatedStudies"
			:mpr-title="mprLoadingReason || 'View MPR'"
			:mpr-loading="!!mprLoadingReason"
			:container-ref="$refs.layoutContainer"
			@select="switchSeries"
			@view-mpr="checkAndLoadMpr()"
			@close="contextMenu.isOpen = false"
		/>

		<ast-cine-dialog
			v-if="isCineDialogOpen"
			:is-open="isCineDialogOpen"
			v-bind="activeCanvas.cinePlayer"
			@play-clip="playClip"
			@pause-clip="pauseClip"
			@framerate-change="onFrameRateChange"
			@goto-image="goToImage"
			@goto-next-series="cineNextSeries"
			@goto-previous-series="cinePreviousSeries"
		/>

		<mpr-annotation-dialog v-if="mprActive" />
		<annotation-dialog v-else />

		<ast-hotkey-modal :show-hotkeys="hotkeysVisible" @close="toggleHotkeyModal" />
	</main>
</template>

<script>
import { detect } from 'detect-browser'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { polyfill } from 'mobile-drag-drop'
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour'
import Hammer from 'hammerjs'
import storage from '@services/storage'

import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneMath from 'cornerstone-math'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { provider as metadataProvider } from '@/cornerstone/metadata'
import CustomTools from '@/cornerstone/tools'
import synchronizers from '@store/modules/toolManager/synchronizers.js'

import FullScreenMixin from '@mixins/fullscreen.js'
import { eventBus } from '@services/eventBus'
import { findImageUrlFromImage } from '@utils/urlUtility'
import { scaleDicomPresetToJpeg, scaleJpegVoiToDicom } from '@/utils/wwwc.js'
import { validateMPRImages } from '@vtk'
import shouldLoadDicom from '@/utils/shouldLoadDicom.js'

// COMPONENTS
import AnnotationDialog from '@components/layout/ViewerAnnotationDialog'
import MprAnnotationDialog from '@components/layout/MprViewerAnnotationDialog'
import AstHotkeyModal from '@components/hotkeys/HotkeyModal'
import AstImagePlaceholder from '@components/ImagePlaceholder'
import AstImageViewer from '@components/ImageViewer'
import AstMprViewerLayoutSelector from '@components/layout/MprViewerLayoutSelector'
import AstMprViewerTools from '@components/layout/MprViewerTools'
import AstToolbar from '@components/Toolbar'
import AstToolbarBranding from '@components/ToolbarBranding'
import AstToolbarButton from '@components/ToolbarButton'
import AstViewerLayoutSelector from '@components/layout/ViewerLayoutSelector'
import AstViewerNavigation from '@components/layout/ViewerNavigation'
import AstViewerStudy from '@components/layout/ViewerStudy'
import AstViewerTools from '@components/layout/ViewerTools'
import EmailImagesSidebar from '@components/EmailImagesSidebar.vue'
import ModalDrawer from '@components/ModalDrawer'
import PersistentNavigationDrawer from '@components/PersistentNavigationDrawer'
import UserMenu from '@components/UserMenu.vue'
import HelpMenu from '@components/HelpMenu.vue'

// Dialogs
import { showMprDisabledDialog } from '@dialogs/MprDisabledDlg.vue'

import viewerCineDialog from './ViewerCineDialog'
import viewerDragAndDrop from './ViewerDragAndDrop'
import { tryStartTour, startTour } from './ViewerTour'
import { startTour as startMprTour } from './MprViewerTour'
import { openAttachmentDlg } from '../../dialogs/attachmentDlg.vue'
import { imageLoader, decacheInactiveStudies } from '@/imageLoader'
import { omniDesktop } from '@/electron/omniDesktop'
import listToArray from '@/utils/listToArray'
import DesktopDownloader from '@components/DesktopDownloader.vue'

polyfill({
	dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
})

cornerstoneTools.external.cornerstoneMath = cornerstoneMath
cornerstoneTools.external.cornerstone = cornerstone
cornerstoneTools.external.Hammer = Hammer

cornerstone.metaData.addProvider(metadataProvider)

// Originally: white
// Backup purple: #c000ff
cornerstoneTools.toolColors.setToolColor('#00e0ff')
cornerstoneTools.textStyle.setBackgroundColor('rgba(15,10,25,0.6)')

// Perform OS and screen size checks before enabling preset hotkeys.
// Passing 'CT' as modality to assume that study MAY have CTs or MRs.
const shouldLoadLevelPresets = shouldLoadDicom('CT')

export default {
	name: 'BaseViewerLayout',
	components: {
		DesktopDownloader,
		AnnotationDialog,
		MprAnnotationDialog,
		AstHotkeyModal,
		AstImagePlaceholder,
		AstImageViewer,
		AstMprViewerLayoutSelector,
		AstMprViewerTools,
		AstToolbar,
		AstToolbarBranding,
		AstToolbarButton,
		AstViewerLayoutSelector,
		AstViewerNavigation,
		AstViewerStudy,
		AstViewerTools,
		EmailImagesSidebar,
		ModalDrawer,
		PersistentNavigationDrawer,
		UserMenu,
		HelpMenu,
		// Dialog
		AstCineDialog: () => import(/* webpackChunkName: "componentCineDialog" */ '@components/CineDialog'),
		AstViewerAddRelatedStudy: () =>
			import(/* webpackChunkName: "componentViewerAddRelatedStudy" */ '@components/layout/ViewerAddRelatedStudy'),
		AstViewerContextMenu: () =>
			import(/* webpackChunkName: "componentViewerContextMenu" */ '@components/layout/ViewerContextMenu'),
		// Drawer
		AstViewerToolHelp: () =>
			import(/* webpackChunkName: "componentViewerToolHelp" */ '@components/layout/ViewerToolHelp'),
		ViewerActionsDrawer: () =>
			import(/* webpackChunkName: "componentViewerActionsDrawer" */ '@components/view/ViewerActionsDrawer'),
		DicomTagsDrawer: () =>
			import(/* webpackChunkName: "componentDicomTagsDrawer" */ '@components/view/ViewerDicomTagsDrawer'),
		AstMprViewer: () => import(/* webpackChunkName: "componentMprViewer" */ '@components/MprViewerInterface'),
	},
	mixins: [FullScreenMixin],
	props: {
		allowRelatedStudies: {
			type: Boolean,
			default: false,
		},
		primaryToolbarConfig: {
			type: Object,
			default: () => ({}),
		},
		settingsConfig: {
			type: Object,
			default: () => ({}),
		},
    clinicCode: {
      type: String,
      default: null
    }
	},
	data() {
		return {
			defaultPrimaryToolbar: {
				Thumbnails: {
					group: 'left',
					icon: 'menu',
					action: () => this.$store.dispatch('toggleDrawers'),
				},
			},
			viewerHelpItems: [
				{
					iconName: 'life-ring',
					label: 'Feature Tour',
					command: this.startTour,
				},
				{
					iconName: 'keyboard-outline',
					label: 'Keyboard Shortcuts',
					command: () => this.toggleHotkeyModal(true),
				},
			],
			mprInitialSlice: 0,
			mprInitialVoi: undefined,
			mprHelpItems: [
				{
					iconName: 'life-ring',
					label: 'Feature Tour',
					command: () => this.startMprTour(),
				},
				{
					iconName: 'keyboard-outline',
					label: 'Keyboard Shortcuts',
					command: () => this.toggleHotkeyModal(true),
				},
				{
					iconName: 'check-circle',
					label: 'Check MPR Performance',
					command: () => this.openMPRPerformanceModal(),
				},
			],
			isDrawerOpen: {
				mprActions: false,
				actions: false,
				about: false,
				dicom: false,
			},
			contextMenu: {
				isOpen: false,
				top: 0,
				left: 0,
				//
				eventStartX: 0,
				eventStartY: 0,
				eventStartTime: 0,
			},
			isCineDialogOpen: false,
			hotkeysVisible: false,
			showEmailImages: false,
			navPanelDraggedOver: false,
		}
	},
	computed: {
		...mapState({
			activeCanvasIndex: state => state.viewer.activeCanvasIndex,
			canvasLayout: state => state.viewer.canvasLayout,
			hangingProtocol: state => state.viewer.hangingProtocol,
			hideLoadingText: state => state.viewer.hideLoadingText,
			isHangingProtocolLoading: state => state.viewer.isHangingProtocolLoading,
			isModalDrawerOpen: state => state.ui.isModalDrawerOpen,
			isPersistentDrawerOpen: state => state.ui.isPersistentDrawerOpen,
			isViewerLoading: state => state.viewer.isViewerLoading,
			draggingFromThumbnailList: state => state.viewer.draggingFromThumbnailList,
			settings: state => state.viewer.settingsPanel,
			studies: state => state.viewer.studies,
			studiesNotFound: state => state.viewer.studiesNotFound,
			tools: state => state.toolManager.tools,
		}),
		...mapGetters([
			'activeCanvas',
			'activeSeries',
			'hasActiveSeries',
			'relatedStudies',
			'activeStudy',
			'activeImage',
			'allSeries',
			'mprActive',
			'visibleCanvases',
			'isCommunityUser',
		]),
		activeStudySeries() {
			return this.activeStudy?.imageData.series || []
		},
		isRepository() {
			return ['consigner-studies-viewer', 'repository-studies-viewer'].includes(this.$route.name)
		},
		isEmail() {
			return this.$route.name === 'email-share-consultant'
		},
		showBottomNavigation() {
			return this.isMobileOS || !this.mq.large
		},
		primaryToolbar() {
			return { ...this.defaultPrimaryToolbar, ...this.primaryToolbarConfig }
		},
		toolbarLocation() {
			if (!this.mq.small) return 'top'
			return this.settings.toolbarLocation
		},
		loadingText() {
			if (this.hideLoadingText) return
			if (this.isViewerLoading) return 'Loading images ...'
			if (this.isHangingProtocolLoading) return 'Loading Hanging Protocol ...'
			if (!this.viewerHasImages && !this.viewerHasAttachments) {
				if (this.studiesNotFound && this.studiesNotFound.length) {
					if (this.$route.query.clinicCode) {
						// missing study is from another clinic; user will be prompted to add the study to their clinic
						return 'Loading images ...'
					} else {
						return 'The requested study was not found.'
					}
				}
				if (this.studies.length || this.isEmail) {
					return 'The images in this study were removed and are no longer available for viewing.'
				}
			}
			return ''
		},
		attachments() {
			return [].concat(...this.studies.map(study => study.imageData.attachments))
		},
		viewerHasAttachments() {
			return this.attachments.length > 0
		},
		viewerHasImages() {
			return this.allSeries.length > 0
		},
		isMultiplePatients() {
			if (!this.studies.length || this.studies.length === 1) return false
			let firstPatientName = this.studies[0].patientName
			return this.studies.some(s => s.patientName !== firstPatientName)
		},
		layoutPaneSize() {
			return {
				width: `${100 / this.canvasLayout.canvasesPerRow}%`,
				height: `${100 / (this.canvasLayout.numCanvases / this.canvasLayout.canvasesPerRow)}%`,
			}
		},
		previewImageUrl() {
			return this.activeImage ? findImageUrlFromImage(this.activeImage, this.activeSeries) : ''
		},
		activeDicomTags() {
			if (!this.isDrawerOpen.dicom || !this.activeImage) return []
			const result = cornerstone.metaData.get('raw', this.activeImage.imageId)
			return result || []
		},
		mprLoadingReason() {
			if (!this.hasActiveSeries) {
				return 'No active series detected'
			}
			if (this.isViewerLoading || !this.getCanvasSeries(this.activeCanvas)) {
				return 'Viewer is still loading'
			}
			if (this.activeCanvas.isInitializing) {
				return 'Please wait for images to start loading'
			}
			return ''
		},
		mprDisabledReasons() {
			const reasons = []
			if (!this.mq.ipadSize) {
				if (this.isMobileOS) reasons.push('mobile')
				else reasons.push('size')
			}
			const browser = detect()
			if (browser.name === 'edge') {
				reasons.push('edge')
			}
			if (!['CT', 'MR'].includes(this.activeSeries.modality)) {
				reasons.push('modality')
			}
			if (reasons.length) return reasons
			return validateMPRImages(this.activeSeries.images).disabledReasons
		},
		mprWarningReasons() {
			const warnings = []
			//  Hide Warnings if user has disabled them
			if (!JSON.parse(storage.getItem('hideMPRSizeWarning'))) {
				const numImages = this.activeSeries.images.length
				const { rows } = cornerstone.metaData.get('imagePixelModule', this.activeImage.imageId)
				if (rows >= 1024 && numImages >= 200) {
					warnings.push('1024')
				} else if (rows >= 512 && numImages >= 900) {
					warnings.push('512')
				}
			}
			return warnings
		},
		helpItems() {
			return this.mprActive ? this.mprHelpItems : this.viewerHelpItems
		},
		isDesktop() {
			return omniDesktop.isConnected
		},
	},
	watch: {
		'studies.length'(currentCount, oldCount) {
			if (currentCount && !oldCount) this.onInitialStudyLoadOrReload()
		},
		isViewerLoading(isLoading) {
			// Just want to run the logic below ONCE after first load after window is first opened
			if (this.viewerHasLoaded) return
			this.viewerHasLoaded = true
			if (this.viewerHasImages) this.tryStartTour()
			this.$store.commit('resetBindings')
			this.$store.commit('resetLastLoadedModalityPreset')
			this.$nextTick(() => {
				this.setHotkeys()
				this.setMouseBindings()
			})
		},
		hasActiveSeries(active) {
			// Set up Cornerstone state when the viewer goes from having no active series
			// to an active one since the settings could have changed and this window could
			// have been in a closed state.
			if (active) {
				this.startImageViewerSync()
			}
		},
		mprActive(isActive) {
			if (isActive) {
				// Make sure player is paused so canvas can update
				this.pauseClip()
				// Hide CINE dialog
				this.isCineDialogOpen = false
				// Bind MPR key actions
				this.$store.commit('resetBindings')
				this.$nextTick(() => {
					this.setMPRHotkeys()
				})
			} else {
				// Close any MPR notifications that may have been thrown
				this.$store.commit('removeNotification', 'missingSlices')
				this.$store.commit('removeNotification', 'extraSlices')

				// Rebind keys when mpr is inactive
				this.$store.commit('resetBindings')
				this.$nextTick(() => {
					this.setHotkeys()
					// Rebind level presets
					if (shouldLoadDicom('CT')) {
						this.$store.commit('resetLastLoadedModalityPreset')
						this.setLevelPresets()
					}
				})
			}
		},
		canvasLayout: {
			handler() {
				this.$nextTick(this.resizeCanvases)
			},
			deep: true,
		},
		'activeSeries.seriesId': {
			immediate: true,
			handler() {
				this.updateDocumentTitle()
				// Make sure player is paused so canvas can update
				this.pauseClip()
				// Hide CINE dialog if series doesn't have multiple images
				if (!(this.activeSeries && this.activeSeries.images && this.activeSeries.images.length > 1)) {
					this.isCineDialogOpen = false
				}
				if (shouldLoadLevelPresets) this.setLevelPresets()
			},
		},
	},
	created() {
		cornerstoneTools.init({
			globalToolSyncEnabled: true,
		})
		this.addTools()
		this.setDefaultToolsActive()
		synchronizers.create()
	},
	mounted() {
		const onCacheFull = () => {
			if (decacheInactiveStudies()) {
				imageLoader.prefetch()
			}
		}

		// deselect any selected text on the page to prevent dragging of text
		if (window.getSelection && window.getSelection().empty) window.getSelection().empty()

		cornerstone.events.addEventListener('cornerstoneimagecachefull', onCacheFull)

		eventBus.on('toggleDicomTags', this.toggleDicomTagsDrawer)
		eventBus.on('openViewerEmail', this.openEmailAction)

		this.$once('hook:beforeDestroy', () => {
			cornerstone.events.removeEventListener('cornerstoneimagecachefull', onCacheFull)
			eventBus.off('toggleDicomTags', this.toggleDicomTagsDrawer)
			eventBus.off('openViewerEmail', this.openEmailAction)
		})
	},
	beforeDestroy() {
		if (this.isFullScreen) this.toggleFullScreen()
		this.$api.cancelAllPendingRequests()
		synchronizers.destroy()
		this.removeTools()
		this.clearAnnotations()
		this.setMprActive(false)
		imageLoader.stop()
		eventBus.off('toggleDicomTags', this.toggleDicomTagsDrawer)
		eventBus.off('openViewerEmail', this.openEmailAction)
		this.$store.commit('SET_ACTIVE_IMAGE_METADATA', null)
		this.$store.dispatch('stopImageViewerSync')
	},
	destroyed() {
		this.$store.commit('CLEAR_VIEWER')
		this.$store.commit('resetBindings')
		this.$store.commit('SET_CANVAS_LAYOUT', {
			columns: 1,
			numCanvases: 1,
		})
	},
	methods: {
		...mapActions([
			'clearAnnotations',
			'syncClearAnnotations',
			'copyCanvasAsync',
			'printCanvasAsync',
			'saveCanvasAsync',
			'setActiveCanvas',
			'setHangingProtocolDisplaySet',
			'switchSeries',
			'updateCanvas',
			'openMPRPerformanceModal',
			'setInitialValuesAndEnableMPR',
			'saveMprImageAsync',
			'printMprImageAsync',
			'copyMprImageAsync',
			'startImageViewerSync',
			'createAnnotationRenderingAsync',
			'createMprAnnotationRenderingAsync',
		]),
		...mapMutations({
			updateThumbnailProgress: 'UPDATE_THUMBNAIL_PROGRESS',
			setMprActive: 'SET_MPR_ACTIVE',
			clearMprActiveAnnotations: 'CLEAR_MPR_ACTIVE_ANNOTATIONS',
		}),
		...viewerCineDialog,
		...viewerDragAndDrop,
		tryStartTour,
		startTour,
		startMprTour,
		updateDocumentTitle() {
			const { patientName, patientId } = (this.activeSeries && this.activeSeries.overlayInformation) || {}
			const patientInfo = patientName ? `${patientName} - ${patientId}` : ''
			const titlePrefix = patientInfo || (this.$route.meta && this.$route.meta.title) || 'Viewer'
			const omniOrCommunity = this.isCommunityUser ? 'Community' : 'Omni'
			document.title = `${titlePrefix} - Keystone ${omniOrCommunity}`
		},
		onInitialStudyLoadOrReload() {
			// onInitialStudyLoadOrReload is called when either the window first opens, OR opens a study from an empty state
			this.setInitialCanvasLayout()
			this.openInitialAttachment()
			if (this.viewerHasImages && this.hangingProtocol) this.setHangingProtocolDisplaySet(0)
			if (!this.viewerHasImages) this.$store.dispatch('toggleDrawers', true)
		},
		setInitialCanvasLayout() {
			const { layout, sid } = this.$route.query
			if (!layout && !sid) {
				// set layout based on modality
				this.$store
					.dispatch('setCanvasLayoutForModalityAndSpread', {
						modality: this.activeSeries.modality,
						numSeries: this.allSeries.length,
					})
					.then(() => {
						this.$store.commit('FILL_CANVASES')
					})
			}
		},
		openInitialAttachment() {
			// check if sid query param specifies an attachment
			let sid = this.$route.query.sid
			// check if sids query param specifies an attachment
			if (!sid && this.$route.query.sids) {
				let sids = listToArray(this.$route.query.sids)
				if (sids.length === 1) {
					sid = sids[0]
				}
			}
			if (sid) {
				const attachment = this.attachments.find(a => a.seriesId === sid)
				if (attachment) {
					this.openAttachment(attachment)
					return
				}
			}
			// if no attachment opened from query param, no images in viewer, and this is the primary viewer,
			// then open the first attachment
			let isPrimaryViewer = true
			if (omniDesktop.isConnected && this.$store.state.windows.openStudiesInNewWindow) {
				isPrimaryViewer = omniDesktop.isFirstPrimaryViewerWindow
			}
			if (!this.viewerHasImages && this.viewerHasAttachments && isPrimaryViewer) {
				let attachment = this.attachments[0]
				if (['.pdf', '.mp4'].includes(attachment.fileExtension.toLowerCase())) {
					this.openAttachment(attachment)
				}
			}
		},
		getPrimaryToolbarGroup(group) {
			const toolbarGroup = {}
			for (const label in this.primaryToolbar) {
				if (this.primaryToolbar[label].group === group) {
					toolbarGroup[label] = this.primaryToolbar[label]
				}
			}
			return toolbarGroup
		},
		addTools() {
			this.tools.forEach(tool => {
				const toolClass = tool.isCustom ? CustomTools[tool.className] : cornerstoneTools[tool.className]

				cornerstoneTools.addTool(toolClass, tool.config)
			})
		},
		removeTools() {
			this.tools.forEach(tool => {
				cornerstoneTools.removeTool(tool.instanceName)
			})
		},
		setDefaultToolsActive() {
			cornerstoneTools.setToolActive('PanMultiTouch', {})
			cornerstoneTools.setToolActive('ZoomTouchPinch', {})
			// Display Tools
			cornerstoneTools.setToolEnabled('AstOrientationMarkers')
		},
		toggleDrawer(drawer) {
			this.isDrawerOpen[drawer] = !this.isDrawerOpen[drawer]
		},
		openEmailAction() {
			if (!this.mprActive && !this.showEmailImages) this.showEmailImages = true
		},
		toggleDicomTagsDrawer() {
			if (!this.mprActive) this.toggleDrawer('dicom')
		},
		resizeCanvases() {
			cornerstoneTools.forceEnabledElementResize()
		},
		canvasIsVisible(canvas) {
			return this.allSeries.some(x => x.seriesId === canvas.seriesId)
		},
		isUnmatchedImageView(canvas) {
			return canvas.imageView && !canvas.seriesId && !canvas.imageId
		},
		getCanvasSeries(canvas) {
			return this.allSeries.find(x => x.seriesId === canvas.seriesId)
		},
		getActiveDomCanvas() {
			if (this.$refs.canvases) {
				const activeVueCanvas = this.$refs.canvases.find(
					canvas => canvas.$options.propsData.index === this.activeCanvasIndex
				)
				if (activeVueCanvas && activeVueCanvas.$refs) {
					return activeVueCanvas.$refs.canvas
				}
			}
			return undefined
		},
		openAttachment(file) {
			openAttachmentDlg(file, this.isRepository)
		},
		onImageViewerMouseDown(evt, canvasIndex) {
			this.setActiveCanvas({ canvasIndex })
			this.startContextMenuWatch(evt)
		},
		/*
		 * Track most recent mousedown event properties, so when `contextmenu` fires
		 * on mouse up, we can use the values to determine if we should open a context menu
		 *
		 * @param {Object} evt MouseDownEvent
		 */
		startContextMenuWatch(evt) {
			this.contextMenu.eventStartX = evt.clientX
			this.contextMenu.eventStartY = evt.clientY
			this.contextMenu.eventStartTime = evt.timeStamp
		},
		/*
		 * Open a "related studies/series" context menu
		 *
		 * @param {Object} evt ContextMenuEvent
		 */
		openRelatedMenu(e) {
			if (e.pointerType === 'touch') return
			if (this.isRepository) return
			if (this.$store.getters.isRightClickHandledByTool) return
			const tooMuchMovement =
				Math.abs(this.contextMenu.eventStartX - e.clientX) > 50 ||
				Math.abs(this.contextMenu.eventStartY - e.clientY) > 50
			const tooMuchTime = Math.abs(this.contextMenu.eventStartTime - e.timeStamp) > 300
			if (tooMuchMovement || tooMuchTime) return
			this.contextMenu.top = e.clientY
			this.contextMenu.left = e.clientX
			this.contextMenu.isOpen = true
		},
		/*
		 * Layout Selector
		 */
		handleLayoutSelected(params) {
			this.$store.commit('SET_CANVAS_LAYOUT', {
				columns: params.c,
				numCanvases: params.c * params.r,
			})
			if (!this.hangingProtocol) this.$store.commit('FILL_CANVASES')
		},
		setHotkeys() {
			this.$store.dispatch('setHotkeys', this.$ga)
		},
		setMPRHotkeys() {
			this.$store.dispatch('setMPRHotkeys', this.$ga)
		},
		setMouseBindings() {
			this.$store.dispatch('setMouseBindings')
		},
		checkAndLoadMpr() {
			// Get current Viewer values to pass to MPR
			try {
				const canvas = this.getActiveDomCanvas()
				this.mprInitialSlice = 0
				const { voi } = cornerstone.getViewport(canvas)
				const activeImage = cornerstone.getImage(canvas)
				if (!activeImage.isDicom) {
					// voi from cornerstone is in jpeg scale, convert back!
					this.mprInitialVoi = scaleJpegVoiToDicom(voi, activeImage)
				} else {
					this.mprInitialVoi = { ...voi }
				}
				const toolState = cornerstoneTools.getToolState(canvas, 'stack')
				if (toolState && toolState.data && toolState.data.length) {
					// Send the % of the slice to map to the new range. Close enough
					this.mprInitialSlice = (parseInt(toolState.data[0].currentImageIdIndex) + 1) / this.activeSeries.images.length
				}
			} catch (e) {
				// Use initial Values
				this.mprInitialVoi = undefined
			}

			if (this.mprDisabledReasons.length || this.mprWarningReasons.length) {
				// launch modal
				showMprDisabledDialog(this.mprDisabledReasons, this.mprWarningReasons)
			} else {
				this.setMprActive(true)
			}
		},
		toggleHotkeyModal(visible) {
			this.hotkeysVisible = visible
		},
		setLevelPresets() {
			this.$store.dispatch('setWindowLevelPresets', this.setLevels)
		},
		setLevels(windowWidth, windowCenter) {
			const canvas = this.getActiveDomCanvas()
			let viewport = cornerstone.getViewport(canvas)
			if (!viewport) return
			const voi = { windowWidth, windowCenter }
			const activeImage = cornerstone.getImage(canvas)
			const isDicom = activeImage.isDicom

			if (!isDicom) {
				const scaledLevels = scaleDicomPresetToJpeg(voi, activeImage)
				viewport.voi.windowWidth = parseInt(scaledLevels.windowWidth)
				viewport.voi.windowCenter = parseInt(scaledLevels.windowCenter)
			} else {
				viewport.voi.windowWidth = parseInt(voi.windowWidth)
				viewport.voi.windowCenter = parseInt(voi.windowCenter)
			}

			cornerstone.setViewport(canvas, viewport)
		},
		onNavDrawerDragOver(event) {
			const types = event.dataTransfer.types
			if (
				types &&
				types.includes('text/plain') &&
				!this.draggingFromThumbnailList &&
				!this.isRepository &&
				!this.isEmail
			) {
				clearTimeout(this.dragTimeout)
				this.navPanelDraggedOver = true
				event.dataTransfer.dropEffect = 'copy'
				event.preventDefault()
				event.stopPropagation()
			}
		},
		onNavDrawerDragLeave() {
			// This event bubbles, so set on timeout to prevent flickering
			this.dragTimeout = setTimeout(() => (this.navPanelDraggedOver = false), 60)
		},
		onNavDrawerDrop(event) {
			this.navPanelDraggedOver = false
			const data = JSON.parse(event.dataTransfer.getData('text'))
			this.$emit('drop-study-report', data)
		},
	},
}
</script>

<style lang="scss">
@import '~intro.js/introjs.css';
@import '~intro.js/themes/introjs-nassim.css';
@import '~@styles/_vars.scss';

/*
 * STYLES
 *
*/
main.viewer-layout {
	display: flex;
	flex-direction: column-reverse;
	color: var(--primary-label);
	overflow: hidden;
	height: 100%;
}

.viewer-content {
	display: flex;
	flex-grow: 1;
	align-items: stretch;
	overflow: hidden;
}

.study-container {
	min-width: 212px;
}

.navigation-drawer,
.navigation-drawer .modal-content {
	padding-right: 1em; // for consistent scrollbars in Chrome and Edge
}

.navigation-drawer.dragged-over {
	background-color: var(--tertiary-bg);
}

.left,
.right {
	display: flex;
}
.top-navigation {
	display: flex !important;
	flex-direction: row !important;
}
.bottom-navigation {
	display: flex !important;
}

.loading-text {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 2em;
	color: #fff;
	background: #000;
}

.layout-pane-container {
	display: flex;
	flex-wrap: wrap;
	background: #000;
	flex-grow: 1;
	padding: 4px;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.icon-layout-mpr-3x1 {
	font-size: 18px !important;
}
</style>
