import {
	ArrowAnnotateTool,
	external,
	addToolState,
	removeToolState,
	EVENTS,
	import as csTools,
	getToolState,
	toolStyle,
	getModule,
	textStyle,
	toolColors,
} from 'cornerstone-tools/dist/cornerstoneTools.js'
import { openPromptDlg } from '@dialogs/TextPromptDlg'
import toolSelectedCallback from './_shared/toolSelectedCallback.js'

// Manipulators
import moveNewHandle from '@/cornerstone/manipulators/moveNewHandle'
import moveHandle from '@/cornerstone/manipulators/moveHandle'
import triggerDoneModifying from './util/triggerDoneModifying.js'

const triggerEvent = csTools('util/triggerEvent')
const getNewContext = csTools('drawing/getNewContext')
const draw = csTools('drawing/draw')
const setShadow = csTools('drawing/setShadow')
const drawArrow = csTools('drawing/drawArrow')
const textBoxWidth = csTools('drawing/textBoxWidth')
const drawHandles = csTools('drawing/drawHandles')
const drawLinkedTextBox = csTools('drawing/drawLinkedTextBox')

export default class AstArrowAnnotate extends ArrowAnnotateTool {
	constructor(configuration = {}) {
		const defaultConfig = {
			name: 'AstArrowAnnotate',
			supportedInteractionTypes: ['Mouse', 'Touch'],
			options: {
				precisionHandlesEnabled: true,
			},
			configuration: {
				changeTextCallback: () => {},
				async getTextCallback(cb) {
					const text = await openPromptDlg({
						title: 'New Arrow Annotation',
						prompt: 'Enter your annotation text (optional):',
						requireInput: false,
					})
					cb(text)
				},
				drawHandlesOnHover: true,
				shadow: true,
				shadowColor: '#000000',
				shadowOffsetX: 1,
				shadowOffsetY: 1,
			},
		}
		const initialConfiguration = Object.assign(defaultConfig, configuration)
		super(initialConfiguration)
		this.initialConfiguration = initialConfiguration
		this.mergeOptions(initialConfiguration.options)
	}

	// custom toolSelectedCallback for opening annotation dialog
	toolSelectedCallback = toolSelectedCallback.bind(this)

	addNewMeasurement(evt, interactionType) {
		const element = evt.detail.element
		const measurementData = this.createNewMeasurement(evt)

		// Associate this data with this imageId so we can render it and manipulate it
		addToolState(element, this.name, measurementData)
		external.cornerstone.updateImage(element)

		moveNewHandle(
			evt.detail,
			this.name,
			measurementData,
			measurementData.handles.end,
			this.options,
			interactionType,
			(evt, success) => {
				if (success) {
					if (measurementData.text === undefined) {
						this.configuration.getTextCallback(text => {
							if (text) {
								measurementData.text = text
							}
							measurementData.active = false
							external.cornerstone.updateImage(element)
							triggerDoneModifying(this.name, element, evt.detail.image)
						}, evt.detail)
					}
				} else {
					removeToolState(element, this.name, measurementData)
				}

				external.cornerstone.updateImage(element)

				const modifiedEventData = {
					toolName: this.name,
					toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
					element,
					measurementData,
				}

				triggerEvent(element, EVENTS.MEASUREMENT_COMPLETED, modifiedEventData)
			}
		)
	}

	handleSelectedCallback(evt, toolData, handle, interactionType = 'mouse') {
		moveHandle(evt.detail, this.name, toolData, handle, this.options, interactionType)

		evt.stopImmediatePropagation()
		evt.stopPropagation()
		evt.preventDefault()
	}

	renderToolData(evt) {
		const { element, enabledElement } = evt.detail
		const { handleRadius, drawHandlesOnHover, renderDashed } = this.configuration

		// If we have no toolData for this element, return immediately as there is nothing to do
		const toolData = getToolState(element, this.name)

		if (!toolData) {
			return
		}

		// We have tool data for this element - iterate over each one and draw it
		const canvas = evt.detail.canvasContext.canvas
		const context = getNewContext(canvas)

		const lineWidth = toolStyle.getToolWidth()

		let lineDash
		if (renderDashed) {
			lineDash = getModule('globalConfiguration').configuration.lineDash
		}

		for (let i = 0; i < toolData.data.length; i++) {
			const data = toolData.data[i]

			if (data.visible === false) {
				continue
			}

			draw(context, context => {
				setShadow(context, this.configuration)

				const color = toolColors.getColorIfActive(data)

				// Draw the arrow
				const handleStartCanvas = external.cornerstone.pixelToCanvas(element, data.handles.start)
				const handleEndCanvas = external.cornerstone.pixelToCanvas(element, data.handles.end)

				// Config.arrowFirst = false;
				if (this.configuration.arrowFirst) {
					drawArrow(context, handleEndCanvas, handleStartCanvas, color, lineWidth, lineDash)
				} else {
					drawArrow(context, handleStartCanvas, handleEndCanvas, color, lineWidth, lineDash)
				}

				const handleOptions = {
					color,
					handleRadius,
					drawHandlesIfActive: drawHandlesOnHover,
				}

				if (this.configuration.drawHandles) {
					drawHandles(context, evt.detail, data.handles, handleOptions)
				}

				const text = textBoxText(data)

				// Draw the text
				if (text && text !== '') {
					// Calculate the text coordinates.
					const padding = 5
					const textWidth = textBoxWidth(context, text, padding)
					const textHeight = textStyle.getFontSize() + 10

					let distance = Math.max(textWidth, textHeight) / 2 + 5

					if (handleEndCanvas.x < handleStartCanvas.x) {
						distance = -distance
					}

					if (!data.handles.textBox.hasMoved) {
						let textCoords

						if (this.configuration.arrowFirst) {
							textCoords = {
								x: handleEndCanvas.x - textWidth / 2 + distance,
								y: handleEndCanvas.y - textHeight / 2,
							}
						} else {
							// If the arrow is at the End position, the text should
							// Be placed near the Start position
							textCoords = {
								x: handleStartCanvas.x - textWidth / 2 - distance,
								y: handleStartCanvas.y - textHeight / 2,
							}
						}

						const transform = external.cornerstone.internal.getTransform(enabledElement)

						transform.invert()

						const coords = transform.transformPoint(textCoords.x, textCoords.y)

						data.handles.textBox.x = coords.x
						data.handles.textBox.y = coords.y
					}
					if (!data.handles.textBox.hidden) {
						drawLinkedTextBox(
							context,
							element,
							data.handles.textBox,
							text,
							data.handles,
							textBoxAnchorPoints,
							color,
							lineWidth,
							0,
							false
						)
					}
				}
			})
		}

		function textBoxText(data) {
			return data.text
		}

		function textBoxAnchorPoints(handles) {
			const midpoint = {
				x: (handles.start.x + handles.end.x) / 2,
				y: (handles.start.y + handles.end.y) / 2,
			}

			return [handles.start, midpoint, handles.end]
		}
	}
}
