<template>
	<div class="wrapper">
		<div ref="canvas" class="dicom-image-canvas" @contextmenu.prevent></div>

		<div class="border"></div>
		<div class="dicom-image-overlay">
			<div class="dot" :style="{ background: canvasColor }"></div>
			<div v-if="showOverlayText && image" class="dicom-image-overlay-text" :class="{ compact: useCompactOverlay }">
				<div class="top-left">
					<ul>
						<li class="full-only">Zoom: {{ +imageOverlayInfo.zoom | formatNumber }}%</li>
						<li>Series: {{ seriesIndex + 1 }} / {{ seriesTotal }}</li>
						<li>Image: {{ imageOverlayInfo.imageIndex }} / {{ series.images.length }}</li>
						<li v-if="image.fullName || image.simpleName" class="full-only">
							View: {{ image.fullName || image.simpleName }}
						</li>
						<li v-if="series.images.length > 1 && imageView" class="is-danger">
							<strong>View contains multiple images</strong>
						</li>
					</ul>
				</div>
				<div class="top-right">
					<ul>
						<template v-if="!isRepository">
							<li>Name: {{ series.overlayInformation.patientName }}</li>
							<li>Id: {{ series.overlayInformation.patientId }}</li>
							<li>Owner: {{ series.overlayInformation.ownerName }}</li>
							<li>
								Institution:
								{{ series.overlayInformation.institutionName }}
							</li>
							<li>
								Study Date:
								{{ series.overlayInformation.studyDate | localizeDate({ forceUTC: false }) }}
							</li>
							<li v-if="image.acquisitionTime">
								Acq Time: {{ image.acquisitionTime | localizeDate({ forceUTC: false }) }}
							</li>
						</template>
						<template v-else>
							<li>Id: {{ image.overlayInformation.lotNumber }}</li>
							<li>Chip Id: {{ image.overlayInformation.chipId }}</li>
							<li>Sire: {{ image.overlayInformation.sire }}</li>
							<li>Dam: {{ image.overlayInformation.dam }}</li>
							<li>Institution: {{ image.overlayInformation.institutionName }}</li>
							<li>
								Study Date:
								{{ image.overlayInformation.studyDate | localizeDate({ forceUTC: false }) }}
							</li>
							<li>Acq Time: {{ image.acquisitionTime | localizeDate({ forceUTC: false }) }}</li>
						</template>
					</ul>
				</div>
				<div class="bottom-right">
					<ul>
						<li v-if="imageOverlayInfo.hflip || imageOverlayInfo.vflip">
							<span v-if="imageOverlayInfo.hflip">H. Flip</span>
							<span v-if="imageOverlayInfo.hflip && imageOverlayInfo.vflip">/</span>
							<span v-if="imageOverlayInfo.vflip">V. Flip</span>
						</li>
						<li> Rotation Angle: {{ imageOverlayInfo.rotation }}&deg;</li>
					</ul>
				</div>
				<div class="bottom-left">
					<ul>
						<li v-if="imageOverlayInfo.invert">Inverted</li>
						<li>
							W/L:
							{{
								imageOverlayInfo.windowWidth +
									'/' +
									imageOverlayInfo.windowCenter +
									(imageOverlayInfo.isLevelingAdjusted ? '*' : '')
							}}
						</li>
						<li class="compact-only">Zoom: {{ imageOverlayInfo.zoom }}%</li>
						<li class="compact-only"> Rotation Angle: {{ imageOverlayInfo.rotation }}&deg;</li>
						<li v-if="image.fullName || image.simpleName" class="compact-only">
							View: {{ image.fullName || image.simpleName }}
						</li>
						<li v-if="activeDisplaySet">Display Set: {{ activeDisplaySet }}</li>
					</ul>
				</div>
			</div>

			<transition name="fade">
				<div v-if="showViewportCacheMessage" class="viewport-message">
					<div class="overlay-wrapper">
						Using saved leveling and orientation information
					</div>
				</div>
			</transition>

			<transition name="fade">
				<div v-if="showLoadingSpinner" class="loading">
					<svg-icon icon="spinner" pulse />
				</div>
			</transition>

			<ast-image-scroll
				v-if="series.images.length > 1 && image && !isInitializing"
				:image="image"
				:stack-index="stackIndex"
				:series="series"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { eventBus } from '@services/eventBus'
import * as Sentry from '@sentry/browser'
import * as cornerstone from 'cornerstone-core/dist/cornerstone.js'
import * as cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.js'
import { waitForElementToBeEnabled } from '@/utils/wait.js'
import { scaleJpegVoiToDicom } from '@/utils/wwwc.js'
import shouldRenderWebGL from '@/utils/shouldRenderWebGL'
import { disablePrecisionForInactiveHandles } from '@/cornerstone/tools/util/handles'
import { loadMetadata } from '@/cornerstone/metadata'
import { imageLoader, getCornerstoneImageIdsForSeries, getCornerstoneImageId } from '@/imageLoader'
import { clinicAPI } from '@services/clinicAPI'
import { MEASUREMENT_DONE_MODIFYING } from '@/cornerstone/_shared/events'
import { dicomServiceData } from '@services/dicomServiceData'

const { updateImage, getEnabledElementsByImageId, getEnabledElement } = cornerstone
const { NEW_IMAGE, IMAGE_RENDERED } = cornerstone.EVENTS
const { STACK_SCROLL, MEASUREMENT_REMOVED } = cornerstoneTools.EVENTS
const { EVENTS: TOOL_EVENTS, globalImageIdSpecificToolStateManager } = cornerstoneTools
// Events that we should disable precision mode for inactivate handles
const disablePrecisionEvents = [
	TOOL_EVENTS.MOUSE_DOWN,
	TOOL_EVENTS.TOUCH_START,
	TOOL_EVENTS.MEASUREMENT_ADDED,
	TOOL_EVENTS.MEASUREMENT_MODIFIED,
]

const modifiedAnnotationEvents = [MEASUREMENT_DONE_MODIFYING, MEASUREMENT_REMOVED]

export default {
	name: 'ImageViewer',
	components: {
		AstImageScroll: () =>
			import(/* webpackChunkName: "componentViewerImageScroll" */ '@components/view/ViewerImageScroll.vue'),
	},
	props: {
		index: {
			type: Number,
			required: true,
		},
		series: {
			type: Object,
			required: true,
		},
		asterisImageId: {
			type: String,
			required: true,
		},
		frameIndex: {
			type: Number,
			required: true,
		},
		imageView: {
			type: Object,
			default: undefined,
		},
		isInitializing: {
			type: Boolean,
			default: false,
		},
		cinePlayer: {
			type: Object,
			required: true,
		},
		showOverlayText: {
			type: Boolean,
			default: true,
		},
		// Used exclusively to toggle what overlay information is displayed
		// All of the following properties are why we need to make overlays
		// A [scoped] <slot>
		isRepository: {
			type: Boolean,
			default: false,
		},
		seriesIndex: {
			type: Number,
			required: true,
		},
		seriesTotal: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			stackIndex: 0,
			imageOverlayInfo: {
				imageIndex: 1,
				rotation: 0,
				hflip: false,
				vflip: false,
				invert: false,
				isLevelingAdjusted: false,
				windowWidth: 0,
				windowCenter: 0,
				zoom: 100,
			},
			defaultViewport: undefined,
			isAnyImageLevelAdjusted: false,
			showViewportCacheMessage: false,
			showLoadingSpinner: false,
			updateDebounce: undefined,
			useCompactOverlay: false,
		}
	},
	computed: {
		...mapGetters(['canvasColors', 'activeDisplaySet']),
		canvasColor() {
			return this.canvasColors[this.series.seriesId]
		},
		image() {
			let asterisImageId = this.asterisImageId
			return this.series.images.find(x => x.imageId === asterisImageId && x.frameIndex === this.frameIndex)
		},
	},
	watch: {
		series: {
			handler(series, previousSeries) {
				// Load and display a new series when the canvas's active series changes
				if (series && series.seriesId !== previousSeries?.seriesId) this.loadSeries()
				// Load new images added to series (when connected to local clinic)
				else if (series && series.seriesId === previousSeries?.seriesId) {
					if (series.images.length !== previousSeries.images.length) {
						this.updateStackToolState()
						imageLoader.prefetch()
					}
				}
			},
			deep: true,
		},
		cinePlayer: {
			handler() {
				this.applyCinePlayerSettings()
			},
			deep: true,
		},
	},
	mounted() {
		const opts = shouldRenderWebGL() ? { renderer: 'webgl' } : undefined
		cornerstone.enable(this.$refs.canvas, opts)
		this.addListeners()
		this.$store.dispatch('setCanvasInfo', {
			canvasIndex: this.index,
			domCanvas: this.$refs.canvas,
			vueCanvas: this,
		})
		this.loadSeries(true)
	},
	beforeDestroy() {
		try {
			// Stop any playing cinePlayers
			if (this.cinePlayer.isPlaying) {
				cornerstoneTools.stopClip(this.$refs.canvas)
			}

			this.$store.dispatch('cacheSeriesViewport', this.$store.state.viewer.canvases[this.index])
			cornerstone.disable(this.$refs.canvas)
		} catch (err) {
			// ignore "element not enabled" errors
		} finally {
			this.removeListeners()
		}
	},
	destroyed() {
		imageLoader.prefetch()
	},
	methods: {
		...mapActions(['updateCanvas', 'refreshCanvas']),
		addListeners() {
			this.removeListeners()
			this.$refs.canvas.addEventListener(NEW_IMAGE, this.onNewImage)
			this.$refs.canvas.addEventListener(IMAGE_RENDERED, this.updateOverlayInformation)
			this.$refs.canvas.addEventListener(STACK_SCROLL, this.onStackScroll)
			modifiedAnnotationEvents.forEach(evt => this.$refs.canvas.addEventListener(evt, this.onAnnotationModified))
			disablePrecisionEvents.forEach(evt => this.$refs.canvas.addEventListener(evt, this.onDisablePrecision))
		},
		removeListeners() {
			this.$refs.canvas.removeEventListener(NEW_IMAGE, this.onNewImage)
			this.$refs.canvas.removeEventListener(IMAGE_RENDERED, this.updateOverlayInformation)
			this.$refs.canvas.removeEventListener(STACK_SCROLL, this.onStackScroll)
			modifiedAnnotationEvents.forEach(evt => this.$refs.canvas.removeEventListener(evt, this.onAnnotationModified))
			disablePrecisionEvents.forEach(evt => this.$refs.canvas.removeEventListener(evt, this.onDisablePrecision))
		},
		onDisablePrecision(e) {
			disablePrecisionForInactiveHandles(e.detail.element)
		},
		onAnnotationModified(e) {
			// Don't rebroadcast these tool states. They have their own synchronizing handlers
			const blacklist = ['AstCalibration', 'stack']
			const { toolName, element } = e.detail
			// remove annotation doesn't have the image key in the detail object
			const image = e.detail.image || getEnabledElement(element)?.image
			if (!blacklist.includes(toolName) && image) {
				// redraw any other matching image canvases
				getEnabledElementsByImageId(image.imageId)
					.filter(enabledElement => enabledElement.element !== element)
					.forEach(enabledElement => {
						updateImage(enabledElement.element)
					})
				const toolState = globalImageIdSpecificToolStateManager.getImageIdToolState(image.imageId, toolName)
				eventBus.broadcast(eventBus.type.VUEX_ACTION, {
					type: 'updateExternalToolStateForId',
					payload: {
						imageId: image.imageId,
						toolName,
						toolState,
					},
				})
			}
		},
		/**
		 * When a native cornerstone method updates the image being displayed,
		 * we need to notify and update our store so we can keep cornerstone and our
		 * store in sync.
		 */
		onNewImage(e) {
			// HACK: reset the viewport.displayedArea (crop) to the image width/height
			// https://github.com/cornerstonejs/cornerstone/issues/304
			if (e.detail.viewport.displayedArea) {
				e.detail.viewport.displayedArea.brhc.x = e.detail.image.width
				e.detail.viewport.displayedArea.brhc.y = e.detail.image.height
			}

			this.defaultViewport = cornerstone.getDefaultViewportForImage(this.$refs.canvas, e.detail.image)
			if (!this.isAnyImageLevelAdjusted) {
				// HACK: Reset image to default ww/wc if no manual adjustments have been made to any image
				// in the series. Cornerstone's default behavior is to reuse the ww/wc between images, but
				// each image may have its own default ww/wc, so we need to apply the defaults here.
				e.detail.viewport.voi = { ...this.defaultViewport.voi }
			}

			clearTimeout(this.updateDebounce)
			this.updateDebounce = setTimeout(() => {
				const { asterisImageId, frameIndex } = e.detail.image
				// do not update the canvas if the new image is not in this series (ch11948)
				if (this.series.images.some(i => i.imageId === asterisImageId)) {
					this.updateCanvas({
						seriesId: this.series.seriesId,
						canvasIndex: this.index,
						asterisImageId,
						frameIndex,
					})
				}
			}, 100)
		},
		/**
		 * Update the overlay information for this enabledElement when a new image
		 * is rendered.
		 */
		updateOverlayInformation(e) {
			const eventData = e.detail
			if (!eventData || !eventData.viewport || !eventData.image) return
			const toolState = cornerstoneTools.getToolState(this.$refs.canvas, 'stack')
			if (!toolState || !toolState.data || !toolState.data.length) return

			let isLevelingAdjusted = false
			if (this.defaultViewport) {
				const defaultVoi = this.defaultViewport.voi
				const { voi } = eventData.viewport
				if (voi.windowWidth !== defaultVoi.windowWidth) isLevelingAdjusted = true
				if (voi.windowCenter !== defaultVoi.windowCenter) isLevelingAdjusted = true
			}
			if (isLevelingAdjusted) this.isAnyImageLevelAdjusted = true

			let windowWidth, windowCenter
			const isDicom = eventData.image.isDicom
			if (isDicom) {
				windowWidth = eventData.viewport.voi.windowWidth
				windowCenter = eventData.viewport.voi.windowCenter
			} else {
				const scaledDisplayValues = scaleJpegVoiToDicom(eventData.viewport.voi, eventData.image)

				windowWidth = scaledDisplayValues.windowWidth
				windowCenter = scaledDisplayValues.windowCenter
			}

			this.imageOverlayInfo = {
				imageIndex: parseInt(toolState.data[0].currentImageIdIndex) + 1,
				rotation: eventData.viewport.rotation,
				invert: eventData.viewport.invert,
				hflip: eventData.viewport.hflip,
				vflip: eventData.viewport.vflip,
				isLevelingAdjusted,
				windowWidth: Math.round(windowWidth),
				windowCenter: Math.round(windowCenter),
				zoom: (eventData.viewport.scale * 100).toFixed(2),
			}
		},
		/****
		 * Tool Management
		 *
		 */
		initCanvasTools() {
			// Set the stack as tool state
			cornerstoneTools.addStackStateManager(this.$refs.canvas, ['crossPoint'])
			this.updateStackToolState()
			this.$store.commit('UPDATE_TOOL_BINDINGS')
		},
		disableWheelTools() {
			cornerstoneTools.setToolDisabledForElement(this.$refs.canvas, 'StackScrollMouseWheel', {})
			cornerstoneTools.setToolDisabledForElement(this.$refs.canvas, 'ZoomMouseWheel', {})
		},
		updateActiveWheelTool() {
			const activeWheelTool =
				this.series.images && this.series.images.length > 1 ? 'StackScrollMouseWheel' : 'ZoomMouseWheel'

			cornerstoneTools.setToolActiveForElement(this.$refs.canvas, activeWheelTool, {})
		},
		applyCinePlayerSettings() {
			let settings = this.cinePlayer
			if (settings.isPlaying) {
				cornerstoneTools.playClip(this.$refs.canvas, settings.frameRate)
			} else {
				cornerstoneTools.stopClip(this.$refs.canvas)
			}
		},
		async loadSeries(initTools) {
			let loadingSpinnerDelay
			try {
				this.defaultViewport = undefined
				this.isAnyImageLevelAdjusted = false
				this.showViewportCacheMessage = false
				if (!this.image || !this.series || !this.series.images) return
				this.$store.commit('SET_CANVAS_INITIALIZING', { canvasIndex: this.index, isInitializing: true })
				loadingSpinnerDelay = setTimeout(() => {
					this.showLoadingSpinner = true
				}, 200)
				this.disableWheelTools()
				this.stackIndex = this.series.images.findIndex(image => image.imageId === this.asterisImageId)
				this.updateStackToolState()
				const cornerstoneImageId = getCornerstoneImageId(this.series, this.image)

				let study = this.$store.state.viewer.studies.find(s => s.studyId === this.series.studyId)
				if (study && !study.isArchived) {
					if (clinicAPI.firstScanPromise) {
						await clinicAPI.firstScanPromise
					}
					await dicomServiceData.firstStatusPromise
				}
				await loadMetadata(this.series)
				// remove existing prefetch promise for image
				const cachedImage = cornerstone.imageCache.cachedImages.find(i => i.imageId === cornerstoneImageId)
				if (cachedImage && !cachedImage.image) cornerstone.imageCache.removeImageLoadObject(cornerstoneImageId)
				const image = await cornerstone.loadAndCacheImage(cornerstoneImageId)
				const enabledElement = await waitForElementToBeEnabled(this.$refs.canvas)
				if (enabledElement) {
					cornerstone.displayImage(this.$refs.canvas, image)
					this.updateActiveWheelTool()
					if (initTools) this.initCanvasTools()
					imageLoader.prefetch()
					cornerstone.reset(this.$refs.canvas)
					this.$nextTick(this.refreshCanvas)
				}
				clearTimeout(loadingSpinnerDelay)
				this.$store.commit('SET_CANVAS_INITIALIZING', { canvasIndex: this.index, isInitializing: false })
				this.showLoadingSpinner = false
				this.glitchCanvas()
				if (this.$store.state.viewer.seriesViewportCache[this.series.seriesId]) {
					this.isAnyImageLevelAdjusted = true
					this.showViewportCacheMessage = true
					setTimeout(() => (this.showViewportCacheMessage = false), 2000)
				}
			} catch (err) {
				clearTimeout(loadingSpinnerDelay)
				this.$store.commit('SET_CANVAS_INITIALIZING', { canvasIndex: this.index, isInitializing: false })
				this.showLoadingSpinner = false
				if (err && err.message && !err.message.includes('element not enabled')) {
					const sentryEventId = Sentry.captureException(err)
					this.$store.dispatch('addNotification', {
						message: err.message,
						notificationType: 'error',
						sentryEventId,
					})
				}
			}
		},
		updateStackToolState() {
			let currentState = cornerstoneTools.getToolState(this.$refs.canvas, 'stack')
			if (!currentState) return
			cornerstoneTools.clearToolState(this.$refs.canvas, 'stack')
			cornerstoneTools.addToolState(this.$refs.canvas, 'stack', {
				currentImageIdIndex: this.stackIndex,
				imageIds: getCornerstoneImageIdsForSeries(this.series),
			})
		},
		onStackScroll(e) {
			this.stackIndex = e.detail.newImageIdIndex
			imageLoader.prefetch(e.detail.newImageIdIndex)
		},
		// LOOK AWAY!! LOOK AWAY!!
		// Really sorry for this, but Safari sometimes retains overlay background pixels
		// All this does is slightly lower the opacity and then reset it 10ms later
		glitchCanvas() {
			if (!this.$refs.canvas) return
			this.$refs.canvas.style.opacity = 0.99
			setTimeout(() => {
				if (!this.$refs.canvas) return
				this.$refs.canvas.style.opacity = 1
			}, 10)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	user-select: none;

	.border {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		border: 1px solid #333;
		touch-action: none;
		pointer-events: none;
	}

	&.isActiveViewer .border {
		border: 2px solid white;
	}
}

.dicom-image-canvas {
	width: 100%;
	height: 100%;
}
.dot {
	position: absolute;
	top: 0;
	right: 0;
	width: 12px;
	height: 12px;
	margin: 4px;
	border-radius: 100%;
}

.dicom-image-overlay {
	display: flex;
	position: absolute;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
}
.viewport-message {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	font-size: 1.1em;
	width: 100%;
	height: 33%;
	bottom: 0;
	left: 0;
	pointer-events: none;
	.overlay-wrapper {
		text-align: center;
		padding: 8px 16px;
		color: #fff;
		border: 1px solid #888;
		background: #000;
		border-radius: 1em;
		opacity: 0.75;
	}
}
.loading {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	font-size: 4em;
	opacity: 0.5;
	background: #000;
	color: #fff;
}

.dicom-image-overlay-text {
	position: relative;
	flex-grow: 1;
	color: white;
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.top-left,
	.top-right,
	.bottom-left,
	.bottom-right {
		position: absolute;
		max-width: 50%;
		padding: 12px;
	}

	.compact-only {
		display: none;
	}
	.full-only {
		display: block;
	}
	&.compact {
		.top-left,
		.bottom-left {
			max-width: 100%;
		}
		.top-right,
		.bottom-right {
			display: none;
		}
		.compact-only {
			display: block;
		}
		.full-only {
			display: none;
		}
	}

	.top-left {
		top: 0;
		left: 0;
	}

	.top-right {
		top: 0;
		right: 0;
	}

	.bottom-right {
		bottom: 0;
		right: 0;
	}

	.bottom-left {
		bottom: 0;
		left: 0;
	}
}

// MEDIA
.hide-compact {
	display: none;
}
.show-compact {
	display: block;
}
</style>
