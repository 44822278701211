<template>
	<ast-checkbox v-model="isVisible" :disabled="disabled" style="margin-bottom: 4px;">
		<slot></slot>
	</ast-checkbox>
</template>

<script>
import AstCheckbox from '@components/Checkbox.vue'

export default {
	name: 'AnnotationCheckbox',
	components: {
		AstCheckbox,
	},
	props: {
		textbox: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		isVisible: {
			get() {
				return !this.textbox.hidden
			},
			set() {
				this.textbox.hidden = !this.textbox.hidden
				this.$emit('update-image')
			},
		},
	},
}
</script>
